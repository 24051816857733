import { Component, Input, SimpleChanges } from "@angular/core";
import { InputViewModel, ValueChangeEventArgs } from "../../../../shared/components/shared";
import { ManagedSubscription } from "../../../../shared/managedSubscription";
import { BaseComponent } from "../../../shared";
import { ConfPropertyValue, ConfValueErrorMessage } from "../../../shared/models";
import { VisualObjectHelper } from "../../parameters/shared";
import { ConfiguratorStore, ConfMessageProvider, ConfPageSessionService } from "../../providers";
import { ConfPropertyModel } from "./confPropertyModel";
import { GlobalDataStore } from "../../../shared/providers/globalData/globalDataStore";

@Component({
  selector: 'property-decoration-item',
  templateUrl: './propertyDecorationItemComponent.html',
})
export class PropertyDecorationItemComponent extends BaseComponent {

  @Input()
  propertyModel: ConfPropertyModel;

  @Input()
  configurationId: number;

  @Input()
  weekNumbersEnabled: boolean = false;

  errors: Array<string> = [];

  viewModel: InputViewModel;
  itemsModel: InputViewModel[];
  errorSubscription: ManagedSubscription;

  dateFormat: string;
  

  constructor(public confStore: ConfiguratorStore,
    public storeSession: ConfPageSessionService,
    public messagesProvider: ConfMessageProvider,
    public visualObjectHelper: VisualObjectHelper,
    public globalDataStore: GlobalDataStore
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['propertyModel'] && !changes['propertyModel'].firstChange) {
      this.updateView();
    }
  }

  ngOnInit() {
    
    this.weekNumbersEnabled = this.uiSettings.datepicker.showWeekNumbers;    
    this.dateFormat = this.globalDataStore.globalSettings.dateFormat;

    // Translate the title
    this.viewModel = new InputViewModel(this.propertyModel.confPropertyValue.title);    
    this.viewModel.formattedValue = this.propertyModel.confPropertyValue.title;    
    this.viewModel.value = this.propertyModel.confPropertyValue.propertyName;    
    this.viewModel.readOnly = this.propertyModel.confPropertyValue.isReadOnly;
    this.updateView();

    this.errorSubscription = this.messagesProvider.onMessagesRequest<ConfValueErrorMessage>(this.storeSession.confSessionId, ConfValueErrorMessage.name, (x) => {
      // Flatten my error messages to a single array 
      let errorMessages = Array<string>();
      if (x)
        x.forEach(msg => {
          if (msg.confProprtyName === this.propertyModel.propertyDecorationItem.propertyName) {
            errorMessages = errorMessages.concat(msg.message);
          }
        });

      this.errors = errorMessages;

    }, false);

  }

  /**
   * Only updates the values in model and repopulates the dropdownlist items
   */
  public updateView() {
    if (!this.propertyModel)
      return;

    this.viewModel.width = '100%';
   
    this.viewModel.readOnly = this.propertyModel.confPropertyValue.isReadOnly;

    if (this.propertyModel.propertyDecorationItem.propertyType == "RefToObject") {

      // Value should be set by matching it against the available dropdown options
      this.viewModel.value = null;
      this.viewModel.formattedValue = null;

      this.itemsModel = new Array<InputViewModel>();

      if (this.propertyModel.confPropertyValue.selectableValues == null || this.propertyModel.confPropertyValue.selectableValues.size == 0) {

        this.viewModel.value = this.propertyModel.confPropertyValue.keyAndValue.key;
        this.viewModel.formattedValue = this.propertyModel.confPropertyValue.keyAndValue.value;

      }
      else {
        this.propertyModel.confPropertyValue.selectableValues.forEach((kvp) => {

          if (kvp.key == this.propertyModel.confPropertyValue.keyAndValue.key) {
            this.viewModel.value = kvp.key;
            this.viewModel.formattedValue = kvp.value;
          }

          let itemModel = new InputViewModel(kvp.value);
          itemModel.id = kvp.key;
          this.itemsModel.push(itemModel);

        })
      }
    }
    else if (this.propertyModel.propertyDecorationItem.propertyType == "Boolean") {
      this.viewModel.value = (this.propertyModel.confPropertyValue.keyAndValue.key == "True");
    }
    else {

      // Apply the formatting if it is set
      let formatting = this.propertyModel.propertyDecorationItem.numericFormat;
      if (this.propertyModel.confPropertyValue.valueByNumericFormat && this.propertyModel.confPropertyValue.valueByNumericFormat.has(formatting)) {

        this.viewModel.value = this.propertyModel.confPropertyValue.valueByNumericFormat.get(formatting);

      } else {

        // Read unformatted value
        this.viewModel.value = this.propertyModel.confPropertyValue.keyAndValue.key;
        this.viewModel = { ...this.viewModel };
      }
    }
  }

  onValueChange($event: ValueChangeEventArgs): void {
    if ($event.actionView) {
      this.confStore.setConfPropertyValue(this.configurationId, this.storeSession.confSessionId, this.propertyModel.confPropertyValue, $event.actionView.value);
    }
  }

  ngOnDestroy(): void {

    if (this.errorSubscription)
      this.errorSubscription.unsubscribe();

    super.ngOnDestroy();
  }

}