import { Component, Input, ElementRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, SimpleChanges, EventEmitter, Output, Inject } from '@angular/core';
import { UIElement, TextType, UIGroup, UISelect, UIInput, UIImage } from '../../models';
import { PushMessageSelection } from '../../providers/pushMessage/pushMessageSelection';
import { InputViewModel, ValueChangeEventArgs, DisplayStyle, Positions } from '../../../../shared/components';
import { PushMessageStore } from '../../providers/pushMessage';
import { BaseComponent } from '../..';
import { ManagedSubscription } from '../../../../shared';
import { ElementStore } from '../../providers/elementStore';

@Component({
  selector: 'ui-element',
  templateUrl: './uiElementComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIElementComponent extends BaseComponent {

  // The view can not work with the base class.
  @Input()
  public uiElementId: number;

  @Input()
  public elementStore: ElementStore; 

  public uiElement: any = {};

  @Input()
  public sessionId: number;

  @Output()
  action = new EventEmitter<PushMessageSelection>();

  public style = "";
  
  public rows = null;

  public cols = null;

  // Export enum to view.
  public TextType = TextType;

  viewModel: InputViewModel;
  itemsView: InputViewModel[] = [];
  selectedViewId: string;

  textBoxView: InputViewModel;

  checkboxView: InputViewModel;

  public DISPLAY_STYLE_OPTIONS: typeof DisplayStyle = DisplayStyle;

  public elementSubscription: ManagedSubscription;
  public elementValueSubscription: ManagedSubscription;

  constructor(
    public cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.refresh();
    this.listenChanges();
  }

  refresh() {

    this.style = "";

    this.uiElement = this.elementStore.getElement(this.uiElementId, this.sessionId);

    // Add a warning output to the console if the element is not found!
    if (!this.uiElement) {
      console.warn("UIElement with id '" + this.uiElementId + "' was not found.");
      return;
    }

    if (this.uiElement.className == "UISelect")
      this.createViewModels();
    else if (this.uiElement.className == "UIInput" && this.uiElement.type == "text") {

      let uiInput = this.uiElement as UIInput;

      this.textBoxView = new InputViewModel(uiInput.id);
      this.textBoxView.title = "";
      this.textBoxView.placeholder = uiInput.placeHolder;
      this.textBoxView.readOnly = uiInput.readonly;

      // This is needed for the for attribute to work.
      this.textBoxView.id = uiInput.id;

      if (uiInput.isMultiline) {

        this.rows = uiInput.rows || 1;

        if (uiInput.cols > 0)
          this.cols = uiInput.cols;
      }

      this.textBoxView.value = this.elementStore.getElementValue(this.uiElement.id, this.sessionId);

      // Set width and height.
      if (this.uiElement.width > 0)
        this.textBoxView.width = this.uiElement.width + "px";
      if (this.uiElement.height > 0)
        this.textBoxView.height = this.uiElement.height + "px";
    }
    else if (this.uiElement.className == "UIInput" && this.uiElement.type == "checkbox") {

      let uiInput = this.uiElement as UIInput;

      this.checkboxView = new InputViewModel(this.uiElement.id);
      this.checkboxView.title = "";
      this.checkboxView.readOnly = uiInput.readonly;

      // Set default value.
      this.checkboxView.value = this.elementStore.getElementValue(this.uiElement.id, this.sessionId);

      if (this.uiElement.width > 0)
        this.checkboxView.width = this.uiElement.width + "px";

      this.checkboxView.labelPosition = Positions.Above;
    }
    else {
      if (this.uiElement.width > 0)
        this.style += "width:" + this.uiElement.width + "px;";

      if (this.uiElement.height > 0)
        this.style += "height:" + this.uiElement.height + "px;";

      if (this.uiElement.className == "UIGroup") {
        let uiGroup = this.uiElement as UIGroup;

        // Width has higher prio.
        if (!this.uiElement.width && uiGroup.columnCount > 0)
          this.style += "width:" + uiGroup.columnCount * 10 + "px;";

        // Height has higher prio.
        if (!this.uiElement.height && uiGroup.rowCount > 0)
          this.style += "height:" + uiGroup.rowCount * 10 + "px;";
      }
    }

    if (this.uiElement.style)
      this.style += this.uiElement.style;
  }

  createViewModels(): void {

    let uiSelect = this.uiElement as UISelect;

    this.viewModel = new InputViewModel(uiSelect.id);
    this.viewModel.title = "";
    this.viewModel.helpImagePosition = null;
    this.viewModel.readOnly = uiSelect.readonly;

    // Set width and height.
    if (this.uiElement.width > 0)
      this.viewModel.width = this.uiElement.width + "px";
    if (this.uiElement.height > 0)
      this.viewModel.height = this.uiElement.height + "px";

    // Pre selected value.
    this.selectedViewId = this.elementStore.getElementValue(this.uiElement.id, this.sessionId);

    this.itemsView = Array<InputViewModel>();

    uiSelect.titleByValue.forEach((keyValuePair) => {

      let itemModel = new InputViewModel(keyValuePair.value);

      itemModel.id = keyValuePair.key;
      itemModel.value = keyValuePair.key;
      itemModel.formattedValue = keyValuePair.value;

      if (this.selectedViewId == itemModel.value) {
        this.viewModel.value = keyValuePair.key;
        this.viewModel.formattedValue = keyValuePair.value;
      }

      this.itemsView.push(itemModel);
    })
  }

  onCheckChanged(event: ValueChangeEventArgs): void {

    let pushMessageSelection = new PushMessageSelection();

    pushMessageSelection.sessionId = this.sessionId;
    pushMessageSelection.key = this.uiElement.id;
    pushMessageSelection.value = event.value;
    pushMessageSelection.triggerAction = true;

    this.action.emit(pushMessageSelection);

    this.cd.detectChanges();
  }

  onTextValueChange(event: ValueChangeEventArgs): void {

    let pushMessageSelection = new PushMessageSelection();

    pushMessageSelection.key = this.uiElement.id;
    pushMessageSelection.value = event.value;
    pushMessageSelection.sessionId = this.sessionId;
    pushMessageSelection.triggerAction = true;

    this.action.emit(pushMessageSelection);
  }

  public onAction(event: PushMessageSelection) {
    // Notify server.
    this.action.emit(event);
  }

  public onClick(id: string) {
    // Notify server.

    let pushMessageSelection = new PushMessageSelection();

    pushMessageSelection.sessionId = this.sessionId;
    pushMessageSelection.key = id;
    pushMessageSelection.triggerAction = true;

    this.action.emit(pushMessageSelection);
  }

  public onSelectValueChange($event: ValueChangeEventArgs): void {

    let select = this.uiElement as UISelect;

    let pushMessageSelection = new PushMessageSelection();

    pushMessageSelection.key = select.id;
    pushMessageSelection.value = this.viewModel.value;
    pushMessageSelection.triggerAction = true;
    pushMessageSelection.sessionId = this.sessionId;

    this.action.emit(pushMessageSelection);

    this.cd.detectChanges();
  }

  public getCssClass(uiElementId2: number) {

    let uiElement2 = this.elementStore.getElement(uiElementId2, this.sessionId);

    if (!uiElement2)
      return "";

    // Do not effect buttons.
    if (uiElement2.className == "UIInput") {
      return "";
    }

    // Do not effect UIGroup rendered with a div.
    if (uiElement2.className == "UIGroup") {
      let uiGroup = uiElement2 as UIGroup;
      if (uiGroup.includeDiv) {
        return "";
      }
    }

    return uiElement2.cssClass;
  }

  // This detects changes from a replaced popup.
  ngOnChanges(changes: SimpleChanges) {
    this.refresh();
    this.listenChanges();
    this.cd.markForCheck();
  }

  public listenChanges() {

    this.unsubscribe();

    this.elementSubscription = this.elementStore.onElementChange(this.sessionId, this.uiElementId, (uiElement: UIElement): void => {

      this.uiElement = uiElement;
      this.uiElementId = uiElement.renderId;

      this.refresh();
      this.cd.detectChanges();
    });

    this.elementValueSubscription = this.elementStore.onElementValueChange(this.sessionId, this.uiElementId, (value: string): void => {

      //console.log("Value has updated for uiElementId: '" + this.uiElementId + "' and value is now: '" + value + "'.");

      this.refresh();
      this.cd.markForCheck();

    });
  }

  ngOnDestroy() {
    this.unsubscribe();

    super.ngOnDestroy();
  }

  unsubscribe() {
    if (this.elementSubscription)
      this.elementSubscription.unsubscribe();

    if (this.elementValueSubscription)
      this.elementValueSubscription.unsubscribe();
  }

}