import { Injectable, Inject } from '@angular/core';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

import { HttpService } from "../../../../shared/providers/httpService";
import { ApiResponseBuilder } from "../apiResponseBuilder";
import { ModelFactory } from '../modelFactory';
import { ClientPushMessage } from '../../models/requests/clientPushMessage';
import { ApiResponse } from '../../models';
import { BaseController } from '../baseController';
import { JsonConvert } from '../../../../shared/providers';

@Injectable()
export class PushMessageController extends BaseController {

  public url = "api/pushmessage";

  constructor(
    @Inject(HttpService) public httpService: HttpService,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    public apiResponseBuilder: ApiResponseBuilder,
    public jsonConvert: JsonConvert,)
  {
    super(httpService, apiResponseBuilder);
  }

  public send(message: ClientPushMessage) {

    return this.httpService.get(this.url, message)
      .pipe(this.buildResponseObject).subscribe(
        // If there is not a subscription the data will never be sent.
        data => console.log('success', data),
        error => console.log('oops', error)
      );
  }

  public buildResponseObject = map((response, index) => this.apiResponseBuilder.build(response));

  public getPushMessage(data: any): Observable<ApiResponse> {

    const obsUsingCreate = Observable.create(observer => {
      observer.next(this.apiResponseBuilder.build(data));

      observer.complete()
    })

    return obsUsingCreate;
  }

  public getControllerUrl(): string {
    return this.url;
  }

  upload(model: ClientPushMessage, file: File) {
    let formData = new FormData();
    formData.append('json', this.jsonConvert.stringify(model));
    formData.append('file', file, file.name);

    // Add cache-busting parameter to URL
    const timestamp = new Date().getTime();
    const url = `${this.url}/upload?timestamp=${timestamp}`;

    return this.httpService.postWithProgress(url, formData);
  }

}