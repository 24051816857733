import { Route } from "@angular/router";
import { AccountSubRouteComponent, CreateProfileComponent, LoginComponent, LogoutComponent, PasswordChangeComponent, SignUpConfirmEmailComponent } from "../pages/account";
import { AccountComponent } from "../pages/account/accountComponent";
import { RecoverConfirmEmailComponent } from "../pages/account/password";
import { ComparisonRoutes } from "../pages/configurator/comparison/comparisonRoutes";
import { ConfiguratorRoutes } from "../pages/configurator/configuratorRoutes";
import { SearchRoutes } from "../pages/configurator/search/searchRoutes";
import { RedirectorComponent } from "../pages/redirector/redirectorComponent";
import { SelectorComponent } from "../pages/selector/selectorComponent";
import { SettingsComponent } from "../pages/settings/settingsComponent";
import { AuthGuard, RouteNames } from "../pages/shared/providers";
import { StartComponent } from "../pages/start/startComponent";
import { LogoutActivateGuard } from "../pages/account/logoutActivateGuard";

export let routeList: Route[] = [
  {
    path: '',
    redirectTo: '/start',
    pathMatch: 'full'
  },       
  ...ConfiguratorRoutes,
  {
    path: 'redirector',
    component: RedirectorComponent,
    //canActivate: [AuthGuard] // TODO Should redirector be guarded?
  },
  ...SearchRoutes,   
  ...ComparisonRoutes,   
  {
    path: 'selector',
    component: SelectorComponent,
    canActivate: [AuthGuard],    
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],    
  },
  {
    path: 'start',
    component: StartComponent,
    canActivate: [AuthGuard],    
  },  
  {
    path: 'account',
    component: AccountComponent,
    children: [

      {
        path: '', redirectTo: RouteNames.Login, pathMatch: 'prefix'
      },
      {
        path: RouteNames.Login, component: LoginComponent
      },
     
      {
        path: RouteNames.SignUp,
        component: AccountSubRouteComponent,
        children: [
          {
            path: '', redirectTo: RouteNames.Email, pathMatch: 'prefix'
          },
          {
            path: RouteNames.Email,
            component: SignUpConfirmEmailComponent
          },
          {
            path: RouteNames.Verify,
            component: SignUpConfirmEmailComponent,
          },
          {
            path: RouteNames.CreateProfile,
            component: CreateProfileComponent,
          }
        ]
      },
     
      {
        path: RouteNames.Recover,
        component: AccountSubRouteComponent,
        children: [
          {
            path: '', redirectTo: RouteNames.Email, pathMatch: 'prefix'
          },
          {
            path: RouteNames.Email,
            component: RecoverConfirmEmailComponent
          },
          {
            path: RouteNames.Verify,
            component: RecoverConfirmEmailComponent,
          },
          {
            path: RouteNames.PasswordChange,
            component: PasswordChangeComponent,
          }
        ]
      },     

    ]
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [LogoutActivateGuard]
  },  
  {
    path: '**',
    component: StartComponent,    
    canActivate: [AuthGuard],    
  }
]
