import { Injectable } from "@angular/core";
import * as Immutable from "immutable";
import { CodeDecoration, ConfUIItem, GroupConfUIItem, ConfCodeValue, AbstractCodeValue, CodeDisplayStyles } from "../../../models";
import { ConfPageSessionService } from "../../../../configurator/providers";
import { ProductDataStore } from "../../../providers/productData";
import { CodeDecorationValuesModel } from "../codeDecorationValueModel";
import { SummaryDisplayStyles } from "../../../../configurator/summary/prices/SummaryDisplayStyle";

@Injectable()
export abstract class BaseCodeValuesProvider {

  constructor(public productStore: ProductDataStore, public storeSession: ConfPageSessionService) {

  }

  /**
   * Returns the <CodeDecorationValueModel> list. Each decoration model <CodeDecorationValueModel> contains own values.
   * @param confUIItem
   * @param isEditor
   * @param displayStyleIfSummary
   */
  public getFilteredItems(confUIItem: ConfUIItem, isEditor: boolean, filterDisplayStyles: Array<CodeDisplayStyles>, displayStyleIfSummary?: SummaryDisplayStyles): Array<ConfUIItem> {

    // Grab the items where the decorationId defined.
    const confUIitems: Array<ConfUIItem> = this.extractDecorationConfUIItems(confUIItem);

    // Filter the confUIItems 
    const filteredItems = confUIitems.filter(uiItem => this.isValid(uiItem, isEditor, displayStyleIfSummary));

    return filteredItems;
  }


  isGrouped(confUIItem: ConfUIItem): boolean {
    return confUIItem instanceof GroupConfUIItem;
  }


  /**
   * Validates the decorationCode's visibility by request display style.
   * @param codeUIItem
   * @param isEditor
   * @param summaryDisplayStyle
   */
  isValid(codeUIItem: ConfUIItem, isEditor: boolean, summaryDisplayStyle: SummaryDisplayStyles): boolean {

    if (isEditor)
      return this.isValidForEditor(codeUIItem);

    return this.isValidForSummary(codeUIItem, summaryDisplayStyle);
  }

  isValidForEditor(codeUIItem: ConfUIItem): boolean {

    let dec: CodeDecoration = this.getCodeDecoration(codeUIItem.id);
    return dec.showInConfigurator

  }


  /**
   * Validates the decorationCode's visibility by request display style for summary page.
   * @param codeUIItem
   * @param isEditor
   * @param summaryDisplayStyle
   */
  isValidForSummary(codeUIItem: ConfUIItem, summaryDisplayStyle: SummaryDisplayStyles): boolean {

    let dec: CodeDecoration = this.getCodeDecoration(codeUIItem.id);

    return dec.showInSummary && (dec.summaryDisplayStyle == summaryDisplayStyle || dec.summaryDisplayStyle == SummaryDisplayStyles.Both);

  }

  /**
   * Return the requested <CodeDecoration>
   * @param id
   */
  getCodeDecoration(id: number): CodeDecoration {
    return this.productStore.getEntity<CodeDecoration>(id);
  }

  
  /**
   * Extracts the <ConfUIItem>(s) where the decorationId defined.   
   * Note! In Editor case we don't support grouping so decorationId would be at root level.
   * @param confUIItem
   */
  public extractDecorationConfUIItems(confUIItem: ConfUIItem): Array<ConfUIItem> {

    // In group case decorationId exists at children level so grab the items where the decorationId defined.
    const confUIitems: Array<ConfUIItem> = this.isGrouped(confUIItem) ? confUIItem.items.toArray() : [confUIItem];

    return confUIitems;
  }

  protected get sessionId(): number { return this.storeSession.confSessionId; }

  abstract getCodeValue(fileId: number, configurationId: number): AbstractCodeValue;

}