import { UIAction } from "./uiAction";
import * as Immutable from "immutable";

export enum OperationType {
  Refresh,
  Redirect,
  ClosePopup,
  ClientPushMessage,
  Download,
  AccordionChildNavigation,
  AccordionChildExit,
  Upload,
  SelectTab
}

export class UIEventOperation extends UIAction {

  protected readonly OPERATION_TYPE = "operationType";
  protected readonly URL = "url";
  protected readonly ROUTE_ARGS = "routeArgs";
  protected readonly REPLACE_URL = "replaceUrl";
  protected readonly SKIP_LOCATION_CHANGE = "skipLocationChange";
  protected readonly QUERY_PARAMS = "queryParams";
  protected readonly CONF_ID = "confId";
  protected readonly RELOAD = "reload";
  protected readonly ACCEPT_FILE_TYPE = "acceptFileType";
  protected readonly TAB_ID = "tabId";

  get operationType(): OperationType { return this.getInternalValue<OperationType>(this.OPERATION_TYPE); }
  setOperationType(operationType: OperationType): this { return this.setInternalValue(this.OPERATION_TYPE, operationType); }

  get url(): string { return this.getInternalValue<string>(this.URL); }
  setUrl(url: string): this { return this.setInternalValue(this.URL, url); }

  get routeArgs(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.ROUTE_ARGS); }
  setRouteArgs(routeArgs: Immutable.Map<string, string>): this { return this.setInternalValue(this.ROUTE_ARGS, routeArgs); }

  get replaceUrl(): boolean { return this.getInternalValue<boolean>(this.REPLACE_URL); }
  setReplaceUrl(replaceUrl: boolean): this { return this.setInternalValue(this.REPLACE_URL, replaceUrl); }

  get skipLocationChange(): boolean { return this.getInternalValue<boolean>(this.SKIP_LOCATION_CHANGE); }
  setSkipLocationChange(skipLocationChange: boolean): this { return this.setInternalValue(this.SKIP_LOCATION_CHANGE, skipLocationChange); }

  get queryParams(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.QUERY_PARAMS); }
  setQueryParams(queryParams: Immutable.Map<string, string>): this { return this.setInternalValue(this.QUERY_PARAMS, queryParams); }

  get confId(): number { return this.getInternalValue<number>(this.CONF_ID); }
  setConfId(confId: number): this { return this.setInternalValue(this.CONF_ID, confId); }

  get reload(): boolean { return this.getInternalValue<boolean>(this.RELOAD); }
  setReload(reload: boolean): this { return this.setInternalValue(this.RELOAD, reload); }

  get acceptFileType(): string { return this.getInternalValue<string>(this.ACCEPT_FILE_TYPE); }
  setAcceptFileType(acceptFileType: string): this { return this.setInternalValue(this.ACCEPT_FILE_TYPE, acceptFileType); }

  get tabId(): number { return this.getInternalValue<number>(this.TAB_ID); }
  setTabId(tabId: number): this { return this.setInternalValue(this.TAB_ID, tabId); }
}