import { Inject, Component, Input, ViewEncapsulation } from "@angular/core";
import { Assortment, Product } from "../shared/models";
import { Breakpoints, BreakPointAccessor } from "../../shared/utils";
import { BaseComponent } from "../shared";
import { RouteRedirector } from "../shared/providers";
import { ConfRouteParams } from "../configurator/providers";

@Component({
  selector: 'product-detail',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './detailComponent.html'
})
export class DetailComponent extends BaseComponent {

  public readonly DEFAULT_IMAGE = "configuration";
  public image: string = "";
  public imageType: string = "svg";
  public isVault: boolean = false;
  public imageSet: string;

  public isProduct = false;

  _assortment: Assortment;
  detailStyle: CSSStyleDeclaration;
  @Input() set product(value: Assortment) {
    this._assortment = value;
    this.isProduct = value instanceof Product;
    this.image = this.hasImage ? this._assortment.imageRelativeUrl : this.DEFAULT_IMAGE;
    this.isVault = this.hasImage;
    this.imageType = this.hasImage ? 'png' : 'svg';
    this.imageSet = this.hasImage ? '' : 'primary-light';

    this.updateStyle();
  };

  constructor(
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(BreakPointAccessor) public breakPointAccessor: BreakPointAccessor,
  ) {
    super();
  }

  updateStyle(): void {
    let calMinWidth: string;

    if (this.breakPointAccessor.equalOrDown(Breakpoints.md)) {
      calMinWidth = "100%";
    }
    else {
      calMinWidth = "600px";
    }

    this.detailStyle = <CSSStyleDeclaration>{
      maxWidth: calMinWidth
    }

  }

  get hasImage(): boolean {
    return this._assortment.imageRelativeUrl != "";
  }

  onConfigure(): void {
    this.createConfiguration(this._assortment.longId);
  }

  /**
  * Create the configuration
  * @param productId
  */
  createConfiguration(productId: number) {
    this.routeRedirector.redirectToEditor(<ConfRouteParams>{ productId: productId })
      .catch(error => {
        // Avoid unresolved promise error if navigation fails.
      });
  }
}