<accordion *ngIf="!keepAllTabsOpen && tabs.length > 0 && domReady"
           [openFirst]="selectedTabId == null"
           classes="accordion">
  <accordion-group *ngFor="let t of tabs; trackBy: trackTab"
                   [heading]="t.displayStyle == 'Separate' && t.title == '' ? t.handleTitle : t.title"
                   [isOpened]="t.longId === selectedTabId || tabs.length === 1 || t.displayStyle == tabDisplayStyle.AccordionHeaderTab || (t.displayStyle == tabDisplayStyle.AccordionChildren && t.title == '')"
                   [togglable]="tabs.length > 1 && t.displayStyle != tabDisplayStyle.AccordionHeaderTab && !(t.displayStyle == tabDisplayStyle.AccordionChildren && t.title == '')"
                   [icon]="t.iconRelativeUrl"
                   (onOpen)="onSelectedTabId(t.longId)"
                   (onClose)="onSelectedTabId(null)"
                   >
    <visual-object-container *ngIf="t.longId === selectedTabId || tabs.length === 1 || t.displayStyle == tabDisplayStyle.AccordionHeaderTab || (t.displayStyle == tabDisplayStyle.AccordionChildren && t.title == '')" [tab]="t" [attr.id]="t.longId"
                             [configurationId]="configurationId"
                             (accordionComposite)="accordionComposite.emit($event)"></visual-object-container>
  </accordion-group>
</accordion>

<accordion *ngIf="keepAllTabsOpen && tabs.length > 0 && domReady"
           [openFirst]="selectedTabId == null"
           classes="accordion">
  <accordion-group *ngFor="let t of tabs; trackBy: trackTab"
                   [heading]="t.displayStyle == 'Separate' && t.title == '' ? t.handleTitle : t.title"
                   [isOpened]="true"
                   [togglable]="false"
                   [icon]="t.iconRelativeUrl" >
    <visual-object-container [tab]="t" [attr.id]="t.longId"
                             (accordionComposite)="accordionComposite.emit($event)"
                             [configurationId]="configurationId"></visual-object-container>
  </accordion-group>
</accordion> 