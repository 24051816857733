import { Injectable } from "@angular/core";
import * as Immutable from "immutable";

import { BaseEntity } from "../baseEntity";
import { BaseObject } from "../../../shared/baseObject";
import { PropertyMapObject } from "../../../shared/propertyMapObject";
import { ImmutableObject } from "../../../shared/immutableObject";
import { Ioc, Register } from "../../../shared/ioc/iocDecorator";

import * as entityClasses from "../models/entities";
import * as messageClasses from "../models/responses/messages";
import * as responseClasses from "../models/responses";
import * as requestClasses from "../models/requests";

@Injectable()
export class ModelFactory {

  createEntity(className: string, rawObject?: Partial<BaseEntity>): BaseEntity {
    return this.createAny<BaseEntity>(className, rawObject);
  }

  createObject<T extends any>(rawObject: Partial<T>): T {

    if (!rawObject)
      return null;

    return this.createAny((<any>rawObject).className, rawObject) as T;
  }

  createRequestOrCommand<T extends requestClasses.BaseRequest | requestClasses.BaseCommand>(className: string, rawObject?: Partial<T>): T {
    return this.createAny<T>(className, rawObject);
  }

  createAny<T extends any>(className: string, rawObject?: Partial<T>, classNameResolver?: (currentObj, mapKey) => string) {

    let classConstructor = entityClasses[className] || messageClasses[className] || responseClasses[className] || requestClasses[className];

    if (classConstructor) {

      let temp = new classConstructor();
      if (temp instanceof PropertyMapObject) {

        let propertyMap = null;
        if (temp instanceof ImmutableObject)
          propertyMap = rawObject ? this.recursivelyCreateBaseObject(rawObject, true, classNameResolver) : Immutable.Map<string, any>();
        else
          propertyMap = rawObject ? this.recursivelyCreateBaseObject(rawObject, false, classNameResolver) : new Map<string, any>();

        return new classConstructor(propertyMap);
      }

      let newObject = new classConstructor();
      if (rawObject) {
        this.populateObject(newObject, rawObject, classNameResolver);
      }
      return newObject;      
    }
    
    throw new Error(`Class for '(${className})' not found`)    
  }

  public recursivelyCreateBaseObject(rawObject: any, isImmutable?: boolean, classNameResolver?: (currentObj, mapKey) => string) {

    let data = new Map<string, any>();

    for (let key of Object.keys(rawObject)) {

      if (rawObject[key] != null && rawObject[key] instanceof Object && !rawObject[key].className && classNameResolver)
        rawObject[key].className = classNameResolver(rawObject[key], key);

      if (rawObject[key] != null && rawObject[key].constructor === Array) {

        let subArray = new Array<any>();

        let rawDataArray: Array<any> = rawObject[key];

        for (let subObject of rawDataArray) {
                    
          if (subObject && subObject.className)
            subArray.push(this.createAny(subObject.className, subObject, classNameResolver));
          else
            subArray.push(this.handleNormalizeId(subObject));
        }

        if (isImmutable)
          data = data.set(key, Immutable.List(subArray));
        else
          data = data.set(key, subArray);
      }
      else if (rawObject[key] != null && rawObject[key].constructor === Object && rawObject[key].className === undefined) {

        let subMap = new Map<any, any>();

        let rawDataMap: Object = rawObject[key];

        for (let mapKey in rawDataMap) {

          let subObject = rawDataMap[mapKey];
      
          if (subObject && subObject.className)
            subMap.set(mapKey, this.createAny(subObject.className, subObject));
          else
            subMap.set(mapKey, this.handleNormalizeId(subObject));
        }

        if (isImmutable)
          data = data.set(key, Immutable.Map(subMap));
        else
          data = data.set(key, subMap);
      }
      else if (rawObject[key] != null && rawObject[key].className) {
        data = data.set(key, this.createAny(rawObject[key].className, rawObject[key]));
      }
      else {
        data = data.set(key, this.handleNormalizeId(rawObject[key]));
      }
    }

    if (isImmutable)
      return Immutable.Map(data);

    return data;
  }

  public populateObject(newObject: any, rawObject: any, classNameResolver?: (currentObj, mapKey) => string) {

    if (!rawObject)
      return;

    for (let key of Object.keys(rawObject)) {

      if (rawObject[key] != null && rawObject[key] instanceof Object && !rawObject[key].className && classNameResolver)
        rawObject[key].className = classNameResolver(rawObject[key], key);

      if (rawObject[key] != null && rawObject[key].constructor === Array) {

        let subArray = new Array<any>();

        let rawDataArray: Array<any> = rawObject[key];

        for (let subObject of rawDataArray) {
          if (subObject && subObject.className)
            subArray.push(this.createAny(subObject.className, subObject, classNameResolver));
          else
            subArray.push(subObject);
        }
        
        newObject[key] = subArray;
      }
      else if (rawObject[key] != null && rawObject[key].constructor === Object && rawObject[key].className === undefined) {

        let subMap = new Map<any, any>();

        let rawDataMap: Object = rawObject[key];

        for (let mapKey in rawDataMap) {

          let subObject = rawDataMap[mapKey];

          if (subObject && subObject instanceof Object && !subObject.className && classNameResolver)
            subObject.className = classNameResolver(subObject, mapKey);

          if (subObject && subObject.className)
            subMap.set(mapKey, this.createAny(subObject.className, subObject, classNameResolver));
          else
            subMap.set(mapKey, subObject);
        }
                
        newObject[key] = subMap;
      }
      else if (rawObject[key] != null && rawObject[key].className) {
        newObject[key] = this.createAny(rawObject[key].className, rawObject[key], classNameResolver);
      }
      else {
        newObject[key] = rawObject[key];        
      }
    }
  }

  createArray<T extends any>(rawObjects: Partial<T>[]): Immutable.List<T> {

    let result = new Array<T>();

    for (let rawObject of rawObjects)
      result.push(this.createObject<T>(rawObject));

    return Immutable.List(result);
  }



  // If it is a Normalizr identifier object then return the id part only
  public handleNormalizeId(data: any): any {
    if (data && data.id && data.schema)
      return data.id;

    return data;
  }

}