import { Injectable, Inject, forwardRef, ElementRef } from '@angular/core';
import { Location } from "@angular/common";
import { Router, RouterStateSnapshot, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { ConfRouteParams } from "../../configurator/providers/confRouteParams";
import { RouteNames } from "./routeNames";
import { ComparisonUrlParams } from '../../configurator/comparison/components/dragDrop/models/comparisonUrlParams';
import { AccountDataStore } from './accountData';
import { searchUrlQueryParam } from '../../../shared/utils/routeParamsUtils';
import { TokenService } from './tokenService';

@Injectable()
export class RouteRedirector {

  constructor(public router: Router, public activeRoute: ActivatedRoute, public location: Location, public accountStore: AccountDataStore, public tokenService: TokenService) { }

  public redirect(commands: any[], extras?: NavigationExtras): Promise<boolean> {

    if (extras && extras.replaceUrl) {
      let urlToRedirect = "#" + decodeURIComponent(this.router.serializeUrl(this.router.createUrlTree(commands, extras)));
      location.replace(urlToRedirect);
      return Promise.resolve(true);
    }

    return this.router.navigate(commands, extras);        
  }

  public redirectByUrl(url: string, extras?: NavigationExtras): Promise<boolean> {

    if (extras && extras.replaceUrl)
      return this.redirect([url], extras);

    return this.router.navigateByUrl(url, extras);
  }

  public redirectByAbsolutUrl(url: string, includeAccessToken = true) {
    if (includeAccessToken)
     url = this.tokenService.addTokenToUrl(url);
    window.location.href = url;
  }

  /**
   * Redirects to the redirector page for the given URL
   * @param url url to redirect to
   * @param routeArgs optional route arguments
   */
  public redirectToRedirector(url: string, routeArgs: any = {}): Promise<boolean> {
    let params = { url: url, ...routeArgs }
    return this.redirect(["/redirector", params], { skipLocationChange: true });
  }

  public redirectToStart(params: any = {}, extras?: NavigationExtras): Promise<boolean> {
    let routeArgs = { ...params };

    return this.redirect(['/start', routeArgs], extras);
  }

  public redirectToLogin(returnUrl: string = null): Promise<boolean> {
        
    if (!returnUrl)
      returnUrl = this.location.path();

    if (returnUrl) {
      // Avoid redirect if the user is already at login or logout url
      if (returnUrl == "account" || returnUrl == "/account" || returnUrl.indexOf("account/login") > -1 || returnUrl.indexOf("logout") > -1)
        return Promise.resolve(true);
    }
        
    let routeArgs = {};
    if (returnUrl)
      routeArgs = { returnurl: returnUrl };

    return this.redirect(['/account/login', routeArgs]);
  }

  public redirectAfterLogout(): Promise<boolean> {

    return this.redirect(['/account/login'], { replaceUrl: true });
  }

  public redirectToSearchResult(params: any = {}): Promise<boolean> {
    return this.redirect([`/${RouteNames.Search}/${RouteNames.Result}`, params]);
  }

  public redirectToSearch(params: any = {}): Promise<boolean> {
    return this.redirect([`/${RouteNames.Search}`, params]);
  }

  public redirectToSelector(params: any = {}): Promise<boolean> {
    return this.redirect([`/${RouteNames.Selector}`, params]);
  }

  /**
   * Redirector to load the load before component rendering.
   * @param params
   */
  public redirectToDataLoaderComponent(params: ConfRouteParams, extras?: any, subConfId?: number): Promise<boolean> {

    if (!extras)
      extras = {};

    if (subConfId)
      params.subConfId = subConfId;

    extras.skipLocationChange = true;

    this.cleanParams(params);
    // Redirector is responsible to make the sub routing.
    return this.redirect(['/configurator/redirector', params], extras);
  }

  /**
   * Navigates to corresponding view on  data load.
   * @param params
   */
  public redirectToConfViewAfterDataLoad(params: ConfRouteParams, extras?: any): Promise<boolean> {
    // Copy the view before remove.
    let view: string = params.view;

    if (!extras)
      extras = {};

    // Clean/remove unnecessary fields
    this.cleanParams(params, true);    

    // Make redirect.
    return this.redirect([`/${RouteNames.Configurator}/${view}`, params], extras);
  }

  public cleanParams(params: ConfRouteParams, removeView: boolean = false): any {
    Object.keys(params).forEach((key) => (params[key] == null || (removeView && key == ConfRouteParams.VIEW)) && delete params[key]);
  }

  public redirectToEditor(params: ConfRouteParams): Promise<boolean> {
    // If the call is made directly, not through <redirectToConfView> then view could be null, set it to 'editor'
    params.view = RouteNames.Editor;
    return this.redirectToDataLoaderComponent(params);
  }

  /** Redirects to the configuration summary*/
  public redirectToSummary(params: ConfRouteParams): Promise<boolean> {
    // If the call is made directly, not through <redirectToConfView> then view could be null, set it to 'summary'
    params.view = RouteNames.Summary;
    return this.redirectToDataLoaderComponent(params);
  }

  public redirectToConfigurator(params: ConfRouteParams): Promise<boolean> {
    switch (params.view) {
      case RouteNames.Editor:
        return this.redirectToEditor(params);
        
      case RouteNames.Summary:
        return this.redirectToSummary(params);
        
    }
  }

  public redirectToAccountRecoverEmail(params: any = {}): Promise<boolean> {
    return this.redirect([`/${RouteNames.Account}/${RouteNames.Recover}/${RouteNames.Email}`, params]);
  }

  public redirectToAccountRecoverVerify(params: any = {}): Promise<boolean> {
    return this.redirect([`/${RouteNames.Account}/${RouteNames.Recover}/${RouteNames.Verify}`, params]);
  }

  public redirectToPasswordChange(params: any = {}): Promise<boolean> {

    return this.redirect([`/${RouteNames.Account}/${RouteNames.Recover}/${RouteNames.PasswordChange}`, params]);
  }

  public redirectToSignUpEmail(params: any = {}): Promise<boolean> {
    return this.redirect([`/${RouteNames.Account}/${RouteNames.SignUp}/${RouteNames.Email}`, params]);
  }

  public redirectToSignUpVerify(params: any = {}): Promise<boolean> {
    return this.redirect([`/${RouteNames.Account}/${RouteNames.SignUp}/${RouteNames.Verify}`, params]);
  }

  public redirectToSignUp(params: any = {}): Promise<boolean> {
    return this.redirect([`/${RouteNames.Account}/${RouteNames.SignUp}/${RouteNames.CreateProfile}`, params]);
  }

  public redirectToComparison(params: ComparisonUrlParams): Promise<boolean> {
    return this.redirect([`/${RouteNames.Comparison}`, params], { skipLocationChange: isNaN(params.sessionid) });
  }

  public readFromRouteUrl(paramName: string) {

    if (!paramName)
      return null;

    paramName = paramName.toLowerCase();

    let params = this.router.url.split(';');
    for (let index = 0; index < params.length; index++) {

      let currentParam = params[index];

      let keyValues = currentParam.split('=');
      if (keyValues.length > 1 && keyValues[0].toLowerCase() == paramName)
        return keyValues[1];
    }

    return null;
  }

  public searchRouteParam(name: string) {

    let url = this.router.url;
    for (let paramValue of url.split(';'))
      if (paramValue.startsWith(name)) {

        const keyValue = paramValue.split('=');
        if (keyValue.length > 1)
          return keyValue[1];
      }

    return null;
  }

  public changeParamValue(paramName: string, value: string): string {

    let newUrl = this.router.url;

    let params = newUrl.split(';');
    let index = 0;
    for (;index < params.length; index++) {

      let param = params[index];
      if (param.toLocaleLowerCase().startsWith(paramName.toLocaleLowerCase())) {
        params.splice(index, 1);
      }
    }

    // If value is defined then include it.
    if (value) {
      const paramVal = `${paramName}=${value}`;
      params.splice(index, 0, paramVal);
    }

    newUrl = params.join(';');
        
    this.redirect([newUrl], { replaceUrl: true});
    return newUrl;
  }

}