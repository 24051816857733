<backdrop-container *ngIf="visible">
  <side-bar-popup *ngIf="isSidebar"
                  [title]="title"
                  [id]="id"
                  [width]="width"
                  [showHeader]="true"
                  [showFooter]="false"
                  position="right"
                  (onClosed)="onPopupClosed()">

    <div card-type="header" class="dataselector-table">
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </div>
    <div card-type="body" [class.dataselector-backdrop]="showFilter" (click)="onCancelFilter()" class="p-4 dataselector-table">
      <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
    </div>

  </side-bar-popup>
  <regular-popup *ngIf="!isSidebar"
                 [title]="title"
                 [id]="id"
                 [width]="width"
                 [showHeader]="true"
                 [showFooter]="false"
                 position="right"
                 (onClosed)="onPopupClosed()">

    <div card-type="header" class="dataselector-table">
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </div>
    <div card-type="body" [class.dataselector-backdrop]="showFilter" (click)="onCancelFilter()" class="p-4 dataselector-table">
      <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
    </div>

  </regular-popup>
</backdrop-container>

<ng-template #headerTemplate>

  <gridview>
    <gridview-row>
      <columntemplate classes="align-self-center py-2 dataselector-title ellipsis">
        <h4 class="modal-title">{{strings.SelectValue}}</h4>
      </columntemplate>
        <columntemplate *ngIf="filterVisible" classes="align-self-center" autoCell="true">
          <div class="container-fluid">
            <div class="row">
              <filter *ngIf="filterVisible"
                      [filterItems]="filterItems"
                      [isInline]="true"
                      [searchInfo]="this.searchInfo"
                      (onFilter)="onFilter($event)"
                      (onCancel)="onCancelFilter()">
              </filter>
            </div>
          </div>
        </columntemplate>
        <columntemplate classes="align-self-center py-2" autoCell="true">
          <co-image *ngIf="isFiltered" class="clickable" width="24px" height="24px" type="svg" key="filter-clear" imageSet="{{imageSet}}" (click)="clearFilter()"></co-image>
        </columntemplate>
        <columntemplate *ngIf="!filterVisible" classes="align-self-center py-2" autoCell="true">
          <co-image class="clickable" width="24px" height="24px" type="svg" key="filter" imageSet="{{imageSet}}" (click)="toggleShowFilter()"></co-image>
        </columntemplate>
    </gridview-row>
  </gridview>
  <popover *ngIf="!filterVisible && filterItems.length > 0" [isOpened]="showFilter" class="d-flex justify-content-end popup">
    <filter [filterItems]="filterItems"
            [searchInfo]="this.searchInfo"
            (onFilter)="onFilter($event)"
            (onCancel)="onCancelFilter()">
    </filter>
  </popover>
</ng-template>

<ng-template #bodyTemplate>
  <div class="table-wrapper">
    <table class="useBorder w-100">
      <thead>
        <tr>
          <th *ngFor="let column of columns"
              title="{{column.contents}}"
              [ngStyle]="column.safeStyle"
              [class]="column.classes"
              id="column.longId"
              (click)="columnClick(column)">
            <div class="d-flex align-middle justify-content-center">
              <span>
                {{column.contents}}
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- Different template depending on hyperlink or not, hyperlink should not have onValueClick -->
        <tr *ngFor="let row of rows; trackBy: trackRow"
            [class]="row.classes + (row.id == selectedValue?.id ? ' selected' : '')">
          <ng-container *ngFor="let cell of row.cells">
            <ng-container *ngIf="cell.href || cell.image; else contents">
              <td [class]="cell.classes" title="{{cell.href}}" [ngStyle]="cell.safeStyle">
                <a *ngIf="cell.href" href="{{cell.image ? cell.contents : cell.href}}" class="d-block" target="_blank">
                  <co-image *ngIf="cell.image; else noImage" type="" [isVault]="cell.contents | isVaultImage" [customUrl]="cell.contents"></co-image>
                  <ng-template #noImage>
                    <co-image *ngIf="!cell.image" width="24px" height="24px" type="svg" key="link"></co-image>
                  </ng-template>
                </a>
                <co-image *ngIf="cell.image && !cell.href" type="" [isVault]="cell.contents | isVaultImage" [customUrl]="cell.contents" (click)="onValueClick(row)"></co-image>
              </td>

            </ng-container>
            <ng-template #contents>
              <td [class]="cell.classes" title="{{cell.contents}}" [ngStyle]="cell.safeStyle" (click)="onValueClick(row)">
                {{cell.contents}}
              </td>
            </ng-template>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="isDataReady" card-type="footer" class="dataselector-table">
    <pager [pagerModel]="pagerModel"
           [initialWidth]="width"
           (pagerUpdated)="refreshPage()"
           [refresh]="refreshPager"></pager>
  </div>

</ng-template>