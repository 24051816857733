import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BroadcastChannelService, BroadcastChannelMessageType } from '../shared/providers/BroadcastChannelService';
import { Strings } from '../shared/providers/globalData';

@Injectable()
export class LogoutActivateGuard implements CanActivate {

  constructor(
    @Inject(BroadcastChannelService) public broadcastChannelService: BroadcastChannelService,
    @Inject(Strings) public strings: Strings
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let hasChanges = await this.subscribeToUnsavedChangesResponse();

    // If another tab has unsaved changes, then show a confirmation dialog.
    // If the user want to leave, then the other tabs will be logged out aswell.
    if (hasChanges && !confirm("[OTHER TAB] " + this.strings.UnsavedChangesConfirmation))
      return false;
    else
      return true;
  }

  subscribeToUnsavedChangesResponse() {
    return new Promise<boolean>((resolve) => {

      let gotAnswered = false;

      // Listens for event only once. When triggered, it's removed.
      this.broadcastChannelService.broadcastChannel.addEventListener("message", (messageEvent: MessageEvent) => {

        if (!messageEvent.origin || messageEvent.origin != location.origin)
          resolve(false);

        gotAnswered = true;

        if (messageEvent.data.type == BroadcastChannelMessageType.HasUnsavedChangesResponse)
          resolve(true);
        else
          resolve(false);
      }, { once: true });

      this.broadcastChannelService.send(BroadcastChannelMessageType.HasUnsavedChangesRequest);

      setTimeout(() => {

        if (gotAnswered)
          return;

        resolve(false);

      }, 100);
    });
  }
}