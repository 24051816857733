import { ChangeDetectorRef, Component, HostListener, Inject, ChangeDetectionStrategy } from "@angular/core";
import { BreakPointAccessor, Breakpoints } from "../../../../shared/utils";
import { UIComponent } from "../../../shared";
import { Conf, Product, Tab, SeparatedTabPosition } from "../../../shared/models";
import { TabDisplayStyle, RouteRedirector } from "../../../shared/providers";
import { ProductDataStore } from "../../../shared/providers/productData";
import { PageUIData } from "../../../shared/state";
import { ConfiguratorLayoutManager, ConfiguratorStore, ConfiguratorUIStore, ConfPageSessionService, ConfRouteParams } from "../../providers";
import { TabVisibilityService } from "../../shared/tabVisibilityService";
import { VisualObjectNavigatorService } from "../../shared/visualObjectNavigatorService";
import { TabAvailabilityService } from "./tabAvailabilityService";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { IEmitDataInfo } from "../../shared/emitterService";

@Component({
  selector: 'tabs-manager',
  templateUrl: './tabsManagerComponent.html',
  providers: [TabAvailabilityService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsManagerComponent extends UIComponent {

  public readonly ACCORDION_TABS_LEFT_CONTAINER_ID: string = 'accordionLeftContainer';
  public readonly ACCORDION_TABS_RIGHT_CONTAINER_ID: string = 'accordionRightContainer';
  public readonly NORMAL_TABS_CONTAINER_ID: string = 'normalContainer';

  public shouldShowSeparatedTabs: boolean = true;
  public shouldMergeAccordionTabs: boolean = true;
  public shouldMergeLeftAndRightAccordionTabs: boolean = true;

  // White space between two adjacent columns.
  gutter: number = 2;

  // Sizes only for separated tabs region.
  public topTabsRegionHeight: number = 300; // Default values -> should be retreived through settings.
  public bottomTabsRegionHeight: number = 300; // Default values 
  public leftTabsRegionWidth: number = 400; // Default values 
  public rightTabsRegionWidth: number = 400; // Default values 

  // Layout is divided in three rows. Top, middle and bottom.
  public topRegionVisible: boolean = false;
  public middleRegionVisible: boolean = false;
  public bottomRegionVisible: boolean = false;

  // Expension only applicable in height
  public topRegionExpanded: boolean = false;
  public middleRegionExpanded: boolean = false;
  public bottomRegionExpanded: boolean = false;

  // Tabs
  public allTabs: Tab[] = [];
  public topTabs: Tab[] = [];
  public bottomTabs: Tab[] = [];
  public leftTabs: Tab[] = [];
  public rightTabs: Tab[] = [];
  public accordionTabsLeft: Tab[] = [];
  public accordionTabsRight: Tab[] = [];
  public normalTabs: Tab[] = [];
  public floatedTabs: Tab[] = [];

  // Customization
  public accordionChildSelectedTabs: Tab[] = [];

  public equalColWidthRegionTop: boolean = true; // Equal width for each column in top region ?
  public equalColWidthRegionMiddle: boolean = true; // Equal width for each column in middle region ?
  public equalColWidthRegionBottom: boolean = true; // Equal width for each column in bottom region ?

  // Active configuration
  public configurationId: number;
  public selectedAccordionTabLeftId: number;
  public selectedAccordionTabRightId: number;

  // Style for row.
  fillStyle: CSSStyleDeclaration;
  accordionCss: string;

  // Style for general tabs
  normalStyleVisible: boolean = true;

  constructor(
    private cd: ChangeDetectorRef,
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ProductDataStore) public productDataStore: ProductDataStore,
    @Inject(BreakPointAccessor) public breakPointAccessor: BreakPointAccessor,
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
    @Inject(ConfiguratorLayoutManager) public uiNormalizer: ConfiguratorLayoutManager,
    @Inject(VisualObjectNavigatorService) public visualObjectNavigatorService: VisualObjectNavigatorService,
    @Inject(TabAvailabilityService) public tabAvailablityService: TabAvailabilityService,
    @Inject(TabVisibilityService) public tabVisibilityService: TabVisibilityService,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector
  ) {
    super(confUIStore);
  }

  ngOnInit() {

    this.setTabsVisibilty();
    this.init();

    this.visualObjectNavigatorService.getAccordionChangeMessage().subscribe((selectedAccordionTabId: number) => {
      if (this.accordionTabsLeft.findIndex(x => x.longId == selectedAccordionTabId) > -1)
        this.selectedAccordionTabLeftId = selectedAccordionTabId;
      else if (this.accordionTabsRight.findIndex(x => x.longId == selectedAccordionTabId) > -1)
        this.selectedAccordionTabRightId = selectedAccordionTabId;

      this.cd.markForCheck();

    }).unsubscribeOn(this.unsubscribeSubject);

    this.confStore.onConfigurationChange(this.configurationId, this.confPageSession.confSessionId, (conf: Conf) => {

      this.init();

    }).unsubscribeOn(this.unsubscribeSubject);

    this.emitterService.getMessage().subscribe((info: IEmitDataInfo<Tab>) => {

      if (info.id == "append-to-parent") {
        this.accordionChildSelectedTabs = [info.tag];

        this.setTabsVisibilty();
        this.setupTabs();
        this.computeViews();
        this.computeHeightForExpandableRegion();

        this.cd.markForCheck();
      }

    }).unsubscribeOn(this.unsubscribeSubject);

    super.ngOnInit();
  }

  public init(): void {
    this.configurationId = this.confPageSession.activeConfigurationId;
    this.setupTabs();
    this.computeViews();
    this.computeHeightForExpandableRegion();
  }

  public isTabVisibilityHandled(): boolean {
    return false;
  }

  public trackTab(index: number, tab: Tab): any {
    return tab.longId;
  }

  public computeViews(): void {

    // Calculate top region height
    if (this.topTabs.length > 0) {
      let maxHeight: number = Math.max(...this.topTabs.map(x => x.separatedHeight));
      this.topTabsRegionHeight = maxHeight > 0 ? maxHeight : this.topTabsRegionHeight;
    }

    // Calculate bottom region height
    if (this.bottomTabs.length > 0) {
      let maxHeight: number = Math.max(...this.bottomTabs.map(x => x.separatedHeight));
      this.bottomTabsRegionHeight = maxHeight > 0 ? maxHeight : this.bottomTabsRegionHeight;
    }

    // Calulate visibility
    this.topRegionVisible = this.topTabs.length > 0;
    this.middleRegionVisible = this.leftTabs.length > 0 || this.normalTabs.length > 0 || this.rightTabs.length > 0;
    this.bottomRegionVisible = this.bottomTabs.length > 0;

    // Top & bottom regions must have atleast one auto width to fill the empty area. Otherwise last column would fill it.
    // Middle region empty room must be added up to normal-tabs container. If normal tab does not exists then the last column must occupy the empty area.
    // If all region's columns contain no width then make their widths equal.
    this.equalColWidthRegionTop = this.topTabs.filter(x => x.separatedWidth > 0).length == 0;
    this.equalColWidthRegionBottom = this.bottomTabs.filter(x => x.separatedWidth > 0).length == 0;

    // Does middle region contain auto width col ?
    // If normal tabs exits in middle region then empty area must be added up to normal tabs.
    if (this.normalTabs.length > 0) {
      this.equalColWidthRegionMiddle = false;
    }
    else {
      // Auto-width exists in left or right tabs?     
      this.equalColWidthRegionMiddle = this.leftTabs.filter(x => x.separatedWidth > 0).length == 0 && this.rightTabs.filter(x => x.separatedWidth > 0).length == 0;
    }

  }

  public computeHeightForExpandableRegion(): void {

    let topRegionHeight: number = this.topRegionVisible ? this.topTabsRegionHeight : 0;

    let bottomRegionHeight: number = this.bottomRegionVisible ? this.bottomTabsRegionHeight : 0;

    let visibleRegions: number = 0;
    visibleRegions += this.topRegionVisible ? 1 : 0;
    visibleRegions += this.bottomRegionVisible ? 1 : 0;
    visibleRegions += this.middleRegionVisible ? 1 : 0;

    // Calculate the vertical spaces between two adjacent rows. One full gutter is removed because half of the gutter on top & bottom is ignored.
    let totalGutterUsed: number = Math.max(0, visibleRegions * this.gutter - this.gutter);

    // As separated tabs height could be fixed height, but normal tabs height is auto. To calculate the auto/dynamic height first accumulate the top and bottom regions and subtract it from 100% to have the right
    // room for normal tabs.
    let removeHeight: number;

    // Rule ->
    // If top or bottom row exists then largest tab height would be the row height.
    // Middle/top row takes all available room. If there is only one row visible, then expand it to 100%.

    // Give the periority to middle row -> expand the middle to all available room.
    if (this.middleRegionVisible) {
      removeHeight = bottomRegionHeight + topRegionHeight + totalGutterUsed;
      this.middleRegionExpanded = true;
    }

    else if (this.topRegionVisible) {
      removeHeight = bottomRegionHeight + totalGutterUsed;
      this.topRegionExpanded = true;
    }

    else if (this.bottomRegionVisible) {
      removeHeight = bottomRegionHeight + totalGutterUsed;
      this.bottomRegionExpanded = true;
    }

    let expandableRegionalHeight: string = `calc(100% - ${removeHeight}px)`;

    // In case of IE make it big TODO: make better fix
    if (this.browserInfo.isIE && !this.browserInfo.isEdge)
      this.fillStyle = <CSSStyleDeclaration>{
        height: expandableRegionalHeight
      }
    else
      this.fillStyle = <CSSStyleDeclaration>{
        minHeight: expandableRegionalHeight
      }
  }

  public setupTabs(): void {

    // Load all tabs.    
    this.allTabs = [];
    let product: Product = this.productDataStore.getEntity<Product>(this.confPageSession.activeConfiguration.productId)
    product.tabs.forEach(tabId => {
      let tab: Tab = this.productDataStore.getEntity<Tab>(tabId);
      if (this.isVisible(tab))
        this.allTabs.push(tab);
    });
    this.filterTabs(this.allTabs);
  }

  public isVisible(tab: Tab): boolean {

    return this.tabVisibilityService.isVisible(tab, this.confPageSession.activeConfiguration);
  }

  public filterTabs(tabs: Tab[]): void {

    // Customization
    let subConfId = +this.routeRedirector.readFromRouteUrl(ConfRouteParams.SUB_CONF_ID);

    // Separated tabs
    let separatedTabs = tabs.filter(x => x.displayStyle == TabDisplayStyle.Separate);
    if (this.shouldShowSeparatedTabs) {
      this.floatedTabs = [];

      this.topTabs = separatedTabs.filter(x => x.separatedTabPosition === 'Top');
      this.bottomTabs = separatedTabs.filter(x => x.separatedTabPosition === 'Bottom');
      this.leftTabs = separatedTabs.filter(x => x.separatedTabPosition === 'Left');
      this.rightTabs = separatedTabs.filter(x => x.separatedTabPosition === 'Right');
    }
    else {
      this.floatedTabs = separatedTabs;

      // Clear all but separated
      this.topTabs = this.bottomTabs = this.leftTabs = this.rightTabs = [];
    }

    // Normal tabs.
    this.normalTabs = tabs.filter(x => x.displayStyle == TabDisplayStyle.Normal);

    // In small screens Accordion should be merged with normal tabs.
    if (this.shouldMergeAccordionTabs) {
      this.accordionTabsLeft = [];
      this.accordionTabsRight = [];
      this.normalTabs = tabs.filter(x => x.displayStyle == TabDisplayStyle.Normal || x.displayStyle == TabDisplayStyle.Accordion || x.displayStyle == TabDisplayStyle.AccordionHeaderTab || x.displayStyle == TabDisplayStyle.AccordionChildren);
    }
    else {
      this.normalTabs = tabs.filter(x => x.displayStyle == TabDisplayStyle.Normal);

      if (this.shouldMergeLeftAndRightAccordionTabs) {
        this.accordionTabsLeft = [];
        this.accordionTabsRight = [];

        let accordionTabs = tabs.filter(x => x.displayStyle == TabDisplayStyle.Accordion || x.displayStyle == TabDisplayStyle.AccordionHeaderTab || x.displayStyle == TabDisplayStyle.AccordionChildren);

        if (accordionTabs.some(x => x.separatedTabPosition.toLowerCase() == SeparatedTabPosition.left))
          this.accordionTabsLeft = accordionTabs;
        else
          this.accordionTabsRight = accordionTabs;
      }
      else {
        // Check if there are accordion children tabs with different positions and put them in the default position if so
        let overrideAccordionChildrenTabPositions = (tabs.filter(x => x.displayStyle == TabDisplayStyle.AccordionChildren).map(x => x.separatedTabPosition)).filter((x, i, a) => a.indexOf(x) == i).length > 1;

        this.accordionTabsLeft = tabs.filter(x => (x.displayStyle == TabDisplayStyle.Accordion || x.displayStyle == TabDisplayStyle.AccordionHeaderTab || x.displayStyle == TabDisplayStyle.AccordionChildren && !overrideAccordionChildrenTabPositions) && x.separatedTabPosition.toLowerCase() == SeparatedTabPosition.left);

        // Right is default position
        this.accordionTabsRight = tabs.filter(x => (x.displayStyle == TabDisplayStyle.Accordion || x.displayStyle == TabDisplayStyle.AccordionHeaderTab || x.displayStyle == TabDisplayStyle.AccordionChildren) &&
          (x.separatedTabPosition.toLowerCase() != SeparatedTabPosition.left || x.displayStyle == TabDisplayStyle.AccordionChildren && overrideAccordionChildrenTabPositions));
      }
    }

    // Customization
    this.leftTabs.forEach(x => {

      (<any>x).configurationId = this.configurationId;

    });

    if (subConfId && this.accordionChildSelectedTabs.length > 0 && !this.shouldMergeAccordionTabs) {

      this.topTabs = [];
      this.bottomTabs = [];
      this.leftTabs = [];
      this.rightTabs = [];
      separatedTabs = [];
      this.normalTabs = [];

      this.accordionChildSelectedTabs.forEach(tab => {
        (<any>tab).configurationId = subConfId;
      });

      this.leftTabs.push(...this.accordionChildSelectedTabs);
      this.shouldShowSeparatedTabs = true;
      /*this.normalTabs = */

    }
    // End customization

    // Add the visible tabs into availability service.
    if (this.normalTabs.length > 0)
      this.tabAvailablityService.addContainerId(this.NORMAL_TABS_CONTAINER_ID);


    let accordionUI = this.confUIStore.getConfiguratorPageUIState(this.pageId).accordionUI;
    if (accordionUI && accordionUI.visible && this.accordionTabsRight.length > 0)
      this.tabAvailablityService.addContainerId(this.ACCORDION_TABS_RIGHT_CONTAINER_ID);

    if (accordionUI && accordionUI.visible && this.accordionTabsLeft.length > 0)
      this.tabAvailablityService.addContainerId(this.ACCORDION_TABS_LEFT_CONTAINER_ID);

    if (this.shouldShowSeparatedTabs)
      separatedTabs.forEach(tab => this.tabAvailablityService.addContainerId(tab.longId.toString()));

  }

  // Temporary code
  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.setTabsVisibilty();
    this.setupTabs();
    this.computeViews();
    this.computeHeightForExpandableRegion();
  }

  get pageId(): number {
    return this.confPageSession.pageId;
  }

  onUIDataChange(uiData: PageUIData): void {

    // compare active configuration
    if (this.configurationId != this.confPageSession.activeConfigurationId) {
      this.configurationId = this.confPageSession.activeConfigurationId;
      this.setupTabs();
    }

  }

  setTabsVisibilty(): void {

    // Returns the visiblity of separated tabs with respect to window size. If the size gets smaller than the threshold value then contents would be moved to popup.
    this.shouldShowSeparatedTabs = window.innerWidth > this.globalDataStore.getGlobalData().globalSettings.separatedTabCollapseAtWindowWidth;

    // At small screens, merge the accordion and normal tab results.
    this.shouldMergeAccordionTabs = this.breakPointAccessor.equal(Breakpoints.xs);

    // At md screens, merge left and right accordions.
    this.shouldMergeLeftAndRightAccordionTabs = this.breakPointAccessor.equalOrDown(Breakpoints.lg);
  }


}