import { BaseRequest } from "./baseRequest";
import * as Immutable from "immutable";

export class ClientPushMessage extends BaseRequest {

  triggeringControlId: string;
  valuesById: Immutable.Map<string, string>;
  interactionSessionId: number;
  tempConnectionId: number;
  file: File;
}