/**
 * Copied from the C# class ErrorCodes.
 */
export class ErrorCodes {
  // 1xxx - Startup errors
  // Such as DB errors, directory/web settings or some other important resource not found
  // Any other errors which are important for the running of the application


  // 2xxx - Authentication/Authorization errors
  // Login session expired, User account has changed, force logout, access denied etc

  public static readonly AUTHENTICATION_EXPIRED = 2000;
  public static readonly WORK_SESSION_EXPIRED = 2001;
  public static readonly ACCESS_DENIED = 2002; // User was not authorized to perform the operation or access the resource
  public static readonly ACTIVE_USERS_COUNT_EXCEEDED = 2003;

  // 3xxx - ConfigurationAuthorization errors, Configuration errors
  // Configuration not found or Configurator session not found
  public static readonly CONFIGURATION_AUTHORIZATION_ERROR = 3000;
  public static readonly CONFIGURATOR_SESSION_NOT_FOUND = 3001;
  public static readonly CONFIGURATOR_SESSION_ACCESS_DENIED = 3002;
  public static readonly CONFIGURATION_EXPIRED = 3003;
  public static readonly CONFIGURATOR_SESSION_CLOSED = 3004;
  public static readonly CONFIGURATION_NOT_FOUND = 3005;
  public static readonly CONFIGURATOR_SESSION_OPERATION_NOT_ALLOWED = 3006;
  public static readonly CONFIGURATOR_SESSION_NOT_INITIALIZED = 3007;

  // 5xxx Search page related errors, Search session authorization
  public static readonly SEARCH_SESSION_NOT_FOUND = 5000;
  public static readonly SEARCH_SESSION_ACCESS_DENIED = 5001;

  // 6xxx Comparison page related errors
  public static readonly COMPARISON_SESSION_ACCESS_DENIED = 6000;

  // 4xxx - Unexpected errors
  // Invalid json, missing arguments etc.    

  public static readonly UNHANDLED_EXCEPTION = 4000;

  // Client version incompatible with server
  public static readonly CLIENT_VERSION_INCOMPATIBLE = 4001;

  public static isErrorCode(code: number) {

    let errorCodes = [ErrorCodes.ACCESS_DENIED];
    return errorCodes.indexOf(code) > -1;
  }

  public static isSuppressNotification(code: number) {
    let codes = [ErrorCodes.CLIENT_VERSION_INCOMPATIBLE];
    return codes.indexOf(code) > -1;
  }   

  public static isInformationCode(code: number) {

    let infoCodes = [ErrorCodes.AUTHENTICATION_EXPIRED, ErrorCodes.CLIENT_VERSION_INCOMPATIBLE, ErrorCodes.ACTIVE_USERS_COUNT_EXCEEDED];
    return infoCodes.indexOf(code) > -1;
  }

  public static isWarningCode(code: number) {

    let warningCodes = [ErrorCodes.WORK_SESSION_EXPIRED, ErrorCodes.SEARCH_SESSION_NOT_FOUND];
    return warningCodes.indexOf(code) > -1;
  }

  public static isConfiguratorErrorCode(code: number) {
    let configurationCodes = [ErrorCodes.CONFIGURATION_AUTHORIZATION_ERROR, ErrorCodes.CONFIGURATION_NOT_FOUND, ErrorCodes.CONFIGURATOR_SESSION_NOT_FOUND, ErrorCodes.CONFIGURATOR_SESSION_ACCESS_DENIED, ErrorCodes.CONFIGURATION_EXPIRED, ErrorCodes.CONFIGURATOR_SESSION_CLOSED];
    return configurationCodes.indexOf(code) > -1;
  }
}