import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { Routing } from "../../shared/route/routeDecorator";
import { BaseComponent } from "../shared";
import { AppStoreSubscriptionManager } from "../shared/providers";
import { AccountDataStore } from "../shared/providers/accountData";

@Routing({ path: 'logout' })
@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './logoutComponent.html',
  providers: [AppStoreSubscriptionManager]
})
export class LogoutComponent extends BaseComponent {

  constructor(
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    public appStoreSubscriptionManager: AppStoreSubscriptionManager,
  ) {
    super();
  }

  ngOnInit() {
    this.accountStore.logOutUser();
  }

  ngOnDestroy() {
    this.appStoreSubscriptionManager.dispose();
    super.ngOnDestroy();
  }
}