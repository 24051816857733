import { Host, Component, Optional, ViewChild, ElementRef, Input, forwardRef, Inject, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { BasePopupComponent } from './basePopupComponent';
import { BackdropComponent } from './backdropComponent';
import { PopupService } from './popupService';
import { SizeUtility, SizeUnit } from "../shared/sizeUtility";
import { BreakPointAccessor, Breakpoints } from "../../utils";
import { PopupInfo } from './popupInfo';
let Popper = require('popper.js').default;

export class Position {
  constructor(public x: number, public y: number) { }
}

@Component({
  selector: 'regular-popup',
  templateUrl: './regularPopupComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegularPopupComponent extends BasePopupComponent {

  @Input() public minHeight = '400px';
  @Input() public minWidth = '350px';  
  
  // Makes the popup's size auto. But It won't be smaller than its minimum widht/height.
  @Input() public autoResize = false;
  @Input() public fullHeight = false;
  @Input() public draggable = false;  
  @Input() public referenceContainerId: string;

  // Only works if showTitleBar == false.
  @Input() public showCloseButton = true;

  @Input() public okButtonClasses = "";
  @Input() public cancelButtonClasses = "";

  public positionX: string;
  public positionY: string;
  public orignal: Position = null;
  public tempPos: Position = new Position(0, 0);
  public referenceContainerOffsetTop: number;
  public moving = false;
  public config: PopupInfo<any>;
  public margin = 50;
  public popperInstance: any;

  // Root element.
  @ViewChild('regularPopupElement')
  public popupElement: ElementRef;

  public contentStyle: CSSStyleDeclaration;

  constructor(cd: ChangeDetectorRef,
    @Optional() @Host() @Inject(forwardRef(() => BackdropComponent)) backdrop: BackdropComponent,
    @Inject(BreakPointAccessor) breakPointAccessor: BreakPointAccessor,
    @Inject(PopupService) popupService: PopupService
  ) {
    super(cd, backdrop, breakPointAccessor, popupService);
  }
  
  get rootElement(): ElementRef {
    return null; // Not used in regular popup.
  }

  open() {
    // Remove any old style.
    this.contentStyle = null;
    this.detachPopper();

    super.open();

  }

  move(config: PopupInfo<any>): void {
    this.config = config;

    if (!this.config)
      return;

    // If the reference object is the part of accordion then It won't be rendered in accordion collapsed state.
    let referenceObject = document.getElementById(this.config.referenceElementId);
    if (!referenceObject)
      return;

    let container = document.getElementById(this.config.containerId);

    // Check if the element is a dropdown
    const dropdownMenu = referenceObject.querySelector('.dropdown-menu');

    // Dropdown needs space below the control for the control expansion.
    let placement = dropdownMenu ? 'top-start' : 'auto';
    let behavior = dropdownMenu ? ['right', 'top', 'left'] : ['right', 'top', 'left', 'bottom'];

    // Reuse the popper instance for subsequent requests
    if (this.popperInstance) {
      referenceObject.scrollIntoView(true);
      this.popperInstance.reference = referenceObject;

      this.popperInstance.options.placement = placement;
      this.popperInstance.options.modifiers.flip.behavior = behavior;

      this.popperInstance.modifiers.find(x => x.name == 'preventOverflow').boundariesElement = container;

      this.popperInstance.update();
      return;
    }

    // Popup element
    let popup = this.popupElement.nativeElement;

    // Container element

    // Container top position. TODO: We can get rid of this, if we get the offset position from popper.
    this.referenceContainerOffsetTop = container.getClientRects()[0].top;

    if (!referenceObject || window.innerWidth < Breakpoints.md.min) {

      this.normalizeSize();
      return;
    }

    referenceObject.scrollIntoView(true);

    this.popperInstance = new Popper(
      referenceObject,
      popup,
      {
        placement: placement,
        modifiers: {
          offset:
          {
            enabled: true,
            offset: "20,20,20,20"
          },
          flip: {
            behavior: behavior
          },
          arrow: {
            enabled: false
          },
          keepTogether: { enabled: false },
          preventOverflow: {
            boundariesElement: container,
            escapeWithReference: false
          },
          applyStyle: { enabled: true }
        }
      },
    );
  }

  detachPopper(): void {
    if (this.popperInstance) {
      // As popper removes the styles applied over the popup, We don't want them to be removed and want it to be on the same position while releasing the element reference.
      this.popperInstance.modifiers.filter(x => x.name == 'applyStyle')[0].enabled = false;
      this.popperInstance.disableEventListeners();
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
  }

  /**
  * Sets the side bar position.
  */
  public normalizeSize(): void {
    // Calculated size after checking the screen size
    let calWidth: string;

    if (SizeUtility.onlyUnit(this.width) === SizeUnit.percentage) {
      // Using percentage and small screen -> maximize
      this.calcMaximize = this.maximize || this.breakPointAccessor.activeBreakPoint.max < Breakpoints.sm.max;
    }
    else {
      // Margin so that we could maximize the popup before hitting the window.width
      const margin: number = 30;
      this.calcMaximize = this.maximize || SizeUtility.sizeAsNumber(this.width) + margin > innerWidth;
    }

    if (this.calcMaximize || !this.isOpened)
      this.positionX = this.positionY = null;

    calWidth = this.width;
    // During the show/hide transitions, width must be same.    
    if (this.width != '100%' && SizeUtility.sizeAsNumber(this.width) + this.responsivenessActivationMargin > window.innerWidth) {

      calWidth = `${window.innerWidth}px`;

    }

    if (!this.contentStyle)
      this.contentStyle = <CSSStyleDeclaration>{};      

    this.contentStyle.maxHeight = "100%";
    this.contentStyle.maxWidth = calWidth;
    this.contentStyle.width = '100%';
    this.contentStyle.minHeight = this.minHeight;

    if (!this.calcMaximize)
      this.contentStyle.minWidth = this.minWidth;

    if (this.autoResize) {
      this.contentStyle.width = null;
      this.contentStyle.height = null;
      this.contentStyle.maxWidth = '100%';
    }

    this.cd.markForCheck();
  }

  private getPosition(x: number, y: number) {
    return new Position(x, y);
  }

  onMouseDown(event: any) {

    // Skip right click;    
    if (event.button == 2 || !event.target) {
      return;
    }

    if ($(event.target).closest('.popup-close-button').length > 0)
      return;

    this.orignal = this.getPosition(event.clientX, event.clientY);
    this.tempPos = this.getPosition(event.clientX, event.clientY);

    if (!this.moving)
      this.moving = true;
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: any) {

    if (!this.isOpened)
      return;

    // Note! If the mouse movement not synchronized with popup then "mouseup" event triggers automatically when mouse goes beyond of the popup's bounds, that's why it is registered at document level.
    if (!this.draggable)
      return;

    if (this.moving) {      
      this.moveTo(event.clientX, event.clientY);
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: any) {
    
    if (!this.isOpened)
      return;

    if (!this.draggable)
      return;

    this.moving = false;

    if (this.orignal) {

      let diffX: number = event.clientX - this.orignal.x;
      let diffY: number = event.clientY - this.orignal.y;

      // Detach the popper if popup is dragged more than 50 pixels.
      if (Math.abs(diffX) > 50 || Math.abs(diffY) > 50)
        this.detachPopper();
    }
  }

  public moveTo(x: number, y: number) {

    this.orignal.x = this.tempPos.x - x;
    this.orignal.y = this.tempPos.y - y;
    this.tempPos.x = x;
    this.tempPos.y = y;

    if (!this.draggable)
      return;

    if (!this.referenceContainerId)
      throw new Error("Input parameter 'referenceContainerId' must be supplied for draggable popups.");

    if (!this.referenceContainerOffsetTop) {
      // Container element
      let container = document.getElementById(this.referenceContainerId);
      this.referenceContainerOffsetTop = container.getClientRects()[0].top;
    }

    let rect = this.popupElement.nativeElement.getClientRects()[0];
    let leftPopupPosition: number = rect.left - this.orignal.x;
    let topPopupPosition: number = rect.top - this.orignal.y - this.referenceContainerOffsetTop;

    if (topPopupPosition < -this.referenceContainerOffsetTop)
      topPopupPosition = -this.referenceContainerOffsetTop;

    // Apply new transformation

    this.contentStyle.transform = `translate3d(${leftPopupPosition}px, ${topPopupPosition}px, 0px)`;
    this.contentStyle.left = "0px";
    this.contentStyle.top = "0px";
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.isOpened) {
      this.move(this.config);
      this.calculateMaximizeState();
      this.normalizeSize();
    }
  }

  ngOnDestroy() {
    this.detachPopper();

    super.ngOnDestroy();
  }
}