import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

import { RouteRedirector } from "./routeRedirector";
import { AccountDataStore } from "./accountData/accountDataStore";
import { UserService } from './userService';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(public routerRedirector: RouteRedirector,
    public accountStore: AccountDataStore,
    public userService: UserService
    )
  {}

  public redirect(state: RouterStateSnapshot) {
    this.routerRedirector.redirectToLogin(state.url);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.userService.isUserLoggedIn())
      return true;
    
    this.redirect(state);
    return false;
  }
}