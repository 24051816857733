import { Component, Input, ViewChild, Inject, SimpleChanges, Output, ChangeDetectorRef, EventEmitter } from "@angular/core";
import * as Immutable from "immutable";
import { BomColumnSetting } from "../../../shared/models";
import { DataRowView, DataCellView, PopupService, PopperConfig, PopoverService, PopupInfo, PopupActions, IdentifierUtility } from "../../../../shared/components";
import { BaseComponent } from "../../../shared";
import { Grid } from "../../../shared/models/responses";
import { BomImagePopupComponent } from "../../popups";
import { PopupIdentifiers } from "../../providers";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { EmitterService } from "..";
import { Subscription } from "rxjs";

@Component({
  selector: 'bom-table',
  templateUrl: './bomTableComponent.html',
})
export class BomTableComponent extends BaseComponent {

  @Input()
  public detailsMode: boolean = false;

  @Input()
  public width: "100%";

  @Input()
  public grid: Grid;

  @Input()
  public columnSettings: Immutable.List<BomColumnSetting>;

  @Output()
  selectionChanged = new EventEmitter();

  public rows: Array<DataRowView> = [];
  public columns: Array<DataCellView> = [];
  public totalRow: DataRowView;
  public selectedRow: DataRowView;
  public messageSubscription: Subscription;

  constructor(public popupService: PopupService,
    public cd: ChangeDetectorRef,
    private globalDataStore: GlobalDataStore    
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['columnSettings'] || changes['grid']) {
      if (this.grid && this.columnSettings)
        this.createTable()
    }
  }

  ngOnInit(): void {

    this.messageSubscription = this.popupService.getMessage().subscribe((info: PopupInfo<any>) => {

      if (info.id == PopupIdentifiers.BomImage && info.action == PopupActions.Closed) {
        this.clearIconSelection();
        this.cd.markForCheck();
      }

    });

    super.ngOnInit();
  }

  createTable(): void {
    this.rows = [];
    this.columns = [];

    let sortedColumns = this.columnSettings.sortBy(x => x.order);
    // If it is not details mode then skip columns which are details only
    if (!this.detailsMode)
      sortedColumns = sortedColumns.filter(x => !x.detailsOnly);

    // Get the columns in the correct order
    sortedColumns.forEach((column: BomColumnSetting) => {
      let columnView: DataCellView = {
        contents: column.property != "Icon" ? column.title : "",
        classes: 'ellipsis + text-' + column.columnAlignment.toLowerCase(),
        width: column.width > 0 ? column.width : null,
        safeStyle: this.columnStyle(column.width)
      };
      this.columns.push(columnView);
    });

    this.grid.rows.forEach(rowData => {
      // Create a row model for each item
      let editableItem = !rowData.readOnly;

      // Create editable item row with its object ID and class to make it selectable.
      let rowView: DataRowView;
      if (editableItem) {
        let id = rowData.values.has("id") ? rowData.values.get("id") : rowData.id ? rowData.id.toString() : null
        rowView = { id: id, cells: [], classes: "editableItem" };
      }
      else
        rowView = { cells: [], classes: "" };


      if (rowData.warningMessage)
        rowView.classes += " warning";

      sortedColumns.forEach((column: BomColumnSetting) => {
        let valueCell: DataCellView = {
          id: column.property,
          contents: (column.property == "Property2" && rowData.warningMessage ? rowData.warningMessage : "") + rowData.values.get(column.property),
          classes: 'ellipsis-wrap text-' + column.columnAlignment.toLowerCase(),
          width: column.width > 0 ? column.width : null,
          safeStyle: this.columnStyle(column.width)
        };

        if (valueCell.id == "Icon") {
          valueCell.cellId = IdentifierUtility.uniqueId;
        }

        rowView.cells.push(valueCell);
      });

      this.rows.push(rowView);
    });

    this.totalRow = { cells: [], classes: 'useHover total' };

    sortedColumns.forEach((column: BomColumnSetting) => {
      let valueCell: DataCellView = {
        classes: 'ellipsis-wrap text-' + column.columnAlignment.toLowerCase(),
        width: column.width > 0 ? column.width : null,
        safeStyle: this.columnStyle(column.width)
      };
      // TODO - Total data is only included in this.grid.total when both cost+price is included,
      // should be possible to just show cost without price.
      valueCell.contents = this.grid.additionalData.get(column.property) || "";

      this.totalRow.cells.push(valueCell);
    });
    // Don't display empty row
    let hasValue = this.totalRow.cells.some(x => x.contents != null && x.contents !== "");
    if (hasValue)
      this.rows.push(this.totalRow);
  }

  /**  Calculate the column width */
  public columnStyle(columnWidth: number) {
    let totalWidth = 0;
    let noOfZeroWidthColumns = 0;
    let noOfColumns = 0;
    this.columnSettings.forEach((column: BomColumnSetting, index: number) => {

      // don't include the non-detailsonly in normal mode.
      if (!this.detailsMode && column.detailsOnly)
        return;
      noOfColumns++;

      if (column.width <= 0)
        noOfZeroWidthColumns++;
      else
        totalWidth += column.width;
    });

    if (totalWidth == 0 && noOfZeroWidthColumns > 0)
      return { "width": 100 / noOfZeroWidthColumns + "%" };

    let defaultWidth = totalWidth / noOfColumns;

    if (columnWidth == null || columnWidth <= 0)
      columnWidth = defaultWidth;

    totalWidth = totalWidth + (noOfZeroWidthColumns * defaultWidth);
    columnWidth = (columnWidth / totalWidth) * 100;

    return { "width": columnWidth + "%" };
  }

  selectRow(row: DataRowView) {
    // Only editable items are clickable, otherwise null.
    this.selectedRow = row.id ? row : null;
    this.selectionChanged.emit(this.selectedRow);
  }

  public showImageRequest(event: any, cell: DataCellView) {

    this.clearIconSelection();
    cell.selected = true;

    // Prepare context menu Info.
    let info = <PopupInfo<DataCellView>>{
      id: PopupIdentifiers.BomImage,
      tag: cell,
      referenceElementId: cell.cellId,
      action: PopupActions.Open,
      containerId: "configuratorContainer"
    };

    this.emitterService.send<PopupInfo<DataCellView>>(PopupIdentifiers.BomImage, info);
    event.stopPropagation();
    
  }

  clearIconSelection(): void {
    
    for (let row of this.rows)
      row.cells.forEach(c => {
        if (c.id == 'Icon')
          c.selected = false;
      });
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
    super.ngOnDestroy();
  }

}