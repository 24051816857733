import { Component, Input, ViewChild, Inject, Host, ChangeDetectorRef, ChangeDetectionStrategy, SimpleChanges, EventEmitter, Output } from "@angular/core";
import { Tab, Conf } from "../../shared/models";
import { ConfMessageProvider, ConfiguratorStore, ConfPageSessionService } from "../providers";
import { VisualObjectGroup } from "./visualObjectGroup";
import { VisualObjectContainerService } from "./visualObjectContainerService";
import { ManagedSubscription } from "../../../shared/managedSubscription";
import { BaseComponent } from "../../shared";
import { ElementRef } from "@angular/core";
import { HostListener } from "@angular/core";
import { Breakpoints, BreakPointAccessor } from "../../../shared/utils";
import { TabDisplayStyle } from "../../shared/providers";
import { VisualObjectHelper } from "../parameters/shared";
import { VisualObjectUIDataService } from "./visualObjectUIDataService";

@Component({
  selector: 'visual-object-container',
  templateUrl: './visualObjectsContainerComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VisualObjectContainerService, ConfMessageProvider, VisualObjectHelper, VisualObjectUIDataService]
})
export class VisualObjectsContainerComponent extends BaseComponent {

  @Input()
  public tab: Tab;

  public showTabComposite: boolean = false;

  public groups: VisualObjectGroup[] = [];

  @ViewChild('container')
  public container: ElementRef;

  public styles: string;
  public innerStyles: string;

  @Input()
  public configurationId: number;

  @Input()
  public pageId: number;

  public isFlexContainer: boolean = false;

  public activeConfSubscription: ManagedSubscription;

  public loadingComplete: boolean = false;

  public productId: number;

  public isPageValid: boolean = true;

  @Output()
  public accordionComposite = new EventEmitter();

  constructor(
    @Host() @Inject(VisualObjectContainerService) public visualObjectService: VisualObjectContainerService,
    @Host() @Inject(ConfMessageProvider) public confMessageProvider: ConfMessageProvider,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(BreakPointAccessor) public breakPointAccessor: BreakPointAccessor,
    public cd: ChangeDetectorRef    
  ) {
    super();

  }

  ngOnInit() {

    if (this.showTabComposite)
      this.accordionComposite.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['configurationId']) {

      this.unsubscribe();
      // Clear the cache to always rerender the visual objects on configuration change.
      this.visualObjectService.clear();

      let conf: Conf = this.confStore.getConf(this.configurationId, this.storeSession.confSessionId);

      if (!conf) {
        this.isPageValid = false;
        return;
      }      

      if (this.productId && this.productId == conf.productId)
      {
        // The param controls can not detect a conf-id change, when a tab for the same product is loaded.
        // This code is only run when switching sibling configurations through push messages within the composite accordion.
        // Mandatory popup could possibly also trigger this same code.
        this.groups = [];
        this.cd.detectChanges();
      }
      else
        this.productId = conf.productId;

      this.setCompositeConfigurationTabInfo();
      this.loadingComplete = false;
      // Listen the configuration values changes
      this.activeConfSubscription = this.confStore.onConfigurationChange(this.configurationId, this.storeSession.confSessionId, (conf: Conf) => {

        if (!this.loadingComplete) {

          this.loadingComplete = true;
          return;
        }

        this.visualObjectService.evaluateResponsiveness(conf);
        this.setCompositeConfigurationTabInfo();
      });
    }
    else if (changes['tab']) {

      if (this.tab) {
        this.visualObjectService.clear();
        this.setCompositeConfigurationTabInfo();
      }
      
    }
  }

  public setCompositeConfigurationTabInfo() {
    
    this.showTabComposite = this.tab.displayStyle == TabDisplayStyle.AccordionChildren;
    let conf: Conf = this.confStore.getConf(this.configurationId, this.storeSession.confSessionId);
    this.visualObjectService.setup(this.tab, conf);
    this.groups = this.visualObjectService.getRootVisualObjectGroups(this.tab);    
    this.isFlexContainer = this.visualObjectService.hasFlexContainer(this.tab);
    this.setStyles();
    this.cd.markForCheck();
  }

  public trackGroup(index: number, group: VisualObjectGroup): any {
    return group.id;
  }

  ngOnDestroy() {
    this.unsubscribe();

    super.ngOnDestroy();
  }

  unsubscribe() {
    if (this.activeConfSubscription)
      this.activeConfSubscription.unsubscribe();
  }

  setStyles(): void {

    if (!this.isFlexContainer)
      this.styles = '';

    if (this.breakPointAccessor.equalOrDown(Breakpoints.xs) || (this.container && this.container.nativeElement && this.container.nativeElement.offsetWidth <= Breakpoints.xs)) {
      this.styles += ' small-container';
    }
    else this.styles += '';

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setStyles();
  }

}