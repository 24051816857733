import { Injectable } from "@angular/core";

@Injectable()
export class BroadcastChannelService {

  public constructor() { }

  /**
   * Making sure that the tab only has one instance of the Broadcast Channel.
   * Otherwise the other Broadcast Channel instances within the same tab will get the message aswell.
   * In short, a tab can have multiple Broadcast Channels with the same name.
   * If one of them sends a message, that message will get to all of the Broadcast Channels in the tab.
   * Except the one that sent the message.
   */
  private _broadcastChannel: BroadcastChannel;
  public get broadcastChannel() {
    if (!this._broadcastChannel)
      this._broadcastChannel = new BroadcastChannel("combinum-app");

    return this._broadcastChannel;
  }

  public send(type: BroadcastChannelMessageType, payload?: any) {
    let message = {
      type: type,
      payload: payload
    };

    this.broadcastChannel.postMessage(message);
  }
}

export enum BroadcastChannelMessageType {
  ReloadCache = "reload-cache",
  LogOutUser = "log-out-user",
  UserSignedIn = "user-signed-in",
  HasUnsavedChangesRequest = "has-unsaved-changes-request",
  HasUnsavedChangesResponse = "has-unsaved-changes-response",
}