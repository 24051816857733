import { ChangeDetectorRef, Component, Inject, Input, ViewEncapsulation, ViewChild, SimpleChanges, NgZone } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { FormModel } from "..";
import { InputViewModel, NotificationInfo, NotificationService, NotificationType, PopupService, MessageBoxComponent, MessageBoxConfig } from "../../../shared/components";
import { Routing } from "../../../shared/route/routeDecorator";
import { ImageSets, Delay } from "../../../shared/utils";
import { PopupIdentifiers } from "../../configurator/providers";
import { PageComponent } from "../../shared";
import { LoginMessage, User, LogoutMessage } from "../../shared/models";
import { ApplicationLifeCycleService, AppStoreSubscriptionManager, RouteNames, RouteRedirector, IHttpDataInfo, BroadcastChannelService, BroadcastChannelMessageType } from "../../shared/providers";
import { AccountDataMessageProvider, AccountDataStore } from "../../shared/providers/accountData";
import { GlobalDataStore } from "../../shared/providers/globalData";
import { PageStore } from "../../shared/providers/page";
import { HttpService } from "../../../shared/providers/httpService";
import { RecaptchaComponent } from "../../../shared/components/recaptcha/recaptcha.component";
import { UserService } from "../../shared/providers/userService";
import { SignalRService } from "../../shared/providers/pushMessage/signalRService";

@Routing({ path: 'account/login' })
@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './loginComponent.html',
  providers: [AppStoreSubscriptionManager]
})
export class LoginComponent extends PageComponent {

  protected returnUrl: string;
  protected isNonSpaReturnUrl: boolean = false;

  public errorMessages: Array<string>;

  public userNameView: InputViewModel;
  public passwordView: InputViewModel;

  @Input()
  public errorMessage: string;
    
  public showRecaptcha: boolean = false;
  public recaptchaResolved: boolean = false;
  public siteKey: string;
  public formModel: FormModel = {};

  public useAzureSSO: boolean = false;
  public azureSSOButtonText: string = "";
  public azureSSOButtonColor: string = "";
  public azureSSOLoginUrl: string = "";
  public azureSSOUpn: string = "";
  private AUTOLOGIN_UPN_PARAM: string = "ssoupn=";
  
  // MsCrm
  public isInMsCrmMode: boolean = false;

  @ViewChild(RecaptchaComponent)
  protected recaptchaComponent: RecaptchaComponent;
  protected recaptchaKey: string;

  @ViewChild("confirmLoginMessageBox")
  protected confirmLoginMessageBox: MessageBoxComponent;

  constructor(
    @Inject(ActivatedRoute) public activatedRoute: ActivatedRoute,
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(HttpService) public httpService: HttpService,
    @Inject(BroadcastChannelService) public broadcastChannelService: BroadcastChannelService,
    @Inject(UserService) public userService: UserService,
    @Inject(SignalRService) public signalRService: SignalRService,
    public globalDataStore: GlobalDataStore,
    public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    public appLifeCycleService: ApplicationLifeCycleService,
    public accountMessageProvider: AccountDataMessageProvider,
    public notificationService: NotificationService,
    public cdr: ChangeDetectorRef,
    private ngZone: NgZone,

  ) {
    super();
  }

  ngOnInit() {
    this.userNameView = new InputViewModel(this.strings.Username);
    this.userNameView.name = "identity";
    this.userNameView.icon = "user";

    this.passwordView = new InputViewModel(this.strings.Password);
    this.passwordView.name = "password";
    this.passwordView.icon = "password";
    this.passwordView.iconImageSet = this.getImageSet(ImageSets.Control);

    this.showRecaptcha = this.accountStore.showRecaptcha();

    this.pageStore.updateActiveRoute(RouteNames.Login);    
    this.useAzureSSO = this.globalDataStore.getGlobalData().globalSettings.useAzureSSO;
    this.azureSSOLoginUrl = this.globalDataStore.getGlobalData().globalSettings.azureSSOLoginUrl;

    // MsCrm
    this.isInMsCrmMode = this.globalDataStore.getGlobalData().globalSettings.isInMsCrmMode;

    this.activatedRoute.params.subscribe(params => {
      // Remove the return url argument from the arguments, and the rest of the arguments should be forwarded
      // Grab returnurl directly from params object without stringifying the entire object and trimming it.
      if (params['returnurl'])
        this.returnUrl = params['returnurl'];
      
      if (this.returnUrl && this.returnUrl?.includes(this.AUTOLOGIN_UPN_PARAM) && this.useAzureSSO) {
        this.setAzureUpn(this.returnUrl);
        this.azureSso(null);
      }
      else {

        let cachedRedirectUrl = localStorage.getItem("cachedRedirectUrl");
        if (cachedRedirectUrl && cachedRedirectUrl?.includes(this.AUTOLOGIN_UPN_PARAM) && this.useAzureSSO) {
          console.log('-------------->The cachedRedirectUrl url is used<--------------');
          this.returnUrl = cachedRedirectUrl;
          this.setAzureUpn(this.returnUrl);
          this.azureSso(null);
          localStorage.setItem("cachedRedirectUrl", undefined);
        }
      }

    });
    this.activatedRoute.queryParams.subscribe(queryParams => {

      if (queryParams['returnurl']) {
        this.isNonSpaReturnUrl = true;
        this.returnUrl = queryParams['returnurl'];
      }
    });

    if (this.globalDataStore.getGlobalData().globalSettings.showCookieInformation) {
      this.notificationService.notify(<NotificationInfo>{
        title: this.strings.CookieInformation,
        message: " ",
        detail: this.strings.CookieInformationMessage,
        rawInfo: this.strings.CookieInformationMessageDetails,
        type: NotificationType.Info,
        identifier: "cookieInformation",
        selfClose: false
      });

      sessionStorage.setItem("cookieConsent", "true");
    }

    this.formModel.captcha = '';
    this.siteKey = this.globalDataStore.getGlobalData().globalSettings.googleReCaptcha;

    super.ngOnInit();
  }

  // MsCrm ->
  public sso(event) {
    let url = '/mscrm/getSSOUrl#';
    this.httpService.get(url).subscribe(data => {
      //window.location.href = data;
      let titel = "Single Sign On";
      var w = 600; var h = 600;
      this.popupwindow(data, titel, w, h);
    });
  }
  // MsCrm <-

  public setAzureUpn(returnUrl: string) {
    this.azureSSOUpn = this.returnUrl?.split(';')?.find(x => x.includes(this.AUTOLOGIN_UPN_PARAM))?.replace(this.AUTOLOGIN_UPN_PARAM, '')?.replace(';', '');
  }

  public azureSso(event) {
    let url = this.azureSSOLoginUrl;
    if (this.azureSSOUpn)
      url += "&login_hint=" + this.azureSSOUpn;
    else
      url += "&prompt=select_account";

    let titel = "Single Sign On";
    var w = 600; var h = 600;
    this.popupwindow(url, titel, w, h);
  }

  public popupwindow(url, title, w, h) {
    var left = (screen.width / 2) - (w / 2);
    var top = (screen.height / 2) - (h / 2);
    var SSO_login_window = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
    var timer = setInterval(() => {
      try {
        if (SSO_login_window.closed) {
          clearInterval(timer);
        }
      } catch (e) {
        alert("Please enable using popups for this site in the browser so that you can be authenticated with SSO.");
        clearInterval(timer);
      }
    }, 1000);
    return SSO_login_window;
  }


  public redirect() {

    let user = this.userService.getUser();
    if (!user)
      return;

    if (user.systemAuthorization.hasAnonymousAccess) {
      this.routeRedirector.redirectToSelector();
      return;
    }

    if (this.returnUrl) {

      // If the return url is outside the single page application, then router redirector cannot navigate outside the single page application.
      if (this.isNonSpaReturnUrl)
        this.routeRedirector.redirectByAbsolutUrl(this.returnUrl);
      else {
        this.routeRedirector.redirectByUrl(this.returnUrl);
      }
    }    
    else
      this.routeRedirector.redirectToStart();
  }

  public redirectIfLoggedIn() {
    if (!this.userService.isUserLoggedIn())
      return;

    this.redirect();
  }

  ngAfterContentInit() {
    this.redirectIfLoggedIn();

    // Listen for login message
    this.accountMessageProvider.onMessages<LoginMessage>(LoginMessage.name, {
      next: (messages) => {

        let loginMessage = messages.get(0);
        if (loginMessage.success) {
          this.appLifeCycleService.reloadAppData(() => {
            
            // show password expiry warning
            if (loginMessage.passwordExpiryWarning) {
              this.notificationService.notify(<NotificationInfo>{
                title: this.strings.Warning,
                message: loginMessage.passwordExpiryWarning,
                type: NotificationType.Warning,
                selfClose: false
              });
            }

            if (loginMessage.message) {
              this.notificationService.notify(<NotificationInfo>{
                title: this.strings.Info,
                message: loginMessage.message,
                type: NotificationType.Info,
                selfClose: true
              });
            }

            this.redirectIfLoggedIn();
            this.broadcastChannelService.send(BroadcastChannelMessageType.UserSignedIn);
          });
        }
        else if (loginMessage.isPasswordExpired) {
          // password is expired -> force user to update it
          this.unblockUI();
          this.popupService.open(PopupIdentifiers.ChangePassword, this.userNameView.value);
        }
        else if (loginMessage.showLoginConfirmation) {
          let info: MessageBoxConfig<string> = <MessageBoxConfig<string>>{
            description: loginMessage.loginConfirmationMessage,
            icon: "info"
          }

          this.unblockUI();
          this.confirmLoginMessageBox.show(info);
        }
        else {
          this.showRecaptcha = this.accountStore.showRecaptcha();
          this.unblockUI();
          this.errorMessages = loginMessage.errors.toArray();
          this.cdr.detectChanges();
        }
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  ngOnDestroy() {
    this.appStoreSubscriptionManager.dispose();
    super.ngOnDestroy();
  }

  public login(): void {
    // Remove white space from username.
    if (this.userNameView.value)
      this.userNameView.value = (this.userNameView.value as string).trim();

    if (!this.showRecaptcha || (this.showRecaptcha && this.recaptchaResolved)) {
      this.accountStore.logInUser(this.userNameView.value, this.passwordView.value, this.recaptchaKey, false);
      this.plainBlockUI();
    }

    if (this.showRecaptcha && !this.recaptchaResolved) {
      if (!this.recaptchaResolved) {
        this.errorMessages = [];
        this.errorMessages.push(this.strings.TheFormMustBeFilledOut);
        this.errorMessages[0];
      }
      return;
    }

    if (this.recaptchaComponent) {
      this.recaptchaComponent.reset();
      this.recaptchaResolved = false;
    }
  }

  onLoginConfirmed() {
    this.accountStore.logInUser(this.userNameView.value, this.passwordView.value, this.recaptchaKey);
    this.plainBlockUI();
  }

  public resolved(event: any): boolean {
    this.recaptchaKey = event;
    return this.recaptchaResolved = true;
  }

  onHttpLifeCycleUpdate(http: IHttpDataInfo) {
    // Prevents unblocking UI before login is completed
  }

}