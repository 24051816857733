import { ChangeDetectorRef, Output, EventEmitter, Input, SimpleChanges, ElementRef, HostListener, Component, } from '@angular/core';
import { Subscription } from 'rxjs';
import { BackdropComponent } from './backdropComponent';
import { BreakPointAccessor } from "../../utils";
import { PopupService } from "./popupService";
import { PopupInfo, PopupActions } from "./popupInfo";
import { SizeUtility } from "../shared";

@Component({ template: '' })
export abstract class BasePopupComponent {

  public defaultOkButtonText = "Ok";
  public defaultCancelButtonText = "Cancel";

  @Input() public id: string;

  @Input() public width = '450px';

  @Input() public height: string;

  @Input() public title: string;

  @Input()
  public okButton = true;

  @Input()
  public cancelButton = true;

  @Input()
  public okButtonText: string;

  @Input()
  public cancelButtonText: string;

  @Input()
  public showTitleBar = true;

  @Input()
  public showHeader = false;

  @Input()
  public showFooter = false;

  @Input()
  public forceWidth = false;

  @Input() public readonly maximize = false;
  public calcMaximize = false;

  @Input()
  public classes = "";

  @Input()
  public closeOnOutSideClick = true;

  /** True to close the popup on OK, otherwise leave it open. */
  @Input()
  public closeOnOk = true;

  @Output()
  public okClick = new EventEmitter();

  @Output()
  public cancelClick = new EventEmitter();

  @Output()
  public outsideClick = new EventEmitter();

  @Output()
  public onMaximizeStart = new EventEmitter();

  @Output()
  public onMaximizeEnd = new EventEmitter();

  public responsivenessActivationMargin: number = 30;

  @Output() onOpenStart: EventEmitter<null> = new EventEmitter<null>();
  @Output() onOpened: EventEmitter<null> = new EventEmitter<null>();
  @Output() onCloseStart: EventEmitter<null> = new EventEmitter<null>();
  @Output() onClosed: EventEmitter<null> = new EventEmitter<null>();
  @Output() public positionChange = new EventEmitter<PopupInfo<any>>();

  @Input() isOpened = false;

  funcTransitionEnd = (e) => this.onTransitionEnd(e);

  messageSubscription: Subscription;

  constructor(
    public cd: ChangeDetectorRef,
    public backdrop: BackdropComponent,
    public breakPointAccessor: BreakPointAccessor,
    public popupService: PopupService
  ) {
    if (this.backdrop)
      this.backdrop.add(this);
  }

  ngOnInit(): void {
    this.messageSubscription = this.popupService.getMessage().subscribe((info: PopupInfo<any>) => {
      if (info.id == this.id) {
        this.executeAction(info);
      }
    })

    this.normalizeSize();
  }

  abstract get rootElement(): ElementRef;

  public executeAction(info: PopupInfo<any>): void {
    switch (info.action) {
      case PopupActions.Open:

        if(!this.isOpened)
          this.open();

        break;

      case PopupActions.Close:
        this.close();
        break;

      case PopupActions.Disable:
        this.enable(false);
        break;

      case PopupActions.Enable:
        this.enable(true);
        break;

      case PopupActions.Move:
        this.move(info);
        break;

      case PopupActions.Update:
        this.update(info);
        break;
    }

    this.cd.markForCheck();
  }

  public normalizeSize(): void {

  }

  public update(info: PopupInfo<any>): void {

  }

  public calculateMaximizeState(): void {

    let oldCalcMax = this.calcMaximize;

    if (this.width == '100%')
      this.calcMaximize = true;

    // TODO: Get the offset width, because sometimes It could be in percentage.
    else this.calcMaximize = this.maximize || SizeUtility.sizeAsNumber(this.width) + this.responsivenessActivationMargin > innerWidth;

    if (this.calcMaximize != oldCalcMax) {

      if (this.calcMaximize)
        this.onMaximizeStart.emit();

      else this.onMaximizeEnd.emit();
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isOpened']) {
      if (changes['isOpened'].currentValue)
        this.open();
      else this.close();
    }
  }

  open(): void {
    this.isOpened = true;
    this.cd.markForCheck();
    this.onOpenStart.emit();
    this.normalizeSize();

    if (this.rootElement) {
      this.rootElement.nativeElement.addEventListener('transitionend', this.funcTransitionEnd);
    }
    else
      // No root element to transition -> emit onOpened directly.
      this.onOpened.emit();

    this.cd.markForCheck();
  }

  close(): void {
    this.isOpened = false;
    this.onCloseStart.emit();
    this.normalizeSize();

    if (this.rootElement) 
      this.rootElement.nativeElement.addEventListener('transitionend', this.funcTransitionEnd);
      // No root element to transition -> emit onClosed directly.
      // Transition end event not triggered for sidebars. This is why this event is active for all popups.
    
    this.onClosed.emit();
    this.popupService.closed(this.id);

    this.cd.markForCheck();
  }

  toggle(): void {
    // If the previous state was opened then execute close ...
    if (this.isOpened)
      this.close();
    else this.open();
  }

  toggleTo(targetValue): void {
    // Do nothing...
    if (this.isOpened == targetValue)
      return;

    if (targetValue)
      this.open();
    else this.close();
  }

  enable(status): void {

  }

  move(info: PopupInfo<any>): void {

  }

  cancelButtonClicked($event): void {
    this.close();

    this.cancelClick.emit($event);
    // Stop default propagation.
    $event.preventDefault();
  }

  okButtonClicked($event): void {

    this.okClick.emit($event);

    if (this.closeOnOk)
      this.close();

    // Stop default propagation.
    $event.preventDefault();
  }

  ngOnDestroy(): void {
    if (this.messageSubscription)
      this.messageSubscription.unsubscribe();
  }

  onTransitionEnd(e: TransitionEvent): void {
    if (!this.rootElement)
      return;

    if (e.target === this.rootElement.nativeElement && this.rootElement.nativeElement.classList.contains('side-bar')) {
      this.rootElement.nativeElement.removeEventListener('transitionend', this.funcTransitionEnd);
    }

    if (this.isOpened) this.onOpened.emit();
    else this.onClosed.emit();

    this.cd.markForCheck();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.isOpened)
      this.calculateMaximizeState();
    this.normalizeSize();
  }
}