<div class="dataselector-table decoration dataselector-decoration" [style.width]="width" [style.max-width.%]="'100'">
  <div>
    <gridview>
      <gridview-header *ngIf="!(decoration?.title | isNullOrWhiteSpace)" classes="decoration-header">
        <header-columntemplate classes="header-image" size="auto">
          <co-image width="24" height="24" type="svg" key="bom" [imageSet]="imageSet"></co-image>
        </header-columntemplate>
        <header-columntemplate classes="header-title">
          <div class="container-fluid">
            <div class="row">
              <span class="col dataselector-title">{{decoration.title}}</span>
              <filter *ngIf="filterVisible"
                      class="col-auto"
                      [filterItems]="filterItems"
                      [isInline]="true"
                      [searchInfo]="this.searchInfo"
                      (onFilter)="onFilter($event)"
                      (onCancel)="onCancelFilter()">
              </filter>
            </div>

          </div>
        </header-columntemplate>
        <header-columntemplate *ngIf="isFiltered" classes="filter-icon header-image" size="auto" role="button" (click)="clearFilter()">
          <co-image class="clickable" width="20px" height="20px" type="svg" key="filter-clear" [imageSet]="imageSet"></co-image>
        </header-columntemplate>
        <header-columntemplate *ngIf="!filterVisible" classes="filter-icon header-image" size="auto" role="button" (click)="toggleShowFilter()">
          <co-image width="20px" height="20px" type="svg" key="filter" [imageSet]="imageSet"></co-image>
        </header-columntemplate>
        <header-columntemplate *ngIf="hasValue" classes="filter-icon header-image" size="auto" role="button" (click)="clearValue()" title="{{strings.Clear_Selection}}">
          <co-image width="20px" height="20px" type="svg" key="delete" [imageSet]="imageSet"></co-image>
        </header-columntemplate>
      </gridview-header>

    </gridview>
    <popover *ngIf="!filterVisible && filterItems.length > 0" [isOpened]="showFilter" class="d-flex justify-content-end">
      <filter [filterItems]="filterItems"
              [searchInfo]="this.searchInfo"
              (onFilter)="onFilter($event)"
              (onCancel)="onCancelFilter()">
      </filter>
    </popover>
  </div>

  <div class="table-wrapper">
    <table class="useBorder w-100">

      <thead>
        <tr>
          <th *ngFor="let column of columns"
              title="{{column.contents}}"
              [ngStyle]="column.safeStyle"
              [class]="column.classes"
              id="column.longId"
              (click)="columnClick(column)">
            <div class="d-flex align-middle justify-content-center">
              <span>
                {{column.contents}}
              </span>
            </div>
          </th>
        </tr>
      </thead>

      <!-- Different template depending on hyperlink or not, hyperlink should not have onValueClick -->
      <tbody>
        <tr *ngFor="let row of rows; trackBy: trackRow"
            [class]="row.classes">
          <!-- NOTE! Selected is not needed here as createTable will be called after we a value has been set. -->
          <ng-container *ngFor="let cell of row.cells">
            <ng-container *ngIf="cell.href || cell.image; else contents">
              <td [class]="cell.classes" title="{{cell.href}}" [ngStyle]="cell.safeStyle">
                <a *ngIf="cell.href" href="{{cell.image ? cell.contents : cell.href}}" class="d-block" target="_blank">
                  <co-image *ngIf="cell.image; else noImage" type="" [isVault]="cell.contents | isVaultImage" [customUrl]="cell.contents"></co-image>
                  <ng-template #noImage>
                    <co-image *ngIf="!cell.image" width="24px" height="24px" type="svg" key="link" [imageSet]="imageSet"></co-image>
                  </ng-template>
                </a>
                <co-image *ngIf="cell.image && !cell.href" type="" [isVault]="cell.contents | isVaultImage" [customUrl]="cell.contents" (click)="onValueClick(row)" [imageSet]="imageSet"></co-image>
              </td>

            </ng-container>
            <ng-template #contents>
              <td [class]="cell.classes" title="{{cell.contents}}" [ngStyle]="cell.safeStyle" (click)="onValueClick(row)">
                {{cell.contents}}
              </td>
            </ng-template>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="isDataReady" card-type="footer">
    <pager [pagerModel]="pagerModel"
            [initialWidth]="width"
            (pagerUpdated)="refreshPage()"
            [refresh]="refreshPager"></pager>
  </div>
</div>