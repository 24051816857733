import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ChangeDetectionStrategy } from "@angular/core";
import { Tab, RequestViews, Conf } from "../../../shared/models";
import { ConfiguratorUIStore, ConfPageSessionService, ConfiguratorStore } from "../../providers";
import { TabAvailabilityService } from "./tabAvailabilityService";
import { TabContainerComponent } from "./tabContainerComponent";

let TabPosition = {
  Left: 'left',
  Right: 'right',
  Top: 'top',
  Bottom: 'bottom'
}

@Component({
  selector: 'separated-tab',
  templateUrl: './separatedTabComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeparatedTabComponent extends TabContainerComponent {

  @Input() public position: string = TabPosition.Right;
  @Input() public tab: Tab;
  @Input() public minHeight: string;
  @Input() public maxWidth: string;
  @Input() public scrollbar: boolean = false;
  @Input() public configurationId: number;

  @Input()
  public showClose: boolean = false;

  public extraStyles: string = "";
  public isIE: boolean = false;

  @Output()
  public onClose = new EventEmitter();

  constructor(
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
    public tabAvailableService: TabAvailabilityService,
    public cd: ChangeDetectorRef) {
    super(confUIStore, cd, tabAvailableService);
  }

  ngOnInit(): void {

    if ((<any>this.tab).configurationId)
      this.configurationId = (<any>this.tab).configurationId;

    super.ngOnInit();

    this.confUIStore.onActiveConfChange(this.confPageSession.pageId, (confId: number) => {

      if (!(<any>this.tab).configurationId)
        this.configurationId = confId;

    }).unsubscribeOn(this.unsubscribeSubject);

    this.isIE = this.browserInfo.isIE;
    if (this.isIE)
      this.extraStyles = 'tab-body-ie';

  }

  public ready(): void {

    let conf: Conf = this.confStore.getConf(this.configurationId, this.confPageSession.confSessionId);
    if (conf) {
      this.domReady = true;
      this.cd.detectChanges();
    }
    else {
      this.confStore.loadConf(this.configurationId, this.confPageSession.confSessionId, RequestViews.Editor, false).then(response => {

        this.domReady = true;
        this.cd.detectChanges();

      });
    }
  }

  get pageId(): number {
    return this.confPageSession.pageId;
  }

  public activateTab(tab: Tab) {

    // Do nothing

  }
}