<picture *ngIf="key || customUrl">
  <source *ngFor="let src of srcSet" type="image/{{type}}" srcset="{{src}}" />
  <img [style.maxWidth]="width | imageDimension"
       [style.width]="keepSameWidthIfImageNotFound && !imageFound ? width : null"
       [class.d-flex]="keepSameWidthIfImageNotFound && !imageFound"
       [class]="cssClass != null ? cssClass : '' "
       [style.maxHeight]="height | imageDimension"
       src="{{defaultSrc}}"
       (error)="onError($event)"
       (load)="triggerEvent($event)" alt="" #image />
</picture>