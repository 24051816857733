import { Injectable, Inject } from "@angular/core";
import * as Immutable from 'immutable';
import { BaseEntity } from "../../shared/baseEntity";
import { AppStore, AppAction, HttpAction, StoreResponse } from "../../shared/state";
import { ConfDataActionCreator } from "../../shared/providers/configurationData/confDataActionCreator";
import { ConfDataState, ConfSessionData, ActionInfo, RequestStatus } from "../../shared/state";
import { AppStoreSubscriptionManager } from "../../shared/providers/appStoreSubscriptionManager";
import { ConfDataMemorizer } from "./confDataMemorizer";
import { ManagedSubject } from "../../../shared/managedSubject";
import { BaseStore } from "../../shared/state/baseStore";
import {
  ConfDataResponse, ConfInfo, Conf, ConfPropertyValue, ConfGraphicsValue,
  ConfDocumentValue, ConfValue, ConfBoolValue, ConfDoubleValue, ConfIntValue,
  ConfLookupValue, ConfMultiChoiceValue, ConfStringValue, ConfSaveMessage, RequestViews, OrderCommand, ConfResponseFormat, CreateCommand, ConfDeleteCommand, CopyCommand, ConfSessionCommand, GetCommand, UpdateCommand, PriceCommand, ReadOnlyParamCommand, MandatoryParamsCommand, StoredConfResponseFormat, WebSettings, ChangeOwnershipCommand, ApiResponse, ConfAttributeValue, ConfReport, CodeCommand, KeyValue, ImportCommand, ConfCodeValue, FormCommand, ConfUIItem, LookupParam, ContactCommand, UIElement, JavaScriptCommand, EventType
} from "../../shared/models";

import { ManagedSubscription, SubscriptionOptions } from "../../../shared/managedSubscription";
import { BaseObject } from "../../../shared/baseObject";
import { ConfMessageProvider } from "./confMessageProvider";
import { ConfDataRequest } from "../../shared/models/requests/confDataRequest";
import { ModelFactory } from "../../shared/providers/modelFactory";
import { ConfValueArgument, ConfPropertyValueArgument } from "../../shared/models/requests/arguments";
import { GlobalDataStore } from "../../shared/providers/globalData";
import { ConfRouteParams } from "./confRouteParams";
import { ConfiguratorUIStore } from "./configuratorUIStore";
import { RouteRedirector } from "../../shared/providers";
import { ElementStore } from "../../shared/providers/elementStore";
import { PushMessageSelection } from "../../shared/providers/pushMessage/pushMessageSelection";

@Injectable()
export class ConfiguratorStore extends BaseStore implements ElementStore {

  constructor(
    @Inject(AppStore) public appStore: AppStore,
    @Inject(ConfDataActionCreator) public confActionCreator: ConfDataActionCreator,
    @Inject(AppStoreSubscriptionManager) public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ConfDataMemorizer) public memorizer: ConfDataMemorizer,
    @Inject(ConfMessageProvider) public confMessageProvider: ConfMessageProvider,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(RouteRedirector) public routeRredirector: RouteRedirector,    
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory, confActionCreator);
  }

  public createRequest(client?: RequestViews, rawObject?: Partial<ConfDataRequest>): ConfDataRequest {

    let request = this.modelFactory.createRequestOrCommand<ConfDataRequest>(ConfDataRequest.name, rawObject);
    request.client = client;

    request.includeNextStateMandatoryParams = this.confUIStore.includeMandatoryInfo;

    if (this.confUIStore.confParams) {

      if (this.confUIStore.confParams.id)
        request.activeConfigurationIds.push(+this.confUIStore.confParams.id);

      if (this.confUIStore.confParams.subConfId)
        request.activeConfigurationIds.push(+this.confUIStore.confParams.subConfId);
    }

    if (this.confUIStore.includeMandatoryInfo) {

      request.mandatoryParam = this.modelFactory.createRequestOrCommand<MandatoryParamsCommand>(MandatoryParamsCommand.name, {});
      request.mandatoryParam.includeNextState = this.confUIStore.isNextStateIncluded;
      request.mandatoryParam.configurationId = this.confUIStore.confParams.id;

    }

    return request;
  }

  public getConfData() {
    return this.appStore.getState().configurationData;
  }

  public getEditorResponseFormat(): ConfResponseFormat {
    return this.globalDataStore.getConfResponseFormat(RequestViews.Editor);
  }

  public listenRequestCompletion(requestId: number): ManagedSubject<ConfDataResponse> {
    return this.appStoreSubscriptionManager.createSubject<ConfDataResponse>((appStore) => {

      let actionInfo: ActionInfo = appStore.getActionInfo(requestId);
      if (actionInfo.status == RequestStatus.SUCCESS) {
        if (actionInfo.payload && actionInfo.payload.data instanceof ConfDataResponse) {
          return actionInfo.payload.data;
        }
      }
      return ManagedSubject.IGNORE_VALUE;
    }, true);
  }

  /**
  * Creates a new child configuration.
  */
  createChildConfiguration(configurationId: number, childProductId: number, sessionId: number, parentTabId?: number): Promise<ConfDataResponse> {

    let model = this.createRequest(RequestViews.Editor, { confSessionId: sessionId });
    model.create = this.modelFactory.createRequestOrCommand<CreateCommand>(CreateCommand.name, {
      productId: childProductId, configurationId: configurationId,
      parentTabId: parentTabId
    });
    model.create.responseFormat = this.getEditorResponseFormat();
    
    let requestId: number = this.confActionCreator.dispatchCreateChildConfiguration(model).id;
    return this.listenRequestCompletion(requestId).toPromise();
  }


  /**
    * CRM Customization
    * Creates a new child configuration.
  */
  createChildConfigurationCorrespondingToCrmQuoteDetail(configurationId: number, childProductId: number, sessionId: number, quoteDetailCrmId: string): Promise<ConfDataResponse> {

    let model = this.createRequest(RequestViews.Editor, { confSessionId: sessionId });

    // customized here to add quoteDetailCrmId as extra args
    let extraArgs = Immutable.Map<string, string>();
    extraArgs = extraArgs.set('quoteDetailCrmId', quoteDetailCrmId);

    model.create = this.modelFactory.createRequestOrCommand<CreateCommand>(CreateCommand.name, {
      productId: childProductId, configurationId: configurationId, extraArgs: extraArgs
    });
    model.create.responseFormat = this.getEditorResponseFormat();

    let requestId: number = this.confActionCreator.dispatchCreateChildConfiguration(model).id;
    return this.listenRequestCompletion(requestId).toPromise();
  }


  getImportIdentitiesHint(keywords: string, productId: number, confSessionId: number): Promise<ConfDataResponse> {

    let model = this.createRequest(RequestViews.Editor, { confSessionId: confSessionId});
    model.import = this.modelFactory.createRequestOrCommand<ImportCommand>(ImportCommand.name, {

      getSuggestionsKeywords: keywords,
      getSuggestionsProductId: productId,
      getSuggestionsAutoComplete: true

    });

    model.import.responseFormat = this.getEditorResponseFormat();
    let requestId: number = this.confActionCreator.dispatchImportIdentitiesHints(model).id;
    return this.listenRequestCompletion(requestId).toPromise();

  }

  importConfiguration(activeConfigurationId: number, sourceConfId: number, identity: string, revision: string, sessionId: number): Promise<ConfDataResponse> {

    let model = this.createRequest(RequestViews.Editor, { confSessionId: sessionId });
    model.import = this.modelFactory.createRequestOrCommand<ImportCommand>(ImportCommand.name, {

      sourceConfId: sourceConfId,
      configurationId: activeConfigurationId,
      sourceConfIdentity: identity,
      sourceConfRevision: revision

    });

    model.import.responseFormat = this.getEditorResponseFormat();
    let requestId: number = this.confActionCreator.dispatchCreateChildConfiguration(model).id;
    return this.listenRequestCompletion(requestId).toPromise();
  }
    
  formControlSubmit(activeConfigurationId: number, confSessionId: number, formDecorationId: number, formControlId: number, valuesById: Immutable.Map<string, string>, view: RequestViews) {

    let model = this.createRequest(view, { confSessionId: confSessionId });
    model.form = this.modelFactory.createRequestOrCommand<FormCommand>(FormCommand.name, {

      configurationId: activeConfigurationId,
      formDecorationId: formDecorationId,
      formControlId: formControlId,
      valuesById: valuesById

    });

    model.form.responseFormat = this.getEditorResponseFormat();
    this.confActionCreator.dispatchCreateDynamicIndexes(model).id;
  }

  javaScriptAction(activeConfigurationId: number, confSessionId: number, eventType: EventType, sourceId: number, json: string, view: RequestViews): Promise<ConfDataResponse> {

    let model = this.createRequest(view, { confSessionId: confSessionId });
    model.javaScript = this.modelFactory.createRequestOrCommand<JavaScriptCommand>(JavaScriptCommand.name, {
      configurationId: activeConfigurationId,
      eventType: eventType,
      sourceId: sourceId,
      json: json
    });

    model.javaScript.responseFormat = this.getEditorResponseFormat();

    let requestId: number = this.confActionCreator.dispatchJavaScriptAction(model).id;
    return this.listenRequestCompletion(requestId).toPromise();    
  }

  moveUpConfiguration(configurationId: number, sessionId: number): Promise<ConfDataResponse> {
    let model = this.createRequest(RequestViews.Editor, { confSessionId: sessionId });
    model.order = this.modelFactory.createRequestOrCommand<OrderCommand>(OrderCommand.name, { configurationId: configurationId, moveDown: false });
    model.order.responseFormat = this.getEditorResponseFormat();

    let requestId: number = this.confActionCreator.dispatchChangeConfigurationsOrder(model).id;
    return this.listenRequestCompletion(requestId).toPromise();
  }

  moveDownConfiguration(configurationId: number, sessionId: number) {
    let model = this.createRequest(RequestViews.Editor, { confSessionId: sessionId });
    model.order = this.modelFactory.createRequestOrCommand<OrderCommand>(OrderCommand.name, { configurationId: configurationId, moveDown: true });
    model.order.responseFormat = this.getEditorResponseFormat();

    let requestId: number = this.confActionCreator.dispatchChangeConfigurationsOrder(model).id;
    return this.listenRequestCompletion(requestId).toPromise();
  }

  deleteConfiguration(configurationId: number, sessionId: number, requestView: RequestViews): Promise<ConfDataResponse> {
    let model = this.createRequest(RequestViews.Editor, { confSessionId: sessionId });
    model.client = requestView;
    model.delete = this.modelFactory.createRequestOrCommand<ConfDeleteCommand>(ConfDeleteCommand.name, { configurationId: configurationId });
    model.delete.responseFormat = this.getEditorResponseFormat();

    let requestId: number = this.confActionCreator.dispatchDeleteConfiguration(model).id;
    return this.listenRequestCompletion(requestId).toPromise();
  }

  contact(configurationId: number, sessionId: number, fields: KeyValue<string, string>[]) {
    let model = this.createRequest(RequestViews.Summary, { confSessionId: sessionId });
    model.contact = this.modelFactory.createRequestOrCommand<ContactCommand>(ContactCommand.name, { configurationId: configurationId, fields: fields });
    this.confActionCreator.dispatchContact(model).id;
  }

  changeOwnership(configurationId: number, sessionId: number, workGroupId: number, applyToAllRevisions: boolean): Promise<ConfDataResponse> {
    let model = this.createRequest(RequestViews.Summary, { confSessionId: sessionId });
    model.changeOwnership = this.modelFactory.createRequestOrCommand<ChangeOwnershipCommand>(ChangeOwnershipCommand.name, { configurationId: configurationId });
    model.changeOwnership.applyToAllRevisions = applyToAllRevisions;
    model.changeOwnership.toWorkGroupId = workGroupId;
    model.changeOwnership.responseFormat = this.globalDataStore.getConfResponseFormat(RequestViews.Summary);

    let requestId = this.confActionCreator.dispatchChangeOwnership(model).id;   
    return this.listenRequestCompletion(requestId).toPromise();
  }

  requestCopyDestinations(configurationId: number, sessionId: number) {
    let model = this.createRequest(RequestViews.Editor, { confSessionId: sessionId });
    model.copy = this.modelFactory.createRequestOrCommand<CopyCommand>(CopyCommand.name, { configurationId: configurationId, getDestinationList: true });
    model.copy.responseFormat = this.getEditorResponseFormat();

    this.confActionCreator.dispatchRequestCopyDestinations(model);
  }

  copyConfiguration(configurationId: number, destinationId: number, noOfCopies: number, sessionId: number) {
    let model = this.createRequest(RequestViews.Editor, { confSessionId: sessionId });
    model.copy = this.modelFactory.createRequestOrCommand<CopyCommand>(CopyCommand.name, { configurationId: configurationId, destinationId: destinationId, noOfCopies: noOfCopies });
    model.copy.responseFormat = this.getEditorResponseFormat();

    this.confActionCreator.dispatchCopyConfiguration(model);
  }

  saveConfiguration(configurationId: number, confSessionId: number, exitEditing: boolean, anonymousUserEmail?: string, sendEmail?: boolean, updateEmail?: boolean): void {
    let model = this.createEmailModel(configurationId, confSessionId, anonymousUserEmail, sendEmail, updateEmail, RequestViews.Editor);
    model.session.save = true;
    model.session.exitEditing = exitEditing;
    model.mandatoryParam = null;

    this.confActionCreator.dispatchSaveConfiguration(model);
  }

  closeConfSession(configurationId: number, confSessionId: number) {

    // If ConfiguratorSession is already closed then skip sending request
    if (this.isConfiguratorSessionClosed(confSessionId))
      return;

    let model = this.createRequest(RequestViews.Editor, { confSessionId: confSessionId });
    model.session = this.modelFactory.createRequestOrCommand<ConfSessionCommand>(ConfSessionCommand.name, { configurationId: configurationId, close: true });
    model.session.responseFormat = this.getEditorResponseFormat();

    this.confActionCreator.dispatchCloseConfSession(model);
  }

  isConfiguratorSessionClosed(confSessionId: number) {
    return this.getConfSessionData(confSessionId).isClosed;    
  }

  markConfSessionForClosing(configurationId: number, sessionId: number) {
    let model = this.createRequest(RequestViews.Editor, { confSessionId: sessionId });
    model.session = this.modelFactory.createRequestOrCommand<ConfSessionCommand>(ConfSessionCommand.name, { configurationId: configurationId, markForClosing: true });
    model.session.responseFormat = this.getEditorResponseFormat();

    this.confActionCreator.dispatchMarkConfSessionForClosing(model);
  }

  emailConfiguration(configurationId: number, confSessionId: number, anonymousUserEmail: string, sendEmail: boolean): void {
    let model = this.createEmailModel(configurationId, confSessionId, anonymousUserEmail, sendEmail, false, RequestViews.Summary);

    this.confActionCreator.dispatchSaveConfiguration(model);
  }

  protected createEmailModel(configurationId: number, confSessionId: number, anonymousUserEmail: string, sendEmail: boolean, updateEmail: boolean, view: RequestViews): ConfDataRequest {
    let model = this.createRequest(view, { confSessionId: confSessionId });
    model.session = this.modelFactory.createRequestOrCommand<ConfSessionCommand>(ConfSessionCommand.name, { configurationId: configurationId });
    model.session.responseFormat = this.getEditorResponseFormat();

    if (sendEmail) {
      model.session.email = anonymousUserEmail;
    }

    if (updateEmail) {
      model.update = this.modelFactory.createRequestOrCommand<UpdateCommand>(UpdateCommand.name, { configurationId: configurationId });
      model.update.confPropertyValue = this.modelFactory.createAny<ConfPropertyValueArgument>(ConfPropertyValueArgument.name, {
        propertyName: "AnonymousUserEmail",
        keyAndValue: this.modelFactory.createAny<KeyValue<string, string>>(KeyValue.name, { key: anonymousUserEmail })
      }); 
      model.update.responseFormat = this.getEditorResponseFormat();
    }

    return model;
  }

  public entity<T extends BaseEntity>(sessionId: number, confId: number, longId: number): T {
    let confDataState: ConfDataState = this.appStore.getState().configurationData;

    if (confDataState.dataBySessionId.get(sessionId.toString()).entitiesByConfId.get(+confId))
      return confDataState.dataBySessionId.get(sessionId.toString()).entitiesByConfId.get(+confId).get(+longId) as T;
    else
      return null;
  }

  /**
   * Creates the new configuration session.
   * @param productId
   */
  createConfiguratorSession(params: ConfRouteParams): Promise<ConfDataResponse> {
    // Async request, but request Id must be returned.    
    let model = this.createRequest(RequestViews.Editor);

    model.create = this.modelFactory.createRequestOrCommand<CreateCommand>(CreateCommand.name, { productId: params.productId, confParams: Immutable.Map(params.confParams), extraArgs: Immutable.Map(params.extraArgs) });
    model.create.responseFormat = this.getEditorResponseFormat();

    let requestId: number = this.confActionCreator.dispatchCreateConfiguration(model).id;

    return this.listenRequestCompletion(requestId).toPromise();
  }

  confSessionData(confSessionId: number): ConfSessionData {
    let confDataState: ConfDataState = this.appStore.getState().configurationData;
    return confDataState.dataBySessionId.get(confSessionId.toString());
  }

  public hasUnsavedChanges(confSessionId: number): boolean {
    let confDataState: ConfDataState = this.appStore.getState().configurationData;
    let dataBySession = confDataState.dataBySessionId.get(confSessionId.toString());
    return dataBySession && dataBySession.hasUnsavedChanges;
  }

  public getConfInfo(confId: number, confSessionId: number): ConfInfo {
    return this.confSessionData(confSessionId).compositeStructure.get(confId);
  }

  public getElement(elementId: number, sessionId: number): UIElement {
    return this.confSessionData(sessionId).uiElements.get(elementId);
  }

  public getElementValue(id: string, sessionId: number) {
    let valuesBySessionId = this.getElementValuesById(sessionId);

    if (valuesBySessionId) {
      let value = valuesBySessionId.get(id);

      // If value was found return it.
      if (value) {
        return value;
      }
    }

    return null;
  }

  onElementChange(sessionId: number, elementId: number, callback: (uiElement: UIElement) => void, currentValueFunc?: () => any): ManagedSubscription {

    let memorizer: () => UIElement = this.memorizer.getElementMemorizer(sessionId, elementId);
    let identifier = `onConfElementChange_${sessionId}_${elementId}`;

    if (currentValueFunc)
      identifier += "_match_current_value";

    return this.createStoreSubscription<UIElement>(identifier, memorizer, callback);
  }

  onElementValueChange(sessionId: number, elementId: number, callback: (value: string) => void, currentValueFunc?: () => any): ManagedSubscription {
    let memorizer: () => string = this.memorizer.getElementValueMemorizer(sessionId, elementId, currentValueFunc);

    let identifier = `onConfElementValueChange_${sessionId}_${elementId}`;

    if (currentValueFunc)
      identifier += "_match_current_value";

    return this.createStoreSubscription<string>(identifier, memorizer, callback);
  }

  public getElements(sessionId: number): Immutable.Map<number, UIElement> {
    return this.confSessionData(sessionId).uiElements;
  }

  public getElementValuesById(confSessionId: number): Immutable.Map<string, string> {
    return this.confSessionData(confSessionId).valueByElementId;
  }

  public setElementValue(pushMessageSelection: PushMessageSelection) {
    this.confActionCreator.dispatchValueChanged(pushMessageSelection);
  }

  public getChildConfInfos(parentId: number, confSessionId: number): ConfInfo[] {

    if (!parentId)
      return [];

    let infos: ConfInfo[] = [];

    // Get parent ConfInfo to see its children.
    let compositeStructure = this.confSessionData(confSessionId).compositeStructure;
    let parentConfInfo: ConfInfo = compositeStructure.get(parentId);

    if (!parentConfInfo)
      return [];

    // Add all children infos.
    parentConfInfo.children.forEach(resultId => infos.push(compositeStructure.get(resultId)));

    return infos;
  }

  onCompositeStructureChange(confSessionId: number, subscriptionOptions: SubscriptionOptions<string>, oneTime: boolean = false): ManagedSubscription {
    let memorizer: () => string = this.memorizer.getCompositeStructureMemorizer(confSessionId);
    return this.createStoreSubscription<string>(`onCompositeStructureChange_${confSessionId}`, memorizer, subscriptionOptions, oneTime);
  }

  onCompositeOrderChange(confSessionId: number, callback: () => void): ManagedSubscription {
    let memorizer: () => string = this.memorizer.getCompositeOrderChangeMemorizer(confSessionId);
    return this.createStoreSubscription<string>(`onCompositeOrderChange_${confSessionId}`, memorizer, callback);
  }

  onConfInfoChange(configurationId: number, confSessionId: number, callback: (conf: ConfInfo) => void): ManagedSubscription {
    let memorizer: () => ConfInfo = this.memorizer.getConfInfoMemorizer(confSessionId, configurationId);
    return this.createStoreSubscription<ConfInfo>(`onConfInfoChange_${confSessionId}_${configurationId}`, memorizer, callback);
  }

  public createStoreSubscription<T>(identifier: string, memorizer: () => T, subscriptionOptions: SubscriptionOptions<T>, oneTime: boolean = false): ManagedSubscription {

    let managedSubject: ManagedSubject<T> = this.appStoreSubscriptionManager.getOrCreateStoreSubject(identifier, memorizer, oneTime, 1, true);
    return managedSubject.subscribe(subscriptionOptions);
  }

  /**
  * Reuses the configuration.
  * @param configurationId
  */
  reuseConfiguration(params: ConfRouteParams): Promise<ConfDataResponse> {
    let model = this.createRequest(RequestViews.Editor);
    model.get = this.modelFactory.createRequestOrCommand<GetCommand>(GetCommand.name, { reuse: true, configurationId: params.reuse, extraArgs: Immutable.Map(params.extraArgs) });
    model.get.responseFormat = this.getEditorResponseFormat();

    // Async request, but request Id must be returned.
    let requestId: number = this.confActionCreator.dispatchReuseConfiguration(model).id;

    return this.listenRequestCompletion(requestId).toPromise();
  }

  /**
   * Revises the configuration.
   * @param configurationId
   */
  reviseConfiguration(params: ConfRouteParams): Promise<ConfDataResponse> {
    let model = this.createRequest(RequestViews.Editor);
    model.get = this.modelFactory.createRequestOrCommand<GetCommand>(GetCommand.name, { revise: true, configurationId: params.revise, extraArgs: Immutable.Map(params.extraArgs) });
    model.get.responseFormat = this.getEditorResponseFormat();

    // Async request, but request Id must be returned.    
    let requestId: number = this.confActionCreator.dispatchReviseConfiguration(model).id;

    return this.listenRequestCompletion(requestId).toPromise();
  }

  /**
   * Triggers on configuration change.
   * @param configurationId
   * @param callback
   */
  onConfigurationChange(configurationId: number, confSessionId: number, callback: (conf: Conf) => void): ManagedSubscription {
    let memorizer: () => Conf = this.memorizer.getConfMemorizer(confSessionId, configurationId);

    return this.createStoreSubscription<Conf>(`onConfigurationChange_${confSessionId}_${configurationId}`, memorizer, callback);
  }

  /**
   * Triggers on configurations change.   
   * @param callback
   */
  onConfigurationsChange(confSessionId: number, callback: (confs: Array<Conf>) => void): ManagedSubscription {
    let memorizer: () => Conf[] = this.memorizer.getConfsMemorizer(confSessionId);

    return this.createStoreSubscription<Conf[]>(`onConfigurationsChange_${confSessionId}`, memorizer, callback);
  }

  onConfReportsChange(configurationId: number, confSessionId: number, callback: (reports: Immutable.List<ConfReport>) => void): ManagedSubscription {

    return this.createStoreSubscription<Immutable.List<ConfReport>>(`onConfigurationReportsChange_${confSessionId}_${configurationId}`, this.memorizer.getConfReportsMemorizer(confSessionId, configurationId), callback);

  }

  /**
   * Triggers on configuration value change.
   * @param configurationId
   * @param confValueId
   * @param callback
   */
  onConfigurationValueChange<T extends BaseEntity>(configurationId: number, confSessionId: number, confValueId: number, callback: (confValue: T) => void, currentValueFunc?: () => any): ManagedSubscription {
    let identifier = `${confSessionId}_${configurationId}_${confValueId}`;

    if (currentValueFunc)
      identifier += "_match_current_value";

    return this.createStoreSubscription<T>(identifier, this.memorizer.getConfValueMemorizer<T>(confSessionId, configurationId, confValueId, currentValueFunc), callback);
  }

  /**
   * Triggers on configuration value change.
   * @param configurationId
   * @param confValueId
   * @param callback
   */
  onLookupConfValueVisibilityChange(configurationId: number, confSessionId: number, loopupParamId: number, callback: (uiItems: string) => void): ManagedSubscription {
    let identifier = `${confSessionId}_${configurationId}_${loopupParamId}_visibility`;

    let memorizer = this.memorizer.getLookupConfValueMemorizer(confSessionId, configurationId, loopupParamId);
    return this.createStoreSubscription<string>(identifier, memorizer, callback);
  }  

  /**
   * Triggers when any configuration values is changed
   * @param configurationId
   * @param confSessionId
   * @param callback   
   */
  onConfigurationValuesChange(configurationId: number, confSessionId: number, callback: (confValues: Immutable.List<ConfValue>) => void): ManagedSubscription {
    let identifier = `${confSessionId}_${configurationId}_confValues`;

    return this.createStoreSubscription<Immutable.List<ConfValue>>(identifier, this.memorizer.getConfValuesMemorizer(confSessionId, configurationId), callback);
  }

  /**
   * Triggers when any configuration attribute values is changed
   * @param configurationId
   * @param confSessionId
   * @param callback   
   */
  onConfigurationAttributeValuesChange(configurationId: number, confSessionId: number, callback: (confValues: Immutable.List<ConfAttributeValue>) => void): ManagedSubscription {
    let identifier = `${confSessionId}_${configurationId}_attributeValues`;
        
    return this.createStoreSubscription<Immutable.List<ConfAttributeValue>>(identifier, this.memorizer.getAttributeValuesMemorizer(confSessionId, configurationId), callback);
  }

  onConfPropertyValuesChange(configurationId: number, confSessionId: number, callback: (propValues: Immutable.List<ConfPropertyValue>) => void): ManagedSubscription {
    let identifier = `${confSessionId}_${configurationId}_configurationProperties`;

    return this.createStoreSubscription<Immutable.List<ConfPropertyValue>>(identifier, this.memorizer.getConfPropertyMemorizer(confSessionId, configurationId), callback);
  }

  onConfDecorationValueChange<T extends BaseEntity>(configurationId: number, confSessionId: number, entityId: number, callback: (decorationItemValue: T) => void): ManagedSubscription {
    return this.onConfigurationValueChange(configurationId, confSessionId, entityId, callback);
  }

  onConfDecorationValuesChange(configurationId: number, confSessionId: number, decorationId: number, entryIds: Array<number>, callback: (decorationItemValue: Map<number, BaseObject>) => void): ManagedSubscription {
    //TODO can cause problem
    let identifier = `${confSessionId}_${configurationId}_${decorationId}_values`;
    return this.createStoreSubscription<Map<number, BaseObject>>(identifier, this.memorizer.getDecorationItemsMemorizer(confSessionId, configurationId, entryIds), callback);
  }

  onConfGraphicValueChange(configurationId: number, confSessionId: number, graphicId: number, callback: (graphicValue: string) => void): ManagedSubscription {
    let identifier = `${confSessionId}_${configurationId}_${graphicId}`;
    return this.createStoreSubscription<string>(identifier, this.memorizer.getConfGraphicsValueMemorizer(confSessionId, configurationId, graphicId), callback);
  }

  onConfDocumentValuesChange(configurationId: number, confSessionId: number, decorationId: number, callback: (documentValueList: Immutable.List<ConfDocumentValue>) => void): ManagedSubscription {
    let identifier = `${confSessionId}_${configurationId}_${decorationId}`;
    return this.createStoreSubscription<Immutable.List<ConfDocumentValue>>(identifier, this.memorizer.getDocumentValueMemorizer(confSessionId, configurationId), callback);
  }

  onConfCodeValuesChange(configurationId: number, confSessionId: number, callback: (codeValueList: Immutable.List<string>) => void): ManagedSubscription {
    let identifier = `${confSessionId}_${configurationId}`;
    return this.createStoreSubscription<Immutable.List<string>>(identifier, this.memorizer.getCodeValuesMemorizer(confSessionId, configurationId), callback);
  }

  public getConf(confId: number, confSessionId: number): Conf {
    return this.getConfDataEntity<Conf>(confId, confSessionId, confId);
  }

  public loadConf(confId: number, confSessionId: number, clientType: RequestViews, isMandatoryOpened: boolean = false): Promise<ConfDataResponse> {
    
    let request = this.createRequest(clientType, { confSessionId: confSessionId });    
    request.get = this.modelFactory.createRequestOrCommand<GetCommand>(GetCommand.name, { configurationId: +confId });      
    request.get.responseFormat = this.globalDataStore.getConfResponseFormat(clientType, false);

    if (this.confUIStore.includeMandatoryInfo) {

      request.mandatoryParam = this.modelFactory.createRequestOrCommand<MandatoryParamsCommand>(MandatoryParamsCommand.name, {});
      request.mandatoryParam.configurationId = confId;
      request.mandatoryParam.includeNextState = this.confUIStore.isNextStateIncluded;

    }

    // Async request, but request Id must be returned.
    let requestId: number = this.confActionCreator.dispatchGetConfiguration(request).id;

    return this.listenRequestCompletion(requestId).toPromise();

  }

  public getConfValue(confId: number, confSessionId: number, entityId: number): BaseEntity {
    return this.getConfDataEntity<BaseEntity>(confId, confSessionId, entityId);
  }

  public getConfValues<T extends BaseEntity>(confId: number, confSessionId: number, itemIds: Array<number>): Map<number, T> {
    let valById: Map<number, T> = new Map<number, T>();
    for (let id of itemIds)
      valById.set(id, this.getConfValue(confId, confSessionId, id) as T);

    return valById;
  }

  public getConfDataEntity<T extends BaseEntity>(confId: number, confSessionId: number, longId: number): T {
    return this.entity<T>(confSessionId, confId, longId);
  }

  getConfPropertyValue(confId: number, confSessionId: number, propertyName: string): ConfPropertyValue {
    return this.getConf(confId, confSessionId).confPropertyValues.find((value) => value.propertyName == propertyName);
  }

  public setConfValue(confValue: ConfValue, confSessionId: number, value: any, includeMandatoryInfo: boolean, includeNextState: boolean, paramValueId?: number): void {

    let configurationId = confValue.configurationId;
    let confValueLongId = confValue.longId;

    let model = this.createRequest(RequestViews.Editor, { confSessionId: confSessionId });
    model.update = this.modelFactory.createRequestOrCommand<UpdateCommand>(UpdateCommand.name, { configurationId: configurationId });
    model.update.confValue = this.modelFactory.createAny<ConfValueArgument>(ConfValueArgument.name, { longId: confValueLongId });
    model.update.responseFormat = this.getEditorResponseFormat();

    // For BoolParam or MultiChoiceValue try the next possible value if the value to be set is not allowed
    model.update.tryNextPossibleValue = confValue instanceof ConfBoolValue || confValue instanceof ConfMultiChoiceValue;

    model.confSessionId = confSessionId;

    if (value && value.length != 0)      
      model.update.confValue.value = value;

    if (includeMandatoryInfo)
      model = this.getMandatoryModel(model, configurationId, includeNextState);

    if (confValue instanceof ConfBoolValue || confValue instanceof ConfDoubleValue || confValue instanceof ConfIntValue || confValue instanceof ConfLookupValue) {
      this.confActionCreator.dispatchSetConfigurationPrimitiveValue(model);
    }
    else if (confValue instanceof ConfMultiChoiceValue) {
      let valueParam: any;
      if (value && value.length != 0)
        valueParam = [{ value: value, longId: paramValueId }]
      else
        valueParam = [{ longId: paramValueId }]

      model.update.confValue = this.modelFactory.createAny<ConfValueArgument>(ConfValueArgument.name, { longId: confValue.longId, value: valueParam });

      this.confActionCreator.dispatchSetConfigurationMultiSetValue(model);
    }
    else if (confValue instanceof ConfStringValue) {
      this.confActionCreator.dispatchSetConfigurationStringValue(model);
    }
  }

  public setConfPropertyValue(confId: number, confSessionId: number, confPropertyValue: ConfPropertyValue, key: any): void {
    let model = this.createRequest(RequestViews.Editor, { confSessionId: confSessionId });

    model.update = this.modelFactory.createRequestOrCommand<UpdateCommand>(UpdateCommand.name, { configurationId: confId });
    model.update.confPropertyValue = this.modelFactory.createAny<ConfPropertyValueArgument>(ConfPropertyValueArgument.name, {
      propertyName: confPropertyValue.propertyName,
      keyAndValue: this.modelFactory.createAny<KeyValue<string, string>>(KeyValue.name)
    });
    model.update.responseFormat = this.getEditorResponseFormat();
    model.confSessionId = confSessionId

    if (key && key.length > 0)
      model.update.confPropertyValue.keyAndValue.key = key;

    this.confActionCreator.dispatchSetConfigurationPropertyValue(model);
  }

  public getRootConfInfo(confSessionId: number): ConfInfo {
    
    // Add all root configuration infos.
    let sessionData = this.confSessionData(confSessionId);
    return sessionData.compositeStructure.get(sessionData.rootConfId);    
  }

  
  public getCodeFile(confSessionId: number, confId: number, codeFileId: number, client: RequestViews): HttpAction<ApiResponse> {

    let model = this.createRequest(client, { confSessionId: confSessionId });    
    model.code = this.modelFactory.createRequestOrCommand<CodeCommand>(CodeCommand.name, { codeFileId: codeFileId, configurationId: confId });

    return this.confActionCreator.dispatchGetCodeFile(model);
  }

  public getPriceReport(confSessionId: number, confId: number, requestType: RequestViews, isStored: boolean = false): HttpAction<ApiResponse> {    
    let model = this.createRequest(requestType, { confSessionId: confSessionId });
    model.confSessionId = confSessionId;
    model.price = this.modelFactory.createRequestOrCommand<PriceCommand>(PriceCommand.name,
      {
        getPriceReport: !isStored,
        getStoredPriceReport: isStored,
        configurationId: confId
      });

    return this.confActionCreator.dispatchGetPriceReport(model);
  }

  public updatePriceReport(confSessionId: number, activeConfId: number, reportConfId: number, requestType: RequestViews, manualPriceValues?: Immutable.List<KeyValue<number, number>>): HttpAction<ApiResponse> {

    let model: ConfDataRequest = this.createRequest(requestType, { confSessionId: confSessionId });
    model.confSessionId = confSessionId;
    model.price = this.modelFactory.createRequestOrCommand<PriceCommand>(PriceCommand.name,
      {
        getPriceReport: true,        
        getStoredPriceReport: false,
        manualPriceValues: manualPriceValues,
        configurationId: reportConfId
      });

    model.get = this.modelFactory.createRequestOrCommand<GetCommand>(GetCommand.name);
    model.get.configurationId = activeConfId;    
    model.get.responseFormat = this.getEditorResponseFormat();
    model.get.responseFormat.price = true;

    return this.confActionCreator.dispatchGetPriceReport(model);
    
  }

  public getPriceSetting(confSessionId: number, confId: number, requestType: RequestViews) {         
    let model = this.createRequest(requestType, { confSessionId: confSessionId });
    model.confSessionId = confSessionId;
    model.price = this.modelFactory.createRequestOrCommand<PriceCommand>(PriceCommand.name, { getPriceSetting: true, configurationId: confId });

    return this.confActionCreator.dispatchGetPriceSetting(model);
  }

  setPriceSetting(confId: number, confSessionId: number, priceListCategoryId?: number, priceListId?: number, currencyId?: number, requestType?: RequestViews): void {    
    let model = this.createRequest(requestType, { confSessionId: confSessionId });
    model.confSessionId = confSessionId;
    model.price = this.modelFactory.createRequestOrCommand<PriceCommand>(PriceCommand.name, { configurationId: confId });
    model.price.responseFormat = this.globalDataStore.getConfResponseFormat(requestType);

    if (priceListCategoryId)
      model.price.setPriceListCategory = priceListCategoryId;

    if (priceListId)
      model.price.setPriceList = priceListId;

    if (currencyId)
      model.price.setCurrency = currencyId;

    this.confActionCreator.dispatchSetPriceSetting(model);
  }

  public getReadOnlyInfo(confId: number, confSessionId: number, confValue: ConfValue, paramValueId?: number) {
    let model = this.createRequest(RequestViews.Editor, { confSessionId: confSessionId });    
    model.readOnly = this.modelFactory.createRequestOrCommand<ReadOnlyParamCommand>(ReadOnlyParamCommand.name, { confValueId: confValue.longId, configurationId: confId });

    if (paramValueId)
      model.readOnly.paramValueId = paramValueId;

    return this.confActionCreator.dispatchGetReadOnlyInfo(model);
  }

  public getMandatoryModel(model: ConfDataRequest, confId: number, includeNextState: boolean): ConfDataRequest {
    model.mandatoryParam = this.modelFactory.createRequestOrCommand<MandatoryParamsCommand>(MandatoryParamsCommand.name, { configurationId: confId });
    model.mandatoryParam.includeNextState = includeNextState;
    return model;
  }

  public getMandatoryInfo(confId: number, confSessionId: number, includeNextState: boolean, view: RequestViews): void {
    let model = this.createRequest(view, { confSessionId: confSessionId });
    model.confSessionId = confSessionId;
    model = this.getMandatoryModel(model, confId, includeNextState);
    this.confActionCreator.dispatchGetMandatoryInfo(model);
  }

  public getConfSessionData(confSessionId: number) {
    return this.getConfData().dataBySessionId.get(confSessionId.toString());
  }

  public getConfSessionIds(rootConfigurationId: number) {

    let confSessionIds: number[] = [];

    this.getConfData().dataBySessionId.forEach((value, key) => {
      if (value.rootConfId == rootConfigurationId)
        confSessionIds.push(value.confSessionId);
    });

    return confSessionIds;
  }

  /**
   *  Removes all ConfSessionData from store which contains the Root configuration id
   */
  public removeConfiguratorSessions(rootConfigurationId: number) {

    let confSessionIds = this.getConfSessionIds(rootConfigurationId);
    this.confActionCreator.dispatchRemoveConfiguratorSessions(confSessionIds);
  }
}