import { Injectable, Inject } from '@angular/core';
import * as Immutable from "immutable";

import { AppStore, StoreResponse, AccountDataState, BaseStore, AccountDataActions } from "../../state";
import {
  User, AccountDataRequest, LoginCommand, LogoutCommand, Customer, CustomerSite, CustomerContact,
  CurrentUserCommand, PasswordChangeCommand, AccountEntitiesCommand, WorkGroup, PasswordRecoverCommand, AccountVerificationCommand, SignUpCommand, KeyValue, ApiResponse
} from "../../models";
import { AccountDataActionCreator } from "./accountDataActionCreator";
import { AppStoreSubscriptionManager } from "../appStoreSubscriptionManager";
import { ManagedSubject } from "../../../../shared/managedSubject";
import { ModelFactory } from "../modelFactory";
import { BaseEntity } from "../../baseEntity";
import { ValueSubscriptionManager } from "../valueSubscriptionManager";
import { LoginUserType } from '../../models/requests/commands/loginUserType';
import { AccountDataController } from './accountDataController';
import { GlobalDataStore } from '../globalData';
import { Observable } from 'rxjs';
import { AuthenticationTokenTypes } from '../authenticationTokenTypes';
@Injectable()
export class AccountDataStore extends BaseStore {

  constructor(@Inject(AppStore) protected appStore: AppStore,
    protected accountDataController: AccountDataController,
    protected accountDataActionCreator: AccountDataActionCreator,
    protected appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ModelFactory) protected modelFactory: ModelFactory,
    protected valueSubscriptionManager: ValueSubscriptionManager,
    protected globalDataStore: GlobalDataStore,
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory, accountDataActionCreator);
  }

  protected getAccountData(): AccountDataState {
    return this.appStore.getState().accountData;
  }

  public createAccountRequest(rawObject?: Partial<AccountDataRequest>): AccountDataRequest {
    return this.modelFactory.createRequestOrCommand<AccountDataRequest>(AccountDataRequest.name, rawObject);
  }

  public getUser(): User {
    return this.getAccountData().user;
  }

  public showRecaptcha(): boolean {
    return this.getAccountData().showRecaptcha;
  }

  public isAccountData(className: string) {
    let classArray = [WorkGroup.name, User.name, Customer.name, CustomerSite.name, CustomerContact.name];
    return classArray.indexOf(className) > -1;
  }

  public getEntity<T extends BaseEntity>(id: number) {
    return this.getAccountData().entities.get(+id) as T;
  }

  public getEntities<T extends BaseEntity>(className: string, forceReload?: boolean): ManagedSubject<StoreResponse<Immutable.OrderedMap<number, T>>> {
    let response = new StoreResponse<Immutable.Map<number, T>>();

    if (!forceReload && this.getAccountData().entityIdsByClassName && this.getAccountData().entityIdsByClassName.has(className)) {
      response.data = this.getEntitiesByClassNameInternal(className);
      return this.valueSubscriptionManager.createSubject(response, true);
    }

    let model = this.createAccountRequest();
    model.entities = this.modelFactory.createRequestOrCommand<AccountEntitiesCommand>(AccountEntitiesCommand.name, { classNames: [className] });
    let action = this.accountDataActionCreator.dispatchFetchEntities(model);

    // Subscribe to store and return the results on request complete.
    return this.createAction(action, () => {
      response.data = this.getEntitiesByClassNameInternal(className);
      return response;
    });
  }

  protected getEntitiesByClassNameInternal<T extends BaseEntity>(className: string) {

    let ids = this.getAccountData().entityIdsByClassName.get(className);
    let orderedMap = Immutable.OrderedMap<number, T>();

    // ids can be null, return empty array if no data found.
    if (!ids)
      return orderedMap;
    
    ids.forEach((value, key) => {
      orderedMap = orderedMap.set(value, this.getAccountData().entities.get(value) as T);
    })

    return orderedMap;
  }

  public logInUser(username: string, password: string, recaptcha = "", logOutOtherUsers: boolean = true) {
    let model = this.createAccountRequest();
    model.login = this.modelFactory.createRequestOrCommand<LoginCommand>(LoginCommand.name, { username: username, password: password, recaptcha: recaptcha, logOutOtherUsers: logOutOtherUsers });

    this.accountDataActionCreator.dispatchUserLogin(model);
  }

    public logInAnonymous(languageTitle: string = null, languageCode: string = null) {
    let model = this.createAccountRequest();
    model.login = this.modelFactory.createRequestOrCommand<LoginCommand>(LoginCommand.name, { userType: LoginUserType[LoginUserType.Anonymous], languageTitle: languageTitle, languageCode: languageCode });

    this.accountDataActionCreator.dispatchUserLogin(model);
  }

  public logOutUser() {
    let model = this.createAccountRequest();
    model.logout = this.modelFactory.createRequestOrCommand<LogoutCommand>(LogoutCommand.name);

    this.accountDataActionCreator.dispatchUserLogout(model);
  }

  public initAccountVerifyRequest(userNameOrEmail: string, newAccount: boolean = true): void {

    let model = this.createAccountRequest();
    model.verify = this.modelFactory.createRequestOrCommand<AccountVerificationCommand>(AccountVerificationCommand.name, { initRequest: true, newAccountRequest: newAccount, accountRecoverRequest: !newAccount, usernameOrEmail: userNameOrEmail });

    this.accountDataActionCreator.dispatchAccountVerifyRequest(model, AccountDataActions.ACCOUNT_VERIFY_REQEUEST, AccountDataActions.ACCOUNT_VERIFY_REQUEST_RESULT);

  }

  public accountVerifyCode(userNameOrEmail: string, code: string): void {

    let model = this.createAccountRequest();
    model.verify = this.modelFactory.createRequestOrCommand<AccountVerificationCommand>(AccountVerificationCommand.name, { verifyRequest: true, usernameOrEmail: userNameOrEmail, code: code });

    this.accountDataActionCreator.dispatchAccountVerifyRequest(model, AccountDataActions.ACCOUNT_VERIFY_CODE, AccountDataActions.ACCOUNT_VERIFY_CODE_RESULT);

  }

  public createAccount(email: string, name: string, username: string, company: string, phone: string, mob: string, password: string): void {

    let model = this.createAccountRequest();
    model.create = this.modelFactory.createRequestOrCommand<SignUpCommand>(SignUpCommand.name,
      {
        username: username,
        email: email,
        name: name,
        company: company,
        phoneNumber: phone,
        mobileNumber: mob,
        password: password

      });

    this.accountDataActionCreator.dispatchCreateAccount(model);

  }

  public recoverPassword(userNameOrEmail: string, newPassword: string): void {

    let model = this.createAccountRequest();
    model.passwordRecover = this.modelFactory.createRequestOrCommand<PasswordRecoverCommand>(PasswordRecoverCommand.name, { changePasswordRequest: true, username: userNameOrEmail, newPassword: newPassword});

    this.accountDataActionCreator.dispatchVerifyCode(model);
  }

  validateSession(userNameOrEmail: string): void {

    let model = this.createAccountRequest();
    model.passwordRecover = this.modelFactory.createRequestOrCommand<PasswordRecoverCommand>(PasswordRecoverCommand.name, { username: userNameOrEmail, ValidateSessionRequest: true });

    this.accountDataActionCreator.dispatchVerifyCode(model);

  }
    
  public getLoggedInUser(): ManagedSubject<StoreResponse<User>> {

    let action = this.accountDataActionCreator.dispatchRequestLoggedInUser();

    // Create request object
    let response: StoreResponse<User> = new StoreResponse<User>();

    // Subscribe to store and return the results on request complete.
    return this.createAction(action, () => {
      response.data = this.getAccountData().user;
      return response;
    });
  }

  public updateProfile(name: string, email: string, company: string, phoneNumber: string, mobileNumber: string, ) {
    let model = this.createAccountRequest();
    model.currentUser = this.modelFactory.createRequestOrCommand<CurrentUserCommand>(CurrentUserCommand.name);
    model.currentUser.updateUser = [
      { key: User.NAME, value: name },
      { key: User.EMAIL, value: email },
      { key: User.COMPANY, value: company },
      { key: User.PHONE_NUMBER, value: phoneNumber },
      { key: User.MOBILE_NUMBER, value: mobileNumber }
    ];
    model.currentUser.get = true;

    this.accountDataActionCreator.dispatchUpdateProfile(model);
  }

  public changeUserPassword(username: string, currentPassword: string, newPassword: string) {
    let model = this.createAccountRequest();
    model.passwordChange = this.modelFactory.createAny<PasswordChangeCommand>(PasswordChangeCommand.name);
    model.passwordChange.username = username;
    model.passwordChange.currentPassword = currentPassword;
    model.passwordChange.newPassword = newPassword;

    this.accountDataActionCreator.dispatchChangeUserPassword(model);
  }

  public updateUserSettings(hightlightMandatoryParams: boolean, languageId?: string, defaultPriceListCategoryId?: string) {
    let model = this.createAccountRequest();
    model.currentUser = this.modelFactory.createRequestOrCommand<CurrentUserCommand>(CurrentUserCommand.name);

    let userValues: KeyValue<string, string>[] = [];
    if (languageId) {
        userValues.push({ key: "LanguageId", value: languageId });
    }
    if (defaultPriceListCategoryId) {
      userValues.push({ key: "DefaultPriceListCategoryId", value: defaultPriceListCategoryId });
    }
    model.currentUser.updateUser = userValues;

    model.currentUser.updateSettings = [
      { key: "HighlightMandatoryParameters", value: "" + hightlightMandatoryParams }
    ];
    model.currentUser.get = true;

    this.accountDataActionCreator.dispatchUpdateUserSettings(model);
  }

  public updateCompositeControlSticky(compositeControlSticky: boolean) {

    let model = this.createAccountRequest();
    model.currentUser = this.modelFactory.createRequestOrCommand<CurrentUserCommand>(CurrentUserCommand.name);

    model.currentUser.updateSettings = [
      { key: "CompositeControlSticky", value: "" + compositeControlSticky }
    ];
    model.currentUser.get = true;

    this.accountDataActionCreator.dispatchUpdateUserSettings(model);
  }
}