<floating [floatingPosition]="decoration.floatingPosition">
  <div class="property-decoration decoration container mr-0" [class.no-decoration-style]="enableParamStyle" [class.border-style]="!showTitle" [style.max-width.%]="'100'" >

    <div class="row decoration-head  decoration-header " *ngIf="showTitle">
      <div class="col-auto icon header-image align-middle">
        <co-image width="24" height="24" type="svg" key="confproperties" [imageSet]="imageSet"></co-image>
      </div>
      <div class="col title header-title align-middle">
        {{decoration.title}}
      </div>
    </div>
    
    <div class="row mx-0 conf-properties-body" [class.body-style]="enableParamStyle">
      
      <ng-container *ngFor="let model of propertyModels; trackBy: identify">

        <div [style.maxWidth]="model.maxWidth" [style.width]="'100%'" [class]="model.styles" attr.lineBreak="{{model.lineBreak}}" attr.floating="{{model.floatingPosition}}">
          <property-decoration-item class="d-block" [propertyModel]="model" [configurationId]="configurationId"></property-decoration-item>
        </div>
        <div *ngIf="model.lineBreak" class="break"></div>

      </ng-container>
    </div>

  </div>
</floating>