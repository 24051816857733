import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({ name: 'imageDimension' })
export class ImageDimensionPipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (value && !value.endsWith('px') && !isNaN(Number(value))) {
      return value + 'px';
    }

    return value || '';
  }
}

@NgModule({
  imports: [

  ],
  declarations: [
    ImageDimensionPipe
  ],
  providers: [

  ],
  exports: [
    ImageDimensionPipe
  ]
})
export class ImageDimensionModule {

};