import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpErrorResponse, HttpResponse, HttpEventType } from '@angular/common/http';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { Ioc, Register } from "../../../shared/ioc/iocdecorator";
import { ApiResponse, ApiException } from "../models"
import { ExceptionHandler } from "./exceptionHandler"
import { Exception } from "../../../shared/exception";
import { HttpException } from "../../../shared/httpException";
import { ModelFactory } from "./modelFactory";
import { HttpLifeCycleService, HttpStatus } from './httpLifeCycleService';
import { SignalRService } from './pushMessage/signalRService';
import { AccountDataStore } from './accountData/accountDataStore';
import { AuthenticationTokenTypes } from './authenticationTokenTypes';
import { HttpRequestTrackerService } from './httpRequestTrackerService';
import { TokenService } from './tokenService';

@Injectable()
export class HttpInterceptService implements HttpInterceptor {

  constructor(
    @Inject(AccountDataStore) public accountDataStore: AccountDataStore,
    public exceptionHandler: ExceptionHandler,
    public httpLifeCycle: HttpLifeCycleService,
    public signalRService: SignalRService,
    public modelFactory: ModelFactory,
    public httpRequestTrackerService: HttpRequestTrackerService,
    public tokenService: TokenService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let requestToUse = request;
    let headersToUse = requestToUse.headers;

    headersToUse = this.injectTokensInRequest(headersToUse);

    if (!requestToUse.headers.has("Combinum-Client-Version")) {

      headersToUse = headersToUse.append("Combinum-Client-Version", window.combinumApp.version);

      // Include header value only if available
      if (window.combinumApp.visualizationVersion && window.combinumApp.visualizationVersion.length > 0)
        headersToUse = headersToUse.append("Combinum-Visualization-Version", window.combinumApp.visualizationVersion);

      let connectionId = this.signalRService.connectionId;
      if (connectionId)
        headersToUse = headersToUse.append("SignalR-Connection-Id", connectionId);
      else
        headersToUse = headersToUse.append("SignalR-Temp-Id", this.signalRService.tempConnectionId.toString());

      headersToUse = headersToUse.append("Client-Request-Id", this.httpRequestTrackerService.addActiveRequest().toString());

      requestToUse = requestToUse.clone({ headers: headersToUse });
    }

    let requestEnded = false;
    this.httpLifeCycle.send(HttpStatus.Started, requestToUse);

    // If request takes longer than 2 seconds then show the blocker ui.
    setTimeout(() => {

      if (!requestEnded)
        this.httpLifeCycle.send(HttpStatus.WaitingState, requestToUse);

    }, 2000);

    let httpException: HttpException = null;

    return next.handle(requestToUse).pipe(
      // Extract ApiException from response.
      // Server can add non-fatal exception even though the response is ok.
      tap((response) => {

        if (response instanceof HttpResponse) {

          this.storeResponseToken(response);

          this.removeClientRequestId(response);

          requestEnded = true;
          this.httpLifeCycle.send(HttpStatus.Completed, requestToUse);

          // Read and store the visualization plugin version in the client
          let headerValue = response.headers.get("Combinum-Visualization-Version");
          if (headerValue && headerValue.length > 0)
            window.combinumApp.visualizationVersion = headerValue;
        }

        this.handleApiException(response);

        //when array buffer returns a json containing an error cancel the request, otherwise it will be downloaded
        if ((response as any).containsHttpError)
          throw new HttpErrorResponse({});
      }),
      catchError((errorResponse) => {
        // Bug in Angular 6, which treats json error as blob so we need to read it and parse it back as json.
        // Keep this just in case the bug ever comes back.
        if (errorResponse instanceof HttpErrorResponse) {
          if (errorResponse.error instanceof Blob && errorResponse.error.size > 0) {

            let blobReader: FileReader = new FileReader();
            let fileReadObservable: Observable<any> = Observable.create((observer: any) => {
              blobReader.onloadend = (e) => {

                (errorResponse as any).error = JSON.parse(blobReader.result as string);
                observer.error(errorResponse);
                observer.complete();
              }
            });
            blobReader.readAsText(errorResponse.error);
            return fileReadObservable;
          }
        }

        // If error is not blob then just throw the originalerror, it will be caught in next catch
        return throwError(errorResponse);
      }),
      catchError((errorResponse) => {
        // Unblock the UI
        requestEnded = true;
        this.httpLifeCycle.send(HttpStatus.Completed, requestToUse);

        let convertedError: HttpErrorResponse = this.convertErrorResponseToHttpErrorResponse(errorResponse);

        // Convert to HttpException
        httpException = this.getHttpException(convertedError);

        // Inform all subscribers that error has occurred
        return new Observable<any>((subscriber) => subscriber.error(httpException));
      }),
      finalize(() => {
        // Handle error rather than throwing to allow the application to remain stable
        if (httpException && !httpException.isHandled)
          this.exceptionHandler.handleException(httpException);        
      })
    );
  }

  removeClientRequestId(response) {

    let clientRequestId = response.headers.get('Client-Request-Id');
    if (clientRequestId) {
      this.httpRequestTrackerService.removeActiveRequest(clientRequestId);
    }
  }

  storeResponseToken(response) {
    let accessToken = response.headers.get('Combinum-Access-Token');
    if (accessToken)
      this.tokenService.accessToken = accessToken;
  }

  injectTokensInRequest(headers) {
    let accessToken = this.tokenService.accessToken;
    if (accessToken)
      headers = headers.append('Combinum-Access-Token', accessToken);

    return headers;
  }

  public getHttpException(errorResponse: HttpErrorResponse) {
    let httpException = new HttpException();

    httpException.errorResponse = errorResponse;
    httpException.exceptions = errorResponse.error;

    return httpException;
  }

  public extractApiExceptions(response: any) {

    let body = null;
    if (response instanceof HttpResponse)
      body = response.body;
    if (response instanceof HttpErrorResponse)
      body = response.error;

    //if it's an arraybuffer and contains a json then parse it
    if (body instanceof ArrayBuffer && response.headers.get("Content-Type") == "application/json; charset=utf-8") {
      let textDecoder = new TextDecoder('utf-8');
      let jsonString = textDecoder.decode(new Uint8Array(body));

      let parsedBody = JSON.parse(jsonString);

      if (parsedBody && parsedBody.className == ApiResponse.name && parsedBody.exceptions && parsedBody.exceptions.length > 0) {
        //used to cancel downloads when they contain an error
        response.containsHttpError = true;
        return this.modelFactory.createArray<ApiException>(parsedBody.exceptions).toArray();
      }
    }

    // Check if response has ApiException then return it
    if (body && body.className == ApiResponse.name && body.exceptions && body.exceptions.length > 0) {
      return this.modelFactory.createArray<ApiException>(body.exceptions).toArray();
    }

    return null;
  }

  public convertErrorResponseToHttpErrorResponse(errorResponse) {
    let apiExceptions: ApiException[] = this.extractApiExceptions(errorResponse);
    if (!apiExceptions && errorResponse) {
      // NOTE: Only exists as a failsafe.
      // If no ApiExceptions were available, then create this to know where it came from.
      // Although it should never come here because there should always be an ApiException available.
      apiExceptions = [this.createApiException(errorResponse.status)];

      // Create the error from the error response message.
      if (!errorResponse.error && errorResponse.message)
        errorResponse.error = [errorResponse.message];
    }

    return new HttpErrorResponse({ error: apiExceptions, headers: errorResponse.headers, status: errorResponse.status, statusText: errorResponse.statusText, url: errorResponse.url });
  }

  public createApiException(status: any) {
    let apiException: ApiException = null;

    switch (status) {
      case 401:
        apiException = new ApiException({ code: 4000, message: "ERROR" });
        break;
    }

    return apiException;
  }

  public handleApiException(response: any) {

    let apiExceptions = this.extractApiExceptions(response);
    if (apiExceptions) {
      apiExceptions.forEach((exception) => {
        this.exceptionHandler.handleException(exception);
      });
    }

    return response;
  }
}