import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JsonConvert } from "./jsonConvert";
import { param } from 'jquery';
import { TokenService } from '../../pages/shared/providers/tokenService';

@Injectable()
export class HttpService {

  constructor(@Inject(HttpClient) public httpClient: HttpClient,
    @Inject(TokenService) public tokenService: TokenService,
    protected jsonConvert: JsonConvert
  ) { }

  public createUrl(url: string, params?: any, addTimeStamp = true, injectToken = false) {

    let queryString = null;
    if (addTimeStamp)
      queryString = `t=${new Date().getTime()}`;
    if (params) {
      
      let json = this.encode(params);
      // Append the json param to query string
      queryString = `json=${json}&${queryString}`;
    }

    if (queryString)
      url += `?${queryString}`;

    if (injectToken)
      url = this.tokenService.addTokenToUrl(url);

    return url;
  }

  public get(url: string, params?: any, addTimeStamp = true): Observable<any> {
    return this.httpClient.get(this.createUrl(url, params, addTimeStamp)).pipe(this.extractData);
  }

  public head(url: string, params?: any): Observable<any> {
    return this.httpClient.head(this.createUrl(url, null, false), params).pipe(this.extractData);
  }

  public post(url: string, params: any): Observable<any> {
    let formData = new FormData();

    let json = this.jsonConvert.stringify(params);
    formData.append("json", json);

    return this.httpClient.post(url, formData)
      .pipe(this.extractData);
  }

  // refactor this method with the other downloadfile method
  public postExpectOptionalDownload(url: string, params: any): Observable<any> {
    let formData = new FormData();

    let json = this.jsonConvert.stringify(params);
    formData.append("json", json);

    let fileName = "download.dat";

    return new Observable<any>(observer => {

      this.httpClient.post(url, formData, { observe: 'response', responseType: 'arraybuffer' })
        .subscribe(
          response => {

            let contentType = response.headers.get("Content-Type");
            if (contentType && contentType.indexOf("json") > -1) {
              let blobData = new Blob([response.body], { type: contentType });
              let fileReader: FileReader = new FileReader();
              fileReader.onload = (e) => {

                  observer.next(JSON.parse(fileReader.result as string))
                  observer.complete();
              }              
              fileReader.readAsText(blobData);
              return;
            }

            //read the file type from the response
            let contentDisposition = response.headers.get('Content-Disposition');

            if (contentDisposition) {
              contentDisposition = contentDisposition.replace(/["']/g, "");
              let startPos = contentDisposition.indexOf("filename*=UTF-8") + 15;
              let startPos16 = contentDisposition.indexOf("filename*=UTF-16") + 16;
              if (startPos16 > 15 && startPos16 < startPos)
                startPos = startPos16;

              if (startPos > 14) {
                fileName = decodeURI(contentDisposition.substr(startPos));
              }
            }

            let blobData = new Blob([response.body], { type: contentType });

            if (window.navigator.msSaveOrOpenBlob) {
              let filename = fileName;
              window.navigator.msSaveOrOpenBlob(blobData, filename);
            }
            else {
              var a = document.createElement("a");
              document.body.appendChild(a);

              a.href = URL.createObjectURL(blobData);
              a.download = fileName;

              // start download
              a.click();
              document.body.removeChild(a);
              a = null;
            }

            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postWithProgress(url: string, formData: FormData): Observable<any> {
    let params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest('POST', url, formData, options);
    return this.httpClient.request(req).pipe(this.extractData);
  }

  public encode(value: any) {

    if (value == null)
      return null;

    return encodeURIComponent(this.jsonConvert.stringify(value));
  }

  public downloadFile(url: string, onSuccess?: any, onError?: any, createLink: boolean = true): void {
    let fileName = "download.dat";

    this.httpClient.get(url, { observe: 'response', responseType: 'arraybuffer' })
      .subscribe(
        response => {
          //read the file type from the response
          let contentDisposition = response.headers.get('Content-Disposition');

          if (contentDisposition) {
            contentDisposition = contentDisposition.replace(/["']/g, "");
            let startPos = contentDisposition.indexOf("filename*=UTF-8") + 15;
            let startPos16 = contentDisposition.indexOf("filename*=UTF-16") + 16;
            if (startPos16 > 15 && startPos16 < startPos)
              startPos = startPos16;

            if (startPos > 14) {
              fileName = decodeURI(contentDisposition.substr(startPos));
            }
          }

          let blobData = new Blob([response.body], { type: response.headers.get("Content-Type") });

          if (blobData && createLink) {

            if (window.navigator.msSaveOrOpenBlob) {
              let filename = fileName;
              window.navigator.msSaveOrOpenBlob(blobData, filename);
            }
            else {
              var a = document.createElement("a");
              document.body.appendChild(a);

              a.href = URL.createObjectURL(blobData);
              a.download = fileName;

              // start download
              a.click();
              document.body.removeChild(a);
              a = null;
            }            
          }

          if (onSuccess) 
            onSuccess(blobData);            
          
        },
        (error) => {
          if (onError)
            onError(error);
        }
      );
  }

  public readContents(url: string, encoding = "UTF-8"): Promise<any> {

    return new Promise<any>((resolve, reject) => {

      // First get the blob data from url.

      this.downloadFile(url, (blobData: Blob) => {

        // Read the blob data.
        const reader = new FileReader();

        // This fires after the blob has been read/loaded.
        reader.addEventListener('loadend', (e: any) => {
          const text = e.srcElement.result;
          resolve(text);
        });

        // Try to read the encoding from the content type
        let charset = "charset=";
        let index = blobData.type.indexOf(charset);
        if (index > -1)
          encoding = blobData.type.substr(index + charset.length);

        // Start reading the blob as text.
        reader.readAsText(blobData, encoding);

      }, reject, false);

    });

  }


  public extractData = map((response: any, index) => response || {});
}