import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler } from "@angular/core";
import { AddConfigurationComponent } from "../pages/configurator/actions/composite/add/addConfigurationComponent";
import { CompositeTreeDataProvider } from "../pages/configurator/actions/composite/tree/compositeTreeDataProvider";
import { ComparisonDataActionCreator, ComparisonDataController, ComparisonDataReducer, ComparisonDataStore, ComparisonHelper, ComparisonResultsResolver } from "../pages/configurator/comparison";
import { BomStore } from "../pages/configurator/decorations/bom";
import { BomActionCreator, BomActions } from "../pages/configurator/decorations/bom/bomActionCreator";
import { BomController } from "../pages/configurator/decorations/bom/bomController";
import { DataSelectorActionCreator, DataSelectorActions } from "../pages/configurator/decorations/dataSelector/dataSelectorActionCreator";
import { DataSelectorController } from "../pages/configurator/decorations/dataSelector/dataSelectorController";
import { VisualObjectViewModelFactory } from "../pages/configurator/parameters/shared/visualObjectViewModelFactory";
import { RuleActionCreator, RuleActions, RuleController } from "../pages/configurator/popups/rule";
import { ConfDataMemorizer, ConfigurationSessionManager, ConfiguratorLayoutManager, ConfiguratorStore, ConfiguratorUIStore, ConfMessageProvider, ConfPageSessionService } from "../pages/configurator/providers";
import { ConfDataDeactivateGuard } from "../pages/configurator/providers/confDataDeactivateGuard";
import { ConfDataLoadedGuard } from "../pages/configurator/providers/confDataLoadedGuard";
import { ConfEventService } from "../pages/configurator/providers/confEventService";
import { ConfDataResolver } from "../pages/configurator/providers/resolvers/confDataResolver";
import { ConfPageIdResolver } from "../pages/configurator/providers/resolvers/confPageIdResolver";
import { UIEventService } from "../pages/configurator/providers/uiEventService";
import { ConfSearchModelCacheService } from "../pages/configurator/search/confSearchModelCacheService";
import { SearchResultsResolver } from "../pages/configurator/search/searchResultsResolver";
import { EmitterService, TabVisibilityService, VisualObjectVisibilityService } from "../pages/configurator/shared";
import { SelectorActionCreator } from "../pages/selector/providers/selectorActionCreator";
import { SelectorStore } from "../pages/selector/providers/selectorStore";
import { SelectorTreeDataProvider } from "../pages/selector/providers/selectorTreeDataProvider";
import { AttachmentsDataActionCreator, AttachmentsDataController, AttachmentsStore, GraphicsHelper } from "../pages/shared/components";
import { CalculatedCodeValuesProvider, StoredCodeValuesProvider } from "../pages/shared/components/code";
import { CodeValueModelBuilder } from "../pages/shared/components/code/providers/codeValueModelBuilder";
import { StateActionCreator } from "../pages/shared/components/states/stateActionCreator";
import { StateController } from "../pages/shared/components/states/stateController";
import {
    ApiMessageProvider, ApiResponseBuilder, AppActionCreator,
    AppController, ApplicationLifeCycleService,
    AppStoreSubscriptionManager, AuthGuard,
    ExceptionHandler, HttpErrorCodeHandler, HttpLifeCycleService, IconTools, ImageService,
    ModelFactory, RouteRedirector,
  StartupArguments, ValueSubscriptionManager, BroadcastChannelService
} from "../pages/shared/providers";
import { AccountDataActionCreator, AccountDataController, AccountDataMemorizer, AccountDataMessageProvider, AccountDataNormalizer, AccountDataSchema, AccountDataStore } from "../pages/shared/providers/accountData";
import { ConfDataActionCreator, ConfDataController, ConfDataNormalizer, ConfDataSchema } from "../pages/shared/providers/configurationData";
import { EntityReselectorService } from "../pages/shared/providers/entityReselectorService";
import { GlobalDataActionCreator, GlobalDataController, GlobalDataNormalizer, GlobalDataSchema, GlobalDataStore } from "../pages/shared/providers/globalData";
import { Strings } from "../pages/shared/providers/globalData/strings";
import { HttpInterceptService } from "../pages/shared/providers/httpInterceptService";
import { ModelLabelService } from "../pages/shared/providers/modelLabelService";
import { PageActionCreator, PageUIStore } from "../pages/shared/providers/page";
import { PageStore } from "../pages/shared/providers/page/pageStore";
import { ProductDataActionCreator, ProductDataController, ProductDataStore } from "../pages/shared/providers/productData";
import { ProductDataNormalizer } from "../pages/shared/providers/productData/productDataNormalizer";
import { ProductDataSchema } from "../pages/shared/providers/productData/productDataSchema";
import { ReselectorService } from "../pages/shared/providers/reselectorService";
import { SearchDataActionCreator, SearchDataController, SearchDataMemorizer, SearchDataMessageProvider, SearchDataNormalizer, SearchDataStore } from "../pages/shared/providers/searchData";
import { AccountDataReducer, AppReducer, AppStore, ConfDataReducer, ConfiguratorUIReducer, ConfUIReducer, GlobalDataReducer, PageReducer, ProductDataReducer, SearchDataReducer, SelectorUIReducer } from "../pages/shared/state";
import { NotificationService } from "../shared/components/notification";
import { PopoverService } from "../shared/components/popover/popoverService";
import { PopupService } from "../shared/components/popup/popupService";
import { AbstractImageService, HttpService, VisualizationPluginManager } from "../shared/providers";
import { GlobalLogger } from "../shared/providers/globalLogger";
import { JsonConvert } from "../shared/providers/jsonConvert";
import { HTMLTemplateBuilder } from "../shared/providers/module/templateBuilder";
import { NormalizerService } from "../shared/providers/normalizer";
import { ReducersCombiner } from "../shared/providers/reducersCombiner";
import { BreakPointAccessor, BrowserInfo } from "../shared/utils";
import { ImageSetAccessor } from "../shared/utils/imageSetAccessor";
import { CompositeActionService } from "../pages/configurator/actions/composite/compositeActionService";
import { CompositeHelper } from "../pages/configurator/actions/composite/compositeHelper";
import { WindowRefService } from "../shared/components/windowRefService";
import { PushMessageStore, PushMessageActionCreator, PushMessageController } from "../pages/shared/providers/pushMessage";
import { PushMessageReducer } from "../pages/shared/state/pushMessage/pushMessageReducer";
import { PushMessageNormalizer } from "../pages/shared/providers/pushMessage/pushMessageNormalizer";
import { SettingsStore } from "../pages/settings/providers/settingsStore";
import { ConfVisiblityService } from "../pages/configurator/shared/confVisibilityService";
import { FormValueModelBuilder } from "../pages/shared/components/form";
import { PushMessageSchema } from "../pages/shared/providers/pushMessage/pushMessageSchema";
import { PushMessageMemorizer } from "../pages/shared/providers/pushMessage/pushMessageMemorizer";
import { HttpRequestTrackerService } from "../pages/shared/providers/httpRequestTrackerService";
import { UserService } from "../pages/shared/providers/userService";
import { LogoutActivateGuard } from "../pages/account/logoutActivateGuard";
import { TokenService } from "../pages/shared/providers/tokenService";

export let providerList: any[] = [
  Strings,
  AppStore,
  GlobalLogger,
  AppStoreSubscriptionManager,
  DataSelectorActions,
  DataSelectorActionCreator,
  BomActions,
  BomActionCreator,
  SelectorActionCreator,
  SelectorTreeDataProvider,
  CompositeTreeDataProvider,
  SelectorStore,
  ImageService,
  AppReducer,
  ConfDataActionCreator,
  HttpService,
  HttpRequestTrackerService,
  ModelFactory,
  HTMLTemplateBuilder,
  ReselectorService,
  EntityReselectorService,
  NormalizerService,
  ProductDataSchema,
  ProductDataNormalizer,
  AddConfigurationComponent,
  BreakPointAccessor,
  ConfDataSchema,
  ConfDataNormalizer,
  ConfDataReducer,  
  VisualObjectViewModelFactory,
  BrowserInfo,
  SelectorUIReducer,
  SelectorActionCreator,
  ConfiguratorUIReducer,
  ProductDataActionCreator,
  PopupService,
  PopoverService,
  AuthGuard,
  AccountDataController,
  AccountDataNormalizer,
  AccountDataSchema,
  AccountDataStore,
  AccountDataActionCreator,
  AccountDataReducer,
  AccountDataMemorizer,
  AccountDataMessageProvider,
  ConfUIReducer,
  UIEventService,
  ProductDataStore,
  PageReducer,
  PageUIStore,
  PageStore,
  ProductDataReducer,
  PageActionCreator,
  ProductDataController,
  ConfDataController,
  ConfEventService,
  ConfDataMemorizer,
  ConfMessageProvider,
  DataSelectorController,  
  SearchDataNormalizer,
  SearchDataActionCreator,
  SearchDataController,
  SearchDataStore,
  SearchDataReducer,
  SearchDataMemorizer,
  SearchDataMessageProvider,
  ComparisonDataActionCreator,
  ComparisonDataReducer,
  ComparisonDataController,
  ComparisonDataStore,
  ComparisonResultsResolver,
  RuleActionCreator,
  RuleActions,
  RuleController,
  //ExceptionHandler,
  { provide: ErrorHandler, useExisting: ExceptionHandler, multi: false },
  //new Registration(ErrorHandler).forwardExisting(ExceptionHandler),
  ExceptionHandler,
  NotificationService,
  JsonConvert,
  { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptService, multi: true },
  //new Registration(HTTP_INTERCEPTORS).forwardClass(HttpInterceptService).allowMultiple(),
  RouteRedirector,
  ValueSubscriptionManager,
  AppActionCreator,
  AppController,
  GlobalDataActionCreator,
  GlobalDataController,
  GlobalDataStore,
  GlobalDataSchema,
  GlobalDataNormalizer,
  GlobalDataReducer,
  ReducersCombiner,
  ApplicationLifeCycleService,
  StartupArguments,
  //ImageService,
  { provide: AbstractImageService, useClass: ImageService, multi: false },
  //new Registration(AbstractImageService).forwardClass(ImageService),
  ConfPageIdResolver,
  ConfDataResolver,
  ConfiguratorUIStore,
  ConfigurationSessionManager,
  ConfiguratorStore,
  StateController,
  StateActionCreator,
  ConfiguratorLayoutManager,
  ModelLabelService,
  ApiResponseBuilder,
  ApiMessageProvider,
  SearchResultsResolver,
  ConfSearchModelCacheService,
  BroadcastChannelService,
  ConfPageSessionService,
  ConfDataLoadedGuard,
  ConfDataDeactivateGuard,
  HttpErrorCodeHandler,
  AttachmentsDataActionCreator,
  AttachmentsDataController,
  AttachmentsStore,  
  EmitterService,
  VisualObjectVisibilityService,
  TabVisibilityService,
  GraphicsHelper,
  HttpLifeCycleService,
  BomStore,  
  BomController,
  ComparisonHelper,
  IconTools,
  CalculatedCodeValuesProvider,
  StoredCodeValuesProvider,
  CodeValueModelBuilder,
  ImageSetAccessor,
  CompositeActionService,
  CompositeHelper,
  VisualizationPluginManager,
  WindowRefService,
  PushMessageStore,
  PushMessageActionCreator,
  PushMessageController,
  PushMessageReducer,
  PushMessageNormalizer,
  PushMessageSchema,
  PushMessageMemorizer,
  SettingsStore,
  ConfVisiblityService,
  FormValueModelBuilder,
  UserService,
  LogoutActivateGuard,
  TokenService
]
