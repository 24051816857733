<div id="wrapper" class="{{extraStyles}}">
  <limited-browser-support [message]="limitedBrowserSupportInfo" *ngIf="isLimitedBrowserSupport"></limited-browser-support>
  <header *ngIf="isUserLoggedIn">
    <top-header></top-header>
  </header>
  <section id="{{containerId}}" [class.is-mobile]="isMobile">
    <router-outlet (activate)='onComponentActivate($event)'></router-outlet>
  </section>
</div>

<notification [detailButtonText]="notificationDetailString"
              [messageButtonText]="notificationMessageString"></notification>

<push-message #pushMessagePopup (action)="onAction($event)" [uiPopup]="uiPopup" [sessionId]="pushMessageSessionId"></push-message>
<block-ui name="blockui" class="{{blockUIStyle}}" [message]="'Loading...'"></block-ui> <!--TODO localize LoadingApost ??-->
<print-component (onStart)="extraStyles = 'ignore-printing'" (onSuccess)="extraStyles = ''"></print-component>
<message-box #navigateMessageBox (okClick)="navigateMessageBoxOkClick($event)" [title]="" [okButtonText]="" [cancelButton]="false" [showCloseButton]="false"></message-box>
<crmIntegration-popup></crmIntegration-popup>
<input #upload type="file" name="file" (change)="selectFile($event)" class="d-none" />