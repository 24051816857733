import { AuthenticationTokenTypes } from "./authenticationTokenTypes";
import { Injectable } from "@angular/core";


@Injectable()
export class TokenService {
  public accessToken: string;

  constructor(
  ) { }

  public addTokenToUrl(url: string): string {
    if (!url)
      return null;

    if (!this.accessToken)
      return url;

    let token_param = `${AuthenticationTokenTypes.AccessToken}=${this.accessToken}`;
    url += ((url.includes('?') ? '&' : '?') + token_param);

    return url;
  }
}