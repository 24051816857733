import { Injectable } from "@angular/core";
import { CalculatedCodeValuesProvider, StoredCodeValuesProvider } from "..";
import { CodeDecorationValuesModel, FileCodeValueModel } from "../codeDecorationValueModel";
import { ConfUIItem, CodeDisplayStyles, CodeDecoration } from "../../../models";
import { SummaryDisplayStyles } from "../../../../configurator/summary/prices/SummaryDisplayStyle";
import * as Immutable from "immutable";
import { ProductDataStore } from "../../../providers/productData";
import { ConfPageSessionService } from "../../../../configurator/providers";

@Injectable()
export class CodeValueModelBuilder {

  constructor(
    public calculatedDataProvider: CalculatedCodeValuesProvider,
    public storedDataProvider: StoredCodeValuesProvider,
    public productStore: ProductDataStore,
    public storeSession: ConfPageSessionService) {

  }

  /**
   * Returns the list of values for each decoration.   
   * Note!  if <confUIItem> is the instance of <GroupConfUIItem> then list must contain the multiple decorations models.
   * @param confUIItem
   * @param isEditor
   * @param filterDisplayStyles
   */
  public build(confUIItem: ConfUIItem, isEditor: boolean, filterDisplayStyles: Array<CodeDisplayStyles>, configurationId: number): Immutable.List<CodeDecorationValuesModel> {

    // Each confUIItem represents one decoration.
    let confUIItems = this.calculatedDataProvider.extractDecorationConfUIItems(confUIItem);

    let valueModels: Array<CodeDecorationValuesModel> = [];
    confUIItems.forEach(uiItem => {
      
      let model = this.createDecorationValueModel(uiItem, isEditor, filterDisplayStyles, configurationId);
      if (model)
        valueModels.push(model);

    });

    return Immutable.List(valueModels);
  }


  createDecorationValueModel(uiItem: ConfUIItem, isEditor: boolean, filterDisplayStyles: Array<CodeDisplayStyles>, configurationId: number): CodeDecorationValuesModel {

    let decoration = this.productStore.getEntity<CodeDecoration>(uiItem.id);

    if ((isEditor && !decoration.showInConfigurator) || (!isEditor && !decoration.showInSummary))
      return;

    const displayStyle = decoration.displayStyle as CodeDisplayStyles;

    // Must match the filter criteria if it is defined.
    if (filterDisplayStyles && filterDisplayStyles.indexOf(displayStyle) == -1)
      return;
    
    let model = new CodeDecorationValuesModel();
    model.decorationId = uiItem.id;

    // Supported display styles for current decoration.
    // In editor mode system only supports calculated but in summary page it could have all possible options.
    const valuesDisplayPattern = isEditor ? SummaryDisplayStyles.Calculated : decoration.summaryDisplayStyle as SummaryDisplayStyles;


    let codeValues = uiItem.items.map(codeUIItem => this.createCodeFileValueModel(codeUIItem, valuesDisplayPattern, configurationId));

    model.values = Immutable.List<FileCodeValueModel>(codeValues);

    return model;
  }

  createCodeFileValueModel(fileItem: ConfUIItem, displayStyle: SummaryDisplayStyles, configurationId: number): FileCodeValueModel {

    let codeValue = new FileCodeValueModel();

    switch (displayStyle) {

      case SummaryDisplayStyles.Calculated:
        {
          codeValue.calcValue = this.calculatedDataProvider.getCodeValue(fileItem.id, configurationId);
          break;
        }

      case SummaryDisplayStyles.Stored:
        {
          codeValue.storedValue = this.storedDataProvider.getCodeValue(fileItem.id, configurationId);
          break;
        }

      case SummaryDisplayStyles.Both:
        {
          codeValue.calcValue = this.calculatedDataProvider.getCodeValue(fileItem.id, configurationId);
          codeValue.storedValue = this.storedDataProvider.getCodeValue(fileItem.id, configurationId);
          break;
        }
    }


    return codeValue;
  }

}