import { Injectable, Inject } from '@angular/core';
import { RouterModule, DefaultUrlSerializer, UrlTree, UrlSerializer, Router, ParamMap } from '@angular/router';
import { TokenService } from './tokenService';
import { Object } from 'core-js';

@Injectable()
export class StartupArguments {

  protected localeKey = "locale";
  protected combinumAccessTokenKey = "combinum_access_token";
  protected allParams: ParamMap;  

  public locale: string;  

  constructor(@Inject(TokenService) public tokenService: TokenService) {

    let urlSerializer = new DefaultUrlSerializer();
    let urlTree = urlSerializer.parse(window.location.href.replace(window.location.origin, ""));

    this.allParams = urlTree.queryParamMap;    
    this.locale = this.allParams.has(this.localeKey) ? this.allParams.get(this.localeKey) : null;

    this.tokenService.accessToken = this.getParamFromHash(this.combinumAccessTokenKey);
  }

  getArgument(key: string)
  {
    if (this.allParams.has(key))
      return this.allParams.get(key);

    return null;
  }

  getParamFromHash(key: string)
  {
    let hash = window.location.hash;

    if (!hash)
      return null;

    let params = new URLSearchParams(hash.replace(/;/g, '&'));

    if (!params.has(key))
      return null;

    return params.get(key);
  }
}