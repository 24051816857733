import { Host, Component, Inject, ChangeDetectorRef, HostListener, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";
import { SubscriptionLike } from "rxjs";
import * as Immutable from 'immutable';
import { PageComponent } from "../shared";
import { ConfPageSessionService, ConfiguratorUIStore, ConfiguratorStore, ConfRouteParams, ConfiguratorLayoutManager, ConfMessageHandler, ConfMessageProvider, ConfDataMemorizer, PopupIdentifiers, ConfDataResolveResponse } from "./providers";
import { AppStoreSubscriptionManager, RouteRedirector, RouteNames } from "../shared/providers";
import { CompositeTreeDataProvider } from "./actions/composite/tree/compositeTreeDataProvider";
import { ConfiguratorPageUIData, PageUIData } from "../shared/state";
import { ActivatedRoute, Router, ActivationEnd, ResolveEnd, ChildActivationEnd } from "@angular/router";
import { ProductDataStore } from "../shared/providers/productData";
import { ConfInfo, ConfSaveMessage, MandatoryParamsMessage, ConfEmailMessage, User, ConfErrorMessage, SaveErrorReason, RequestViews, ApiException, ConfDataResponse, Product } from "../shared/models";
import { ParameterMandatoryService } from "./parameters/shared/parameterMandatoryService";
import { VisualObjectNavigatorService } from "./shared/visualObjectNavigatorService";
import { NotificationInfo, NotificationService, NotificationType, PopupService } from "../../shared/components";
import { PageActionCreator } from "../shared/providers/page";
import { ManagedSubscription } from "../../shared/managedSubscription";
import { PageStore } from "../shared/providers/page/pageStore";
import { MandatoryPopupComponent } from "./popups";
import { AccountDataStore } from "../shared/providers/accountData";
import { GlobalDataStore } from "../shared/providers/globalData";
import { ExceptionHandler } from "../shared/providers/exceptionHandler";
import { ErrorCodes } from "../../shared/errorCodes";
import { CompositeActionService } from "./actions/composite/compositeActionService";
import { CompositeHelper } from "./actions/composite/compositeHelper";
import { searchUrlQueryParam } from "../../shared/utils/routeParamsUtils";
import { PushMessageStore } from "../shared/providers/pushMessage";
import { UserService } from "../shared/providers/userService";

enum ActivateMandatoryReason {

  MandatoryClick = 'mandatoryClick',
  SavedClick = 'savedClick',
  SavedAndClosedClick = 'savedAndClosedClick',
  AlreadyOpened = 'alreadyOpened',
  Other = 'other'
}

@Component({
  selector: 'configurator-view',
  templateUrl: './configuratorComponent.html',
  providers: [CompositeTreeDataProvider, AppStoreSubscriptionManager, ParameterMandatoryService, VisualObjectNavigatorService]
})
export class ConfiguratorComponent extends PageComponent {

  public showComposite: boolean = false;
  public compositeWidth: number;
  public canExitEditor: boolean = false;
  public activateMandatoryReason: ActivateMandatoryReason;

  public compositeStyles: string;
  public isEditor: boolean = true;
  public activeRouteStyle: string = '';
  public messageSubscription: ManagedSubscription;
  public errorMessageSubscription: ManagedSubscription;
  public mandatorySubscription: ManagedSubscription;
  public confEmailMessageSubscription: ManagedSubscription;
  public popStateSubscription: SubscriptionLike;
  public exceptionSubscription: Subscription;

  public isAnonymousMode: boolean = false;
  // Allows the configurator to be redirected without being prevented by unsaved changes
  protected isRedirectingOnError: boolean = false;
    
  @ViewChild(MandatoryPopupComponent)
  protected mandatoryPopup: MandatoryPopupComponent;

  constructor(@Inject(ActivatedRoute) public activatedRoute: ActivatedRoute,
    @Inject(Router) public router: Router,
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ConfiguratorStore) public configuratorStore: ConfiguratorStore,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(ConfMessageProvider) public confMessageProvider: ConfMessageProvider,
    @Inject(CompositeTreeDataProvider) public treeDataProvider: CompositeTreeDataProvider,
    @Inject(NotificationService) public notificationService: NotificationService,
    @Inject(ConfiguratorLayoutManager) public uiNormalizer: ConfiguratorLayoutManager,
    @Inject(AppStoreSubscriptionManager) public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ChangeDetectorRef) public cd: ChangeDetectorRef,
    @Inject(PageActionCreator) public pageActionCreator: PageActionCreator,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ParameterMandatoryService) public parameterMandatoryService: ParameterMandatoryService,
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    @Inject(UserService) public userService: UserService,
    @Inject(PushMessageStore) public pushMessageStore: PushMessageStore,
    public location: Location,
    protected exceptionHandler: ExceptionHandler
  ) {
    super();
  }

  ngOnInit() {
    // Set the pageId.
    let pageId: number = this.activatedRoute.snapshot.data['pageId'] as number;
    this.confPageSession.setPageId(pageId);
    
    let user: User = this.accountStore.getUser();
    this.isAnonymousMode = !user || user.systemAuthorization.hasAnonymousAccess;  
    this.setActiveView();
    this.registerMandatoryMessage();

    // If the page is refreshed and data is not loaded then go to redirector page to load the data.
    if (!this.confPageSession.dataLoaded)
      this.subscribePageUIChanges();

    this.handleConfiguratorError();
    super.ngOnInit();
  }

  ngOnDestroy() {

    if (this.exceptionSubscription)
      this.exceptionSubscription.unsubscribe();

    if (this.messageSubscription)
      this.messageSubscription.unsubscribe();

    if (this.popStateSubscription)
      this.popStateSubscription.unsubscribe();

    if (this.mandatorySubscription)
      this.mandatorySubscription.unsubscribe();

    if (this.confEmailMessageSubscription)
      this.confEmailMessageSubscription.unsubscribe();

    if (this.errorMessageSubscription)
      this.errorMessageSubscription.unsubscribe();

    this.appStoreSubscriptionManager.dispose();
        
    // Close ConfiguratorSession when the user exists the Configurator page
    this.configuratorStore.closeConfSession(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId);
    this.confPageSession.dispose();
    this.confUIStore.setConfParams(null);

    // reset mandatory info.
    this.confUIStore.includeMandatoryInfo = false;
    this.confUIStore.isNextStateIncluded = false;

    super.ngOnDestroy();
  }

  setActiveView(): void {

    // Read the active view i.e editor/summary.
    let firstChild = this.activatedRoute.snapshot.firstChild;
    if (firstChild && firstChild.params) {

      this.activeRouteStyle = this.activatedRoute.snapshot.firstChild.params.view;
      this.isEditor = this.activeRouteStyle == RequestViews.Editor;

      if (!this.isEditor && this.canExitEditor) {
        this.unblockUI();
        this.canExitEditor = false;
      }
    }

  }

  public get canExitConfigurator(): boolean {

    // If user has been logged out then exit is allowed
    if (!this.userService.isUserLoggedIn())
      return true;

    // If data has not been loaded then exit is allowed
    if (!this.confPageSession.dataLoaded)
      return true;

    // If an error caused redirect away from configurator then exit is allowed
    if (this.isRedirectingOnError)
      return true;

    // If there are no unsaved changes then exit is allowed
    if (!this.configuratorStore.hasUnsavedChanges(this.confPageSession.confSessionId))
      return true;

    if (this.pushMessageStore.isRedirecting() || this.pushMessageStore.isRefreshing())
      return true;

    return false;
  }

  updateUI(): void {
    let confUIData: ConfiguratorPageUIData = this.confUIStore.getConfiguratorPageUIState(this.pageId) as ConfiguratorPageUIData;
    this.compositeStyles = confUIData.compositeUI.calcSticky ? 'col-auto padding-right h-100' : '';
    this.cd.markForCheck();
  }

  /**
   * Sets the composite visiblity after getting the data ready.
   */
  subscribePageUIChanges(): void {

    this.confUIStore.onActiveConfChange(this.confPageSession.pageId, (confId: number) => {

      this.setActiveView();

      // Read the global settings and set the visibility status
      this.showComposite = this.globalDataStore.getGlobalData().globalSettings.showCompositeSidebar;
      if (this.showComposite) {

        // Grab the root conf.
        let rootConf: ConfInfo = this.configuratorStore.getConfInfo(this.confPageSession.activeConfiguration.rootId, this.confPageSession.confSessionId);
        let hasCompositProducts: boolean = this.productStore.hasCompositeStructure(rootConf.productId);

        // If it is summary page then show the composite if child configurations exist.
        this.showComposite = hasCompositProducts || (!this.isEditor && rootConf.children.size > 0);

      }
      /*this.updateMandatoryPopup();      */

      // If the view data is loaded then UI nned to be updated
      this.updateUI();
      this.subscribeConfDataChanges();
    }).unsubscribeOn(this.unsubscribeSubject);

    // Listen the store changes when active route updated there.
    this.pageStore.onActiveRouteNameChange((view: string): void => {
      if (!view)
        return;

      if (!this.isEditor && this.activateMandatoryReason === ActivateMandatoryReason.SavedAndClosedClick || this.isEditor && this.activateMandatoryReason === null) {

        this.activateMandatoryReason = null;
        this.popupService.close(PopupIdentifiers.Mandatory);
      }

      this.isEditor = view === RouteNames.Editor ? true : false;
      this.activeRouteStyle = view;
       
      // reset mandatory info.
      this.confUIStore.includeMandatoryInfo = false;      
      this.confUIStore.isNextStateIncluded = false;
    }).unsubscribeOn(this.unsubscribeSubject);

    this.confUIStore.onCompositeCalcStickyChange(this.confPageSession.pageId, (calcSticky: boolean): void => {
      this.updateUI();
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  subscribeConfDataChanges(): void {
    if (this.messageSubscription)
      this.messageSubscription.unsubscribe();

    // Save configuration message
    this.messageSubscription = this.confMessageProvider.onMessagesRequest<ConfSaveMessage>(this.confPageSession.confSessionId, ConfSaveMessage.name, {
      next: (messages: Immutable.List<ConfSaveMessage>) => {

        let message: ConfSaveMessage = messages.first();
        
        this.unblockUI();

        this.notificationService.notify(<NotificationInfo>{
          title: message.title || message.displayStyle ? message.title : message.success ? this.strings.Success : this.strings.Info,
          message: message.message,
          type: message.displayStyle ? message.displayStyle : message.success ? NotificationType.Success : NotificationType.Info,
          selfClose: true
        });
        if (message.success) {
          this.popupService.close(PopupIdentifiers.AnonymousDialog);

          // Saved successfully -> don't include mandatory info any more.
          if (!this.mandatoryPopup.visible && this.confUIStore.includeMandatoryInfo)
            this.confUIStore.includeMandatoryInfo = false;

          if (this.canExitEditor) {

            this.blockUI();

            let targetConfId = this.confPageSession.activeConfigurationId;

            // Make a redirect to root configuration if autoSelectRootConf true
            if (this.globalDataStore.globalSettings.autoSelectRootConfiguration)
              targetConfId = this.confPageSession.activeConfiguration.rootId;

            this.routeRedirector.redirectToSummary(<ConfRouteParams>{ id: targetConfId, confSessionId: this.confPageSession.confSessionId });
          }
        }
        else {

          switch (message.saveErrorReason) {

            case SaveErrorReason.AnonymousUserEmail:
              this.popupService.open(PopupIdentifiers.AnonymousDialog);
              break;

            case SaveErrorReason.MandatoryParameters:
              this.activateMandatoryReason = ActivateMandatoryReason.SavedClick;
              break;

            case SaveErrorReason.Other:

              break;
          }

        }
      },
      listenNewEventsOnly: true
    });

    if (this.errorMessageSubscription)
      this.errorMessageSubscription.unsubscribe();

    // Configurator error messages
    this.errorMessageSubscription = this.confMessageProvider.onMessagesRequest<ConfErrorMessage>(this.confPageSession.confSessionId, ConfErrorMessage.name, {
      next: (messages: Immutable.List<ConfErrorMessage>) => {
                
        messages.forEach(confErrorMessage => {

          let detail: string = "";
          if (confErrorMessage.details && confErrorMessage.details.size > 0)
            detail = confErrorMessage.details.join("\n");

          let rawInfo = confErrorMessage.stackTrace;

          // If the details are empty we will move the stacktrace to the first tab.
          if (!detail) {
            detail = rawInfo;
            rawInfo = null;
          }

          this.notificationService.notify(<NotificationInfo>{
            title: this.strings.Error,
            message: confErrorMessage.message,
            detail: detail,
            rawInfo: rawInfo,
            type: NotificationType.Error,
            selfClose: false
          });

        });        

      },
      listenNewEventsOnly: true
    });

    this.registerMandatoryMessage();

    if (this.popStateSubscription)
      this.popStateSubscription.unsubscribe();

    // Handle back/forward buttons to navigate to the correct configuration.
    this.popStateSubscription = this.location.subscribe((popState: PopStateEvent) => {
      if (popState.type === "popstate" && this.activatedRoute.firstChild && this.activatedRoute.firstChild.snapshot) {
        let paramMap: any = (this.activatedRoute.firstChild.snapshot.paramMap as any).params;

        // URL is like "/configurator/editor;id=15324;confSessionId=1607"
        let url = (popState as any).url as string;

        // Remove the query params if url contains them. They are set to model in different way.
        if (url.indexOf('?') > 0) {
          url = url.split('?')[0];
        }

        let splittedUrl = url ? url.split("/") : null;
        if (splittedUrl && splittedUrl.length === 3 && splittedUrl[1] === RouteNames.Configurator) {
          // subRoutingUrl is like "editor;id=15324;confSessionId=1607"
          let subRoutingUrl = splittedUrl[2].split(";");
          if (subRoutingUrl.length === 3) {

            // Get id and confSessionId.
            let newConfParams: ConfRouteParams = { ...JSON.parse('{"' + subRoutingUrl[1].replace(/=/g, '":"') + '"}'), ...JSON.parse('{"' + subRoutingUrl[2].replace(/=/g, '":"') + '"}') };
            newConfParams.subConfId = +searchUrlQueryParam(ConfRouteParams.SUB_CONF_ID);

            newConfParams.id = parseInt(newConfParams.id as any);
            if (newConfParams.id !== this.confPageSession.activeConfigurationId) {
              // go to the correct configuration here

              console.log(`redirecting to ${newConfParams.id}, from ${this.confPageSession.activeConfigurationId}`);

              //newConfParams.view = <RouteNames>subRoutingUrl[0];
              //this.routeRedirector.redirectToConfigurator(newConfParams);
              //this.messageSubscription.unsubscribe();
              //this.popStateSubscription.unsubscribe();

              // TODO go through the redirector for now.. Should maybe be updated to swap configuration directly?    
              // this.routeRedirector.redirectToRedirector(`${RouteNames.Configurator}/${subRoutingUrl[0]}`, newConfParams);
            }
          }
        }
      }
    });
  }

  onSave() {
    this.canExitEditor = false;
    this.saveInternal();  
    this.plainBlockUI();
  }

  onSaveAndExitEditor() {
    this.canExitEditor = true;
    this.blockUI();
    this.activateMandatoryReason = ActivateMandatoryReason.SavedAndClosedClick;
    this.saveInternal();
  }

  onModify() {
    this.routeRedirector.redirectToEditor(<ConfRouteParams>{ id: this.confPageSession.activeConfigurationId, confSessionId: this.confPageSession.confSessionId });    
  }

  onStartOver() {

    if (this.confPageSession.referralLink) {      
      this.routeRedirector.redirectToRedirector(this.confPageSession.referralLink, { type: 'new' });
      return;
    }

    if (!this.confPageSession.activeConfiguration.copiedFromId)
      this.routeRedirector.redirectToSelector();
    else {
      this.routeRedirector.redirectToRedirector(`${RouteNames.Configurator}/${RouteNames.Editor}`, { reuse: this.confPageSession.activeConfiguration.copiedFromId });
    }
  }

  onCloseConf() {    

    let activeConfInfo = this.configuratorStore.getConfInfo(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId);

    if (activeConfInfo.isNew)
      this.routeRedirector.redirectToRedirector(RouteNames.Start);

    else this.routeRedirector.redirectToRedirector(`${RouteNames.Configurator}/${RouteNames.Summary}`, { id: activeConfInfo.longId });

  }

  saveInternal(): void {    
    this.configuratorStore.saveConfiguration(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId, this.canExitEditor);        
  }

  listenForEmailMessage(closeOnSuccess: boolean) {
    if (this.confEmailMessageSubscription)
      this.confEmailMessageSubscription.unsubscribe();

    this.confEmailMessageSubscription = this.confMessageProvider.onMessagesRequest<ConfEmailMessage>(this.confPageSession.confSessionId, ConfEmailMessage.name, {
      listenNewEventsOnly: true,
      startOnDemand: true,
      next: (messages) => {
        this.confEmailMessageSubscription.unsubscribe();

        let message: ConfEmailMessage = messages.first();

        if (closeOnSuccess && message.success) {
          this.popupService.close(PopupIdentifiers.AnonymousDialog);
          this.popupService.close(PopupIdentifiers.AnonymousContact);
        }

        this.notificationService.notify(<NotificationInfo>{
          title: message.success ? this.strings.Success : this.strings.Error,
          message: message.message,
          type: message.success ? NotificationType.Success : NotificationType.Error,
          selfClose: true
        });
      }
    });
    this.confEmailMessageSubscription.start();
  }

  onAnonymousContactClosed($event) {
    let fields = $event.fields;

    this.configuratorStore.contact(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId, fields)
    this.listenForEmailMessage(true);
  }

  onAnonymousClosed($event) {
    let anonymousUserEmail = $event.anonymousUserEmail;
    let sendEmail = $event.sendEmail;
    let updateEmail = $event.updateEmail;   

    this.listenForEmailMessage(true);

    if (this.isEditor) {
      this.configuratorStore.saveConfiguration(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId, this.canExitEditor, anonymousUserEmail, sendEmail, updateEmail);
    }
    else {
      this.configuratorStore.emailConfiguration(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId, anonymousUserEmail, sendEmail)
    }
  }

  updateMandatoryPopup(): void {

    if (!this.activateMandatoryReason)
      return;

    let activeView = this.isEditor ? RequestViews.Editor : RequestViews.Summary;

    switch(this.activateMandatoryReason) {

      case ActivateMandatoryReason.Other:

        this.confUIStore.isNextStateIncluded = true;
        this.configuratorStore.getMandatoryInfo(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId, true, activeView);
        this.activateMandatoryReason = null;
        break;

      case ActivateMandatoryReason.MandatoryClick:

        this.confUIStore.isNextStateIncluded = true;
        this.configuratorStore.getMandatoryInfo(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId, true, activeView);
        break;

      case ActivateMandatoryReason.SavedClick:

        this.confUIStore.isNextStateIncluded = false;
        this.configuratorStore.getMandatoryInfo(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId, false, activeView);
        break;
    }    
  }

  onMandatoryClick(): void {    

    this.activateMandatoryReason = ActivateMandatoryReason.MandatoryClick;
    this.popupService.open<Immutable.List<MandatoryParamsMessage>>(PopupIdentifiers.Mandatory, Immutable.List<MandatoryParamsMessage>());    
    this.updateMandatoryPopup();
    
  }

  onMandatoryClosed(): void {
    this.activateMandatoryReason = null;
  }

  // Returns the page identifier.
  get pageId(): number {
    return this.confPageSession.pageId;
  }

  registerMandatoryMessage(): void {

    if (this.mandatorySubscription)
      this.mandatorySubscription.unsubscribe();

    // Mandatory message
    this.mandatorySubscription = this.confMessageProvider.onMessagesRequest<MandatoryParamsMessage>(this.confPageSession.confSessionId, MandatoryParamsMessage.name, {
      next: (messages: Immutable.List<MandatoryParamsMessage>) => {

        if (messages == null)
          messages = Immutable.List<MandatoryParamsMessage>();

        if (!(this.activateMandatoryReason && (this.activateMandatoryReason == ActivateMandatoryReason.Other || this.activateMandatoryReason == ActivateMandatoryReason.SavedAndClosedClick))) {
          // Store the message response in the service. 
          // The same service is injected to this configuration's parameters and will be used to check mandatory state.
          this.parameterMandatoryService.setMandatoryParamsById(messages, !this.isEditor);

          // Don't display the popup if we're currently highlighting. But show it if the user clicked "Save" but had mandatory params.
          if (this.activateMandatoryReason || (!this.isEditor && messages.size > 0)) {
            if (!this.activateMandatoryReason)
              this.activateMandatoryReason = ActivateMandatoryReason.Other;

            // Note! If mandatory popup is already opened then system udpates the existing one.
            this.popupService.open<Immutable.List<MandatoryParamsMessage>>(PopupIdentifiers.Mandatory, messages);

          }

          // Store the isNextStateIncluded from server, and send a message to update the highlighted params.
          if (messages.size > 0) {

            let newIsNextStateIncluded = messages.first().isNextStateIncluded;
            if (newIsNextStateIncluded != this.confUIStore.isNextStateIncluded) {
              // Send message to update highlighting
              this.parameterMandatoryService.sendHighlightMessage(this.parameterMandatoryService.isHighlighting);
              this.confUIStore.isNextStateIncluded = newIsNextStateIncluded;
            }
          }
        }
      },
      listenNewEventsOnly: true
    }, false);

  }

  // If server returns an error then redirect to start
  protected handleConfiguratorError() {

    if (this.exceptionSubscription)
      this.exceptionSubscription.unsubscribe();

    this.exceptionSubscription = this.exceptionHandler.onException(exception => {

      if (exception instanceof ApiException) {

        let apiException = exception as ApiException;
        if (ErrorCodes.isConfiguratorErrorCode(apiException.code))
        {
          this.isRedirectingOnError = true;
          this.routeRedirector.redirectToStart();
        }
      }

    }, false);
  }

  onBeforeUnload($event, isReload?: boolean) {

    if (isReload)
      return;
    
    // TODO fix translation and add popup
    if (!this.canExitConfigurator) {

      // Mark the session for closing, if the user stays on this page then in next request the server will unmark it
      // If the user does not stay on this page then the server will discard it later on
      this.configuratorStore.markConfSessionForClosing(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId);

      $event.preventDefault();      
            
      $event.returnValue = this.strings.UnsavedChangesConfirmation;
      return this.strings.UnsavedChangesConfirmation;
    }
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.confPageSession.activeConfigurationId) {
      this.uiNormalizer.updateCompositeSettings(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId, this.confPageSession.pageId);
      this.uiNormalizer.setDefaultAccordionSettings(this.confPageSession.pageId, this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId);
    }
  }
}