import { Injectable } from "@angular/core";
import { User } from "../models";
import { AccountDataStore } from "./accountData/accountDataStore";

@Injectable()
export class UserService {
  constructor(
    protected accountDataStore: AccountDataStore,
  ) { }

  public isUserLoggedIn(): boolean {
    let isLoggedIn = !!this.accountDataStore.getUser();

    return isLoggedIn;
  }

  public getUser(): User {
    return this.accountDataStore.getUser();
  }
}