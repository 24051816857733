import { Component, ChangeDetectorRef, Inject, Output, EventEmitter, SimpleChanges, Input, ChangeDetectionStrategy } from "@angular/core";
import { BaseComponent } from "../../../shared";
import { ConfPageSessionService } from "../../providers/confPageSessionService";
import { ConfInfo, RequestViews, Conf } from "../../../shared/models";
import { CompositeTreeDataProvider } from "../../actions/composite/tree/compositeTreeDataProvider";
import { ConfiguratorStore } from "../../providers/configuratorStore";
import { RouteRedirector } from "../../../shared/providers/routeRedirector";
import { TreeNode } from "../../../../shared/components/treeViewSimple/treeNode";
import { PopupIdentifiers } from "../../providers/popupIdentifiers";
import { ManagedSubscription } from "../../../../shared/managedSubscription";
import { deepCopy } from "../../../../shared/utils/copy";
import { AccordionCompositeParams } from "./accordionCompositeParams";
import { ImageSets } from "../../../../shared/utils";
import { ConfRouteParams } from "../../providers/confRouteParams";

@Component({
  selector: 'accordion-configurations-tree',
  templateUrl: './accordionConfigurationsTreeComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionConfigurationsTreeComponent extends BaseComponent {

  public imageSet: string = "primary";
  public activeNodeId: number;
  public startingNodes: Array<TreeNode> = [];
  @Input() public selectedConfId: number;

  @Output() public treeNodeSelected = new EventEmitter();
  public confInfoChangeSubscriptions: Array<ManagedSubscription> = [];

  constructor(
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(CompositeTreeDataProvider) public treeDataProvider: CompositeTreeDataProvider,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    public cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {

    this.imageSet = this.getImageSet(ImageSets.ConfiguratorTree);
    super.ngOnInit();

  }

  public subscribeConfInfoChanges(confId: number): void {

    // Subscription for data change e.g title
    this.confInfoChangeSubscriptions.push(this.confStore.onConfInfoChange(confId, this.confPageSession.confSessionId, (conf: ConfInfo): void => {

      this.updateTreeData(null);

      // Make a deep copy of starting nodes and set it back to starting nodes for change detection.
      let rootNodes: TreeNode[] = [];
      this.startingNodes.forEach(x => rootNodes.push(deepCopy(x)));
      this.startingNodes = rootNodes;

      this.emitterService.send(PopupIdentifiers.AccordionNodesUpdate);
      this.cd.markForCheck();

    }));
  }

  unsubscribeConfChanges(): void {
    while (this.confInfoChangeSubscriptions.length > 0) {
      let subscription = this.confInfoChangeSubscriptions.pop();
      subscription.unsubscribe();
    }
  }

  updateTreeData(children: TreeNode[]) {

    if (children == null)
      children = this.startingNodes;

    for (let index = 0; index < children.length; index++) {

      let node = children[index];
      let confInfo = this.confStore.getConfInfo(+node.id, this.confPageSession.confSessionId);
      if (confInfo != null && confInfo.text != node.title) {
        node.title = confInfo.text;
      }

      if (node.children.length > 0)
        this.updateTreeData(node.children);
    }

  }

  ngOnChanges(changes: SimpleChanges): void {

    // Populate tree data again, if selected sub configuration is not correct.
    if (changes["selectedConfId"] && this.activeNodeId != this.selectedConfId) {
      this.unsubscribeConfChanges();
      this.setUpTreeData(this.selectedConfId);
    }

  }

  setUpTreeData(activeSubConfId: number): void {

    // As this is only breadcrumb, it won't contain more than one child.
    let nodesByParent: Map<number, TreeNode> = new Map<number, TreeNode>();
    let currentId = activeSubConfId;
    while (currentId != null) {

      let currentConfInfo = this.confStore.getConfInfo(currentId, this.confPageSession.confSessionId);

      // If subconfig Id is incorrect then confInfo would be null.
      if (currentConfInfo == null)
        return;

      // Subscription for title change.
      this.subscribeConfInfoChanges(currentId);

      let node = this.getTreeNode(currentConfInfo.longId);
      nodesByParent.set(currentConfInfo.parentId, node);
      currentId = currentConfInfo.parentId;
    }

    let rootNode = this.getTreeNode(this.confPageSession.activeConfiguration.rootId);
    this.setChildren(rootNode, nodesByParent);
    // Custom CWL start
    //if (rootNode.children.length == 0) 
    //  this.startingNodes = rootNode.children;
    //else
    this.startingNodes = [rootNode];
    // Custom CWL end
    this.activeNodeId = activeSubConfId ? activeSubConfId : null;

  }

  setChildren(parent: TreeNode, nodesByParent: Map<number, TreeNode>) {

    let child: TreeNode;
    if (nodesByParent.has(+parent.id)) {
      child = nodesByParent.get(+parent.id);
      parent.children = [child]
    }

    if (child)
      this.setChildren(child, nodesByParent);
  }

  getTreeNode(confId: number): TreeNode {

    let confInfo = this.confStore.getConfInfo(confId, this.confPageSession.confSessionId);
    let treeNode = this.createTreeNode(confInfo);

    if (!treeNode.children)
      treeNode.children = [];

    return treeNode;

  }

  createTreeNode(info: ConfInfo): TreeNode {

    let hasVaultIcon: boolean = info.iconRelativeUrl && info.iconRelativeUrl != "";

    let nodeData: TreeNode =
      <TreeNode>{
        id: info.longId.toString(),
        title: info.text,
        icon: hasVaultIcon ? info.iconRelativeUrl : 'configuration',
        isVault: hasVaultIcon,
        level: this.getDistanceFromRoot(info) /*As root node is ignored in treeview*/   // Custom CWL
      };

    //if (info.children.size > 0)
    //  nodeData.level += 1;

    return nodeData;
  }
  getDistanceFromRoot(info: ConfInfo, level: number = 0): number {

    if (!info.parentId)
      return level;

    let parentInfo = this.confStore.getConfInfo(info.parentId, this.confPageSession.confSessionId);
    level = this.getDistanceFromRoot(parentInfo, ++level);

    return level;
  }

  onNodeSelected(event): void {
    let confId = +event.node.id;

    // Ignore If configuration detail already visible.
    if (confId == this.selectedConfId)
      return;

    this.setupTreeAndShowOrHideDetail(confId);
  }

  setupTreeAndShowOrHideDetail(confId: number): void {

    if (confId) {

      // Hide the sub configurations.
      if (this.confPageSession.activeConfiguration.rootId == confId) {
        this.emitterService.send(PopupIdentifiers.AccordionCompositeHideDetail, new AccordionCompositeParams(this.confPageSession.activeConfigurationId, false));
        this.routeRedirector.changeParamValue(ConfRouteParams.SUB_CONF_ID, null);
        this.selectedConfId = confId;
        return;
      }
      else {
        this.emitterService.send(PopupIdentifiers.AccordionCompositeShowDetail, new AccordionCompositeParams(confId, true));
      }

      this.selectedConfId = confId;
      this.setUpTreeData(this.selectedConfId);
      this.routeRedirector.changeParamValue(ConfRouteParams.SUB_CONF_ID, this.selectedConfId.toString());
    }

  }

  findNextSelectableNode(focusedNodeId: number, searchPath: TreeNode): TreeNode {

    if (+searchPath.id == focusedNodeId)
      return null;

    for (let index = 0; index < searchPath.children.length; index++) {

      let node: TreeNode = searchPath.children[index];

      // If the index is greater than 0, return the previous sibling; otherwise, return the parent.
      if (+node.id == focusedNodeId) {
        return index > 0 ? searchPath.children[index - 1] : searchPath;
      }
      else {
        return this.findNextSelectableNode(focusedNodeId, node);
      }
    }
  }

  closeConfigurationDetail(event): void {

    // Send the rootId to close the sub configuration.    
    let nextSelectedNode: TreeNode = this.findNextSelectableNode(event.node.id, this.startingNodes[0]);

    /*this.emitterService.send(PopupIdentifiers.AccordionCompositeHideDetail, new AccordionCompositeParams(+event.node.id, false));*/
    let nextSelectedNodeId = nextSelectedNode == null ? this.confPageSession.activeConfigurationId : +nextSelectedNode.id;
    this.setupTreeAndShowOrHideDetail(nextSelectedNodeId);

    //if (nextSelectedNodeId != this.confPageSession.activeConfigurationId)
    //  this.routeRedirector.changeParamValue(ConfRouteParams.SUB_CONF_ID, nextSelectedNodeId.toString());

    this.emitterService.send(PopupIdentifiers.AccordionCompositeShowDetail, new AccordionCompositeParams(+nextSelectedNodeId, false));

  }

  ngOnDestroy() {
    this.unsubscribeConfChanges();
    super.ngOnDestroy();
  }

}